import Big from 'big.js';
import groupBy from 'lodash/groupBy';
import {
  IAOTGroupLoan,
  IAOTGroupTrade,
  IBulkAOTGroup,
  IBulkGroupLoan,
  IBulkTrade
} from '../../../../shared/models/Loan';
import { AOT_AMOUNT_MULTIPLIER } from 'shared/constants';

type TBulkGroupLoanPartial = Pick<IBulkGroupLoan, 'loanNumber' | 'noteAmount' | '_securitySlots'>;

// exporting for testing purposes
export const _bulkGroupLoanToAOTGroupLoan = (loan: TBulkGroupLoanPartial): IAOTGroupLoan[] =>
  loan._securitySlots.map((securitySlot) => ({
    loanNumber: loan.loanNumber,
    noteAmount: loan.noteAmount,
    isSelected: false,
    _securitySlot: securitySlot
  }));

// dateString format: 'MM/DD/YYYY'
export const _getCouponDateFormat = (dateString: string): string => {
  const shortDate = new Intl.DateTimeFormat('en-US', {
    month: 'numeric',
    day: 'numeric'
  });

  const date = new Date(dateString);
  const offsetMillis = date.getTimezoneOffset() * 60 * 1000;
  // We have to add the offset back to ignore timezone
  const normalizedDated = date.getTime() + offsetMillis;

  return shortDate.format(normalizedDated);
};

export const getFormattedCoupon = (type: string, coupon: number, settlementDate: string) =>
  `${type} ${coupon.toFixed(3)} ${_getCouponDateFormat(settlementDate)}`;

// exporting for testing purposes
export const _bulkTradeToAOTGroupTrade = (trade: Omit<IBulkTrade, '_id'>): IAOTGroupTrade => {
  const formattedCoupon = getFormattedCoupon(trade.type, trade.coupon, trade.settlementDate);
  const originalSize = new Big(trade.originalSize).times(AOT_AMOUNT_MULTIPLIER).toString();
  const tradeDate = new Date(trade.tradeDate);

  // Remove timezone from date
  tradeDate.setUTCHours(0, 0, 0, 0);

  return {
    originalSize,
    _coupon: formattedCoupon,
    dealer: trade.dealer,
    clientName: trade.clientName,
    aotAmount: '0',
    originalPrice: trade.originalPrice,
    tradeDate: tradeDate.toISOString(),
    cusip: trade.cusip
  };
};

export const getAOTGroups = (loans: TBulkGroupLoanPartial[], trades: IBulkTrade[]): IBulkAOTGroup[] => {
  const aotGroupLoans = loans.flatMap(_bulkGroupLoanToAOTGroupLoan);
  const aotGroupTrades = trades.map(_bulkTradeToAOTGroupTrade);

  const tradesByCoupon = groupBy(aotGroupTrades, '_coupon');
  const loansByCoupon = groupBy(aotGroupLoans, '_securitySlot.name');

  return Object.entries(loansByCoupon)
    .map(([coupon, loansForGroup]) => ({
      coupon,
      loans: loansForGroup,
      trades: tradesByCoupon[coupon] || []
    }))
    .filter((group) => group.trades.length > 0);
};

export const _ungroupAOTByDealerAccount = (aotGroups: IBulkAOTGroup[]): IBulkAOTGroup[] =>
  aotGroups.flatMap((group) =>
    group.loans.flatMap((loan) =>
      group.trades.map((trade) => ({
        ...group,
        loans: [loan],
        trades: [trade]
      }))
    )
  );
