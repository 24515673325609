import { IBulkRepository } from '../../shared/api/interfaces/IBulkRepository';
import { IBulkContract, ICommitBulkContractV2, IResponseBulkContractV2 } from '../../shared/models/Bulk';
import { thunkReactQueryMiddleware } from './base.thunks';
import { GetSingleBulkThunkPayload } from '../slices/bulk/types';
import { client } from '../../shared/api/clients/client';

export class BulkThunks {
  private readonly bulkRepository!: IBulkRepository;

  private baseIdentifierV2 = 'bulk-commitment-v2';

  constructor(_bulkRepository: IBulkRepository) {
    this.bulkRepository = _bulkRepository;

    if (this.bulkRepository === null) {
      throw new Error('bulkRepository has not been instantiated!');
    }
  }

  //   V2
  commitBulkV2 = () => {
    const action = `${this.baseIdentifierV2}/commit`;

    return thunkReactQueryMiddleware<IResponseBulkContractV2[], ICommitBulkContractV2[]>(action, (contracts) => ({
      queryFn: () => client.post(this.bulkRepository.apiUrlV2, contracts)
    }));
  };

  commitSingleBulkV2 = () => {
    const action = `${this.baseIdentifierV2}/single-commit`;

    return thunkReactQueryMiddleware<IResponseBulkContractV2[], ICommitBulkContractV2>(action, (contract) => ({
      queryFn: () => client.post(this.bulkRepository.apiUrlV2, [contract])
    }));
  };

  getBulkV2 = () => {
    const action = `${this.baseIdentifierV2}/get`;

    return thunkReactQueryMiddleware<IBulkContract, GetSingleBulkThunkPayload>(action, ({ internalId }) => ({
      retry: true,
      queryKey: [action, internalId],
      queryFn: () => client.get(`${this.bulkRepository.apiUrlV2}/${internalId}`)
    }));
  };

  getSingleBulkV2 = () => {
    const action = `${this.baseIdentifierV2}/get-single`;

    return thunkReactQueryMiddleware<IBulkContract, GetSingleBulkThunkPayload>(action, ({ internalId }) => ({
      retry: true,
      queryKey: [action, internalId],
      queryFn: () => client.get(`${this.bulkRepository.apiUrlV2}/${internalId}`)
    }));
  };
}
