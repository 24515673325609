import _, { maxBy, minBy, partition } from 'lodash';
import { formatContractName, formatInterestRateRange } from 'shared/components/BulkFlow/formatters';
import { IBulkGroupLoan, IBulkLoan, IBulkLoansGroup } from 'shared/models/Loan';
import {
  addMortgageData,
  calculateContractAmount,
  createIsLoanPartySingleGroup,
  getNewContractId,
  splitGroupByContractAmount
} from './utils';
import { TExtraDataMortgageMap, TBulkBidsMap, TBulkPayupsMap, TBulkConfig } from './types';
import { IInvestorProcessed } from 'shared/models/Investor';

const groupingAttributes: (keyof IBulkGroupLoan)[] = [
  'specPool',
  'servicerFirm',
  'servicerFee',
  '_interestMinRate',
  '_interestMaxRate',
  'investorId',
  '_productId'
];

const groupSortAttributes: (keyof IBulkLoansGroup)[] = [
  'investorId',
  'servicerFirm',
  'specPool',
  'interestRateRange',
  'servicerFee'
];

export const getSingleGroupKey = (loan: IBulkGroupLoan): string => loan._bidAgent;
export const getMultiGroupKey = (loan: IBulkGroupLoan): string =>
  groupingAttributes.map((attribute) => loan[attribute]).join(',');

const sortLoansByNoteAmountDesc = (loans: IBulkGroupLoan[]): IBulkGroupLoan[] =>
  // Have to use parseFloat because the values are strings
  _.orderBy(loans, [(g) => parseFloat(g.noteAmount)], 'desc');

export const groupLoanData = (
  loansWithoutData: IBulkLoan[],
  mortgages: TExtraDataMortgageMap,
  bids: TBulkBidsMap,
  payups: TBulkPayupsMap,
  config: TBulkConfig,
  investorsConfig: IInvestorProcessed[],
  maxAmount?: number // Testing purposes
): IBulkLoansGroup[] => {
  const isLoanPartySingleGroup = createIsLoanPartySingleGroup(
    Boolean(config.featureFlags?.allowSingleGroupFirms),
    investorsConfig
  );
  const unsortedLoansWithData = loansWithoutData.map(
    addMortgageData(mortgages, bids, payups, config, isLoanPartySingleGroup)
  );
  const sortedLoansWithData = sortLoansByNoteAmountDesc(unsortedLoansWithData);

  const [singleGroupLoans, multiGroupLoans] = partition(sortedLoansWithData, (loan) =>
    isLoanPartySingleGroup(loan._bidAgent)
  );
  const groups = [...groupSingleGroupLoans(singleGroupLoans), ...groupMultiGroupLoans(multiGroupLoans, maxAmount)];

  return _.orderBy(groups, groupSortAttributes, 'asc');
};

export const groupSingleGroupLoans = (loans: IBulkGroupLoan[]): IBulkLoansGroup[] => {
  const sortedGroupedLoans = _.groupBy(loans, getSingleGroupKey);

  return _.map(Object.values(sortedGroupedLoans), singleGroupLoansToGroup);
};

// groups loans by the grouping key
// is a combination of the groupingAttributes set above
// key groups from the start of the array to the end
export const groupMultiGroupLoans = (loans: IBulkGroupLoan[], maxAmount?: number): IBulkLoansGroup[] => {
  const sortedGroupedLoans = _.groupBy(loans, getMultiGroupKey);
  const groupedByContractAmount = Object.values(sortedGroupedLoans).flatMap((loansGroup) =>
    splitGroupByContractAmount(loansGroup, maxAmount)
  );

  return _.map(groupedByContractAmount, multiGroupLoansToGroup);
};

export const singleGroupLoansToGroup = (loans: IBulkGroupLoan[]): IBulkLoansGroup => {
  const {
    servicerFee,
    servicerFirm,
    investorId,

    _bidSeller,
    _bidAgent
  } = loans[0];

  const _interestMinRate = minBy(loans, '_interestMinRate')?._interestMinRate as number;
  const _interestMaxRate = maxBy(loans, '_interestMaxRate')?._interestMaxRate as number;

  return {
    id: getNewContractId(),
    loans,
    servicerFirm,
    servicerFee,
    investorId,
    // Display a blank spec pool for single group loans
    specPool: '',
    // Display a blank interest rate range for single group loans
    interestRateRange: '',
    // Product ID is set to null on each loan, also use null for the group
    _productId: null,
    _interestMinRate,
    _interestMaxRate,
    _bidSeller,
    _bidAgent,
    contractAmount: calculateContractAmount(loans),
    // Display the investor for the group's contract name
    contractName: loans[0].investorId,
    status: null
  };
};

export const multiGroupLoansToGroup = (loans: IBulkGroupLoan[]): IBulkLoansGroup => {
  const {
    specPool,
    servicerFee,
    servicerFirm,
    investorId,
    _productId,
    _bidSeller,
    _bidAgent,
    _interestMinRate,
    _interestMaxRate
  } = loans[0];

  const interestRateRange = formatInterestRateRange(loans[0]._interestMinRate, loans[0]._interestMaxRate);

  return {
    id: getNewContractId(),
    loans,
    servicerFirm,
    servicerFee,
    investorId,
    specPool,
    interestRateRange,
    _productId,
    _interestMinRate,
    _interestMaxRate,
    _bidSeller,
    _bidAgent,
    contractAmount: calculateContractAmount(loans),
    contractName: formatContractName(specPool, interestRateRange),
    status: null
  };
};
