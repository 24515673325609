import { AsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IBaseThunk, thunkReactQueryMiddleware } from '../thunks/base.thunks';
import { IMortgageRepository, MarketplaceFilter } from 'shared/api/interfaces/IMortgageRepository';
import { IMortgage } from 'shared/models/Mortgage';
import { IResponseStatus } from 'shared/models/shared';

export class MortgageThunks implements IBaseThunk<IMortgage> {
  private readonly mortgageRepository!: IMortgageRepository;

  private baseIdentifier = 'mortgage';

  constructor(_mortgageRepository: IMortgageRepository) {
    this.mortgageRepository = _mortgageRepository;

    if (this.mortgageRepository === null) {
      throw new Error('mortgageRepository has not been instantiated!');
    }
  }

  getAll(): AsyncThunk<AxiosResponse<IMortgage[], any>, void, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  getDraftMortgages() {
    const action = `${this.baseIdentifier}/draft`;

    return thunkReactQueryMiddleware<IMortgage[], MarketplaceFilter>(action, (marketplace) => ({
      queryKey: [action, marketplace],
      queryFn: () => this.mortgageRepository.getDraftMortgages(marketplace)
    }));
  }

  getOpenMortgages() {
    const action = `${this.baseIdentifier}/open`;

    return thunkReactQueryMiddleware<IMortgage[], MarketplaceFilter>(action, (marketplace) => ({
      queryKey: [action, marketplace],
      queryFn: () => this.mortgageRepository.getOpenMortgages(marketplace)
    }));
  }

  getPendingMortgages() {
    const action = `${this.baseIdentifier}/pending`;

    return thunkReactQueryMiddleware<IMortgage[], MarketplaceFilter>(action, (marketplace) => ({
      queryKey: [action, marketplace],
      queryFn: () => this.mortgageRepository.getPendingMortgages(marketplace)
    }));
  }

  getSoldMortgages() {
    const action = `${this.baseIdentifier}/sold`;

    return thunkReactQueryMiddleware<IMortgage[], void>(action, () => ({
      queryFn: this.mortgageRepository.getSoldMortgages
    }));
  }

  getDeactivatedMortgages() {
    const action = `${this.baseIdentifier}/deactivated`;

    return thunkReactQueryMiddleware<IMortgage[], void>(action, () => ({
      queryFn: this.mortgageRepository.getDeactivatedMortgages
    }));
  }

  getOne() {
    const action = `${this.baseIdentifier}/fetchMortgage`;

    return thunkReactQueryMiddleware<IMortgage, string>(action, (id) => ({
      queryKey: [action, id],
      queryFn: () => this.mortgageRepository.getOne(id)
    }));
  }

  add() {
    const action = `${this.baseIdentifier}/addMortgage`;

    return thunkReactQueryMiddleware<IMortgage, any>(action, (mortgage) => ({
      queryFn: () => this.mortgageRepository.add(mortgage)
    }));
  }

  update() {
    const action = `${this.baseIdentifier}/updateMortgage`;

    return thunkReactQueryMiddleware<IMortgage, { id: string; item: any }>(action, ({ id, item }) => ({
      queryKey: [action, id],
      queryFn: () => this.mortgageRepository.update(id, item)
    }));
  }

  delete() {
    const action = `${this.baseIdentifier}/deleteMortgage`;

    return thunkReactQueryMiddleware<IMortgage, string>(action, (id) => ({
      queryKey: [action, id],
      queryFn: () => this.mortgageRepository.delete(id)
    }));
  }

  openMortgages() {
    const action = `${this.baseIdentifier}/open-mortgageS`;

    return thunkReactQueryMiddleware<null, string[]>(action, (internalIds) => ({
      queryFn: () => this.mortgageRepository.openMortgages(internalIds)
    }));
  }

  transfer() {
    const action = `${this.baseIdentifier}/transfer`;

    return thunkReactQueryMiddleware<any, any>(action, (mortgageId) => ({
      queryKey: [action, mortgageId],
      queryFn: () => this.mortgageRepository.transfer(mortgageId)
    }));
  }

  deactivateMortgage() {
    const action = `${this.baseIdentifier}/deactivate-mortgage`;

    return thunkReactQueryMiddleware<IResponseStatus, { internalId: string }>(action, ({ internalId }) => ({
      queryKey: [action, internalId],
      queryFn: () => this.mortgageRepository.deactivateMortgage(internalId)
    }));
  }

  deactivateSingleMortgage() {
    const action = `${this.baseIdentifier}/deactivate-single-mortgage`;

    return thunkReactQueryMiddleware<IResponseStatus, { internalId: string }>(action, ({ internalId }) => ({
      queryKey: [action, internalId],
      queryFn: () => this.mortgageRepository.deactivateMortgage(internalId)
    }));
  }

  reactivateMortgage() {
    const action = `${this.baseIdentifier}/reactivate-mortgage`;

    return thunkReactQueryMiddleware<IResponseStatus, { internalId: string }>(action, ({ internalId }) => ({
      queryKey: [action, internalId],
      queryFn: () => this.mortgageRepository.reactivateMortgage(internalId)
    }));
  }

  getMortgageXmlById() {
    const action = `${this.baseIdentifier}/getMortgageXmlById`;

    return thunkReactQueryMiddleware<IResponseStatus, { mortgageId: string }>(action, ({ mortgageId }) => ({
      queryKey: [action, mortgageId],
      queryFn: () => this.mortgageRepository.getMortgageXmlById(mortgageId)
    }));
  }

  getBulkMortgageXml() {
    const action = `${this.baseIdentifier}/getBulkMortgageXml`;

    return thunkReactQueryMiddleware<IResponseStatus, { mortgageIds: string[] }>(action, ({ mortgageIds }) => ({
      queryFn: () => this.mortgageRepository.getBulkMortgageXml(mortgageIds)
    }));
  }

  getMortgageCsvById() {
    const action = `${this.baseIdentifier}/getMortgageCsvById`;

    return thunkReactQueryMiddleware<IResponseStatus, { mortgageId: string }>(action, ({ mortgageId }) => ({
      queryKey: [action, mortgageId],
      queryFn: () => this.mortgageRepository.getMortgageCsvById(mortgageId)
    }));
  }

  getMortgageDocumentsZip() {
    const action = `${this.baseIdentifier}/getMortgageDocumentsZip`;

    return thunkReactQueryMiddleware<IResponseStatus, { mortgageId: string }>(action, ({ mortgageId }) => ({
      queryKey: [action, mortgageId],
      queryFn: () => this.mortgageRepository.getMortgageDocumentsZip(mortgageId)
    }));
  }

  getMortgageDocumentsStatus() {
    const action = `${this.baseIdentifier}/getMortgageDocumentsStatus`;

    return thunkReactQueryMiddleware<IResponseStatus, { mortgageId: string }>(action, ({ mortgageId }) => ({
      queryKey: [action, mortgageId],
      queryFn: () => this.mortgageRepository.getMortgageDocumentsStatus(mortgageId)
    }));
  }
}
