import moment from 'moment';

export const _getPersistedChunk = (persistName: string, initialState = {}, versionWanted?: string) => {
  try {
    const persistedChunk = localStorage.getItem(persistName);

    if (persistedChunk) {
      const { data, timestamp, version } = JSON.parse(persistedChunk);

      const isDaySessionExpired = !moment().isSame(timestamp, 'day');
      const isVersionExpired = versionWanted && version !== versionWanted;

      if (isVersionExpired || isDaySessionExpired) {
        localStorage.removeItem(persistName);

        return initialState;
      }

      return data;
    }

    return initialState;
  } catch (error) {
    console.error(`_getPersistedChunk error: ${error}`);

    return initialState;
  }
};
