import { ToastStatus } from '@3homes/libs';
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { FetchQueryOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { addToast } from '../slices/toast/toast.slice';
import { store } from '../store';
import { StatusCode } from 'shared/api/clients/baseAPIClient';
import { fetchQueryThunk } from '../../shared/api/react-query';

export interface IBaseThunk<T> {
  add(): AsyncThunk<AxiosResponse<T, any>, any, Record<string, unknown>>;
  update(): AsyncThunk<AxiosResponse<T, any>, { id: string; item: T }, Record<string, unknown>>;
  delete(): AsyncThunk<AxiosResponse<T, any>, string, Record<string, unknown>>;
  getAll(): AsyncThunk<AxiosResponse<T[], any>, void, Record<string, unknown>>;
}

export interface IQueryCallback<ApiData> {
  // Set queryKey optional
  (data: ApiData): Omit<FetchQueryOptions, 'queryKey'> & Partial<Pick<FetchQueryOptions, 'queryKey'>>;
}

export function thunkReactQueryMiddleware<ResponseData, ApiData>(action: string, apiCallback: IQueryCallback<ApiData>) {
  return createAsyncThunk<AxiosResponse<ResponseData>, ApiData>(action, async (apiData, thunkAPI) => {
    try {
      // If queryKey is not set, generate a random key
      const options = apiCallback(apiData);
      const queryKey = options.queryKey || [uuidv4()];

      return await fetchQueryThunk({ ...options, queryKey });
    } catch (err: any) {
      if ([StatusCode.BAD_GATEWAY, StatusCode.GATEWAY_TIMEOUT, StatusCode.SERVICE_UNAVAILABLE].includes(err.status)) {
        const payload = {
          status: ToastStatus.error,
          text: 'Connection Error, please ensure you are connected to your network and try again. Please contact your admin if the issue persists.'
        };

        store.dispatch(addToast(payload));
      }

      return thunkAPI.rejectWithValue(err);
    }
  });
}
