import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { client } from 'shared/api/clients/client';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';
import 'reflect-metadata';
import { ITraderData } from 'shared/models/Trader';
import { ITraderRepository } from 'shared/api/interfaces/ITraderRepository';

@injectable()
export class TraderRepository implements ITraderRepository {
  private baseRepository = new BaseRepository();

  apiUrl: string = this.baseRepository.getApiUrl('v2/traders');

  add(): Promise<AxiosResponse<any, any>> {
    throw new Error('Method not implemented.');
  }

  update(): Promise<AxiosResponse<any, any>> {
    throw new Error('Method not implemented.');
  }

  delete(): Promise<AxiosResponse<any, any>> {
    throw new Error('Method not implemented.');
  }

  getAll = async (): Promise<AxiosResponse<ITraderData[]>> => {
    return await client.get(this.apiUrl);
  };

  getOne = async (id: string): Promise<AxiosResponse<ITraderData>> => {
    return await client.get(`${this.apiUrl}/${id}`);
  };
}
