import { PayloadAction } from '@reduxjs/toolkit';
import { ContractStatus, IAOTGroupTrade, IBulkLoan, IBulkLoansGroup, IBulkTrade } from 'shared/models/Loan';
import { IBulkContract, IBulkCommitContractAPIError } from '../../../shared/models/Bulk';

export enum BulkStep {
  UPLOAD_LOANS,
  UPLOAD_TRADES,
  MANAGE_AOT,
  REVIEW,
  FINAL
}

export type BulkStepPermissions = {
  // indicates if the next step is allowed to be accessed
  [key in BulkStep]?: boolean;
};

export enum BulkLoansError {
  'NO_EXISTING_BID' = 'NO_EXISTING_BID',
  'NOT_OPEN' = 'NOT_OPEN',
  'NO_SPEC_POOL' = 'NO_SPEC_POOL',
  'NO_SERVICER_FEE' = 'NO_SERVICER_FEE',
  'DUPLICATED_LOAN' = 'DUPLICATED_LOAN',
  'NO_SERVICER_FIRM' = 'NO_SERVICER_FIRM',
  'INTEREST_RATE_NOT_IN_RANGE' = 'INTEREST_RATE_NOT_IN_RANGE',
  'INTEREST_RATES_NOT_IN_RANGE' = 'INTEREST_RATES_NOT_IN_RANGE',
  'INTEREST_RATES_DIFF_NOT_IN_RANGE' = 'INTEREST_RATES_DIFF_NOT_IN_RANGE',
  'INTEREST_RATES_DIFF_NOT_DIVISIBLE' = 'INTEREST_RATES_DIFF_NOT_DIVISIBLE',
  'INVALID_PRICE' = 'INVALID_PRICE',

  'BLANK_LOAN_NUMBER' = 'BLANK_LOAN_NUMBER',
  'BLANK_INVESTOR_ID' = 'BLANK_INVESTOR_ID',
  'BLANK_INTEREST_MIN_RATE' = 'BLANK_INTEREST_MIN_RATE',
  'BLANK_INTEREST_MAX_RATE' = 'BLANK_INTEREST_MAX_RATE',
  'BLANK_SERVICER_FIRM' = 'BLANK_SERVICER_FIRM',
  'BLANK_SERVICER_FEE' = 'BLANK_SERVICER_FEE',
  'BLANK_SERVICER_FEE_REQUIRED' = 'BLANK_SERVICER_FEE_REQUIRED'
}

export enum BulkTradesError {
  'INCORRECT_DATE_FORMAT' = 'INCORRECT_DATE_FORMAT',
  'BLANK_CLIENT_NAME' = 'BLANK_CLIENT_NAME',
  'BLANK_DEALER' = 'BLANK_DEALER',
  'BLANK_TRADE_DATE' = 'BLANK_TRADE_DATE',
  'BLANK_SETTLEMENT_DATE' = 'BLANK_SETTLEMENT_DATE',
  'BLANK_ORIGINAL_SIZE' = 'BLANK_ORIGINAL_SIZE',
  'BLANK_TYPE' = 'BLANK_TYPE',
  'BLANK_COUPON' = 'BLANK_COUPON',
  'BLANK_ORIGINAL_PRICE' = 'BLANK_ORIGINAL_PRICE',
  'BLANK_CUSIP' = 'BLANK_CUSIP',
  'NO_MATCHING_SECURITY_SLOT' = 'NO_MATCHING_SECURITY_SLOT',
  'DUPLICATE_SECURITY_SLOT' = 'DUPLICATE_SECURITY_SLOT',
  'INVALID_ORIGINAL_PRICE' = 'INVALID_ORIGINAL_PRICE'
}

export const PERSISTED_BULK_STORAGE_KEY = 'BULK-IMPORTED-SOURCE-DATA';

export type BulkLoansErrorsMap = Record<IBulkLoan['loanId'], Partial<{ [key in keyof IBulkLoan]: BulkLoansError }>>;
export type BulkTradesErrorsMap = Record<IBulkTrade['_id'], Partial<{ [key in keyof IBulkTrade]: BulkTradesError }>>;

export type InitBulkImportLoansPayload = PayloadAction<{ data: IBulkLoan[] }>;
export type InitBulkImportTradesPayload = PayloadAction<{ data: IBulkTrade[] }>;
export type RemoveContractRowsPayload = PayloadAction<{ groupId: string }>;
export type RemoveContractRowsByStatusPayload = PayloadAction<{ status: ContractStatus }>;
export type RemoveLoansByContractStatusPayload = PayloadAction<{ status: ContractStatus }>;
export type DeleteBulkLoansPayload = PayloadAction<{ loanIds: string[] }>;
export type DeleteBulkTradesPayload = PayloadAction<{ tradeIds: string[] }>;
export type IUpdateStepPayload = PayloadAction<{ stepTo: BulkStep }>;
export type UpdateBulkLoansErrorsPayload = PayloadAction<BulkLoansErrorsMap>;
export type UpdateBulkTradesErrorsPayload = PayloadAction<BulkTradesErrorsMap>;
export type RemoveBulkLoansErrorsPayload = PayloadAction<Pick<IBulkLoan, 'loanId'>[]>;
export type RemoveBulkTradesErrorsPayload = PayloadAction<Pick<IBulkTrade, '_id'>[]>;
export type UpdateStepPayload = PayloadAction<{ stepTo: BulkStep }>;
export type InitBulkGroupsPayload = PayloadAction<IBulkLoansGroup[]>;
export type CommitBulkErrorPayload = PayloadAction<{ data?: IBulkCommitContractAPIError }>;
export type SetLoansSpreadsheetErrorPayload = PayloadAction<{ error: string }>;
export type SetTradesSpreadsheetErrorPayload = PayloadAction<{ error: string }>;
export type UpdateBulkLoanPayload = PayloadAction<{ loan: IBulkLoan; newLoan: IBulkLoan }>;
export type UpdateBulkTradePayload = PayloadAction<{ trade: IBulkTrade; newTrade: IBulkTrade }>;
export type SetAOTTradeAmountPayload = PayloadAction<{
  existingTrade: IAOTGroupTrade;
  aotAmount: IAOTGroupTrade['aotAmount'];
}>;
export type StartPollingContractPayload = PayloadAction<{ id: string; isSingle?: boolean }>;
export type GetSingleBulkThunkPayload = Pick<IBulkContract, 'internalId'>;
