import {
  TArmMortgage,
  TFixedFannieMaeMortgage,
  TFixedFreddieMacMortgage,
  TProductTypeFannieMaeMortgage,
  TProductTypeFreddieMacMortgage
} from './bulk-grouping/types';
import { IMortgageFlat, LoanProgram } from '../../../shared/models/Mortgage';

const FANNIE_MAE_PRODUCT_ID: Record<IMortgageFlat['amortizationType'], { [key: string]: string }> = {
  Fixed: {
    '360': '0005FR',
    '240': '0015FR',
    '180': '0010FR',
    '120': '0020FR'
  },
  AdjustableRate: {
    '120': '163084',
    '84': '163083',
    '60': '163082',
    '36': '163081'
  }
};

export const isArmLoan = (mortgage: Pick<IMortgageFlat, 'amortizationType'>) =>
  mortgage.amortizationType === 'AdjustableRate';

export const getFreddieMacLoanProductId = (mortgage: TProductTypeFreddieMacMortgage) => {
  if (mortgage.amortizationType === 'Fixed') {
    const fixedMortgage = mortgage as TFixedFreddieMacMortgage;

    const isFhaVa = [LoanProgram.FHA, LoanProgram.VA].includes(fixedMortgage.loanProgram);

    if (fixedMortgage.loanProgram === LoanProgram.Conventional) {
      switch (fixedMortgage.originalTerm) {
        case 120:
          return '1002';
        case 180:
          return '1006';
        case 240:
          return '1007';
        case 360:
          return '1008';
      }
    } else if (isFhaVa) {
      switch (fixedMortgage.originalTerm) {
        case 180:
          return '1480';
        case 240:
          return '1475';
        case 360:
          return '1465';
      }
    }
  } else if (isArmLoan(mortgage)) {
    const armMortgage = mortgage as TArmMortgage;

    switch (armMortgage.initialFixedPeriodEffectiveMonthsCount) {
      case '36':
        return '1577';
      case '60':
        return '1578';
      case '84':
        return '1579';
      case '120':
        return '1580';
    }
  }

  return null;
};

export const getFannieMaeLoanProductId = (mortgage: TProductTypeFannieMaeMortgage) => {
  const timeInMonths =
    mortgage.amortizationType === 'Fixed'
      ? (mortgage as TFixedFannieMaeMortgage).originalTerm
      : (mortgage as TArmMortgage).initialFixedPeriodEffectiveMonthsCount;

  return FANNIE_MAE_PRODUCT_ID[mortgage.amortizationType][timeInMonths] || null;
};
