import { AsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IBaseThunk, thunkReactQueryMiddleware } from '../thunks/base.thunks';
import { IBidRepository } from 'shared/api/interfaces/IBidRepository';
import { IBid, IBidAccept } from 'shared/models/Bid';

export class BidThunks implements IBaseThunk<IBid> {
  private readonly bidRepository!: IBidRepository;

  private baseIdentifier = 'bid';

  constructor(_bidRepository: IBidRepository) {
    this.bidRepository = _bidRepository;

    if (this.bidRepository === null) {
      throw new Error('bidRepository has not been instantiated!');
    }
  }

  update(): AsyncThunk<AxiosResponse<IBid, any>, { id: string; item: IBid }, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  delete(): AsyncThunk<AxiosResponse<IBid, any>, string, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  cancelBid() {
    const action = `${this.baseIdentifier}/cancel-bid`;

    return thunkReactQueryMiddleware<Record<string, unknown>, IBid>(action, (bid) => ({
      queryKey: [action, bid.internalMortgageId],
      queryFn: () => this.bidRepository.cancelBid(bid.internalMortgageId)
    }));
  }

  acceptBidAsOriginator() {
    const action = `${this.baseIdentifier}/accept-bid`;

    return thunkReactQueryMiddleware<IBid, IBidAccept>(action, (bid) => ({
      queryKey: [action, bid.internalMortgageId],
      queryFn: () => this.bidRepository.acceptBid(bid)
    }));
  }

  cancelAcceptedBidAsOriginator() {
    const action = `${this.baseIdentifier}/revert-bid`;

    return thunkReactQueryMiddleware<Record<string, any>, string>(action, (id) => ({
      queryKey: [action, id],
      queryFn: () => this.bidRepository.revertBid(id)
    }));
  }

  confirmBidAsInvestor() {
    const action = `${this.baseIdentifier}/confirm-bid`;

    return thunkReactQueryMiddleware<Record<string, any>, string>(action, (id) => ({
      queryKey: [action, id],
      queryFn: () => this.bidRepository.confirmBid(id)
    }));
  }

  proposeRevisionAsInvestor() {
    const action = `${this.baseIdentifier}/propose-revision`;

    return thunkReactQueryMiddleware<Record<string, any>, { id: string; bidAmount: string }>(
      action,
      ({ id, bidAmount }) => ({
        queryKey: [action, id],
        queryFn: () => this.bidRepository.proposeRevision(id, bidAmount)
      })
    );
  }

  acceptRevisionAsOriginator() {
    const action = `${this.baseIdentifier}/accept-revision`;

    return thunkReactQueryMiddleware<Record<string, any>, string>(action, (id) => ({
      queryKey: [action, id],
      queryFn: () => this.bidRepository.acceptRevision(id)
    }));
  }

  acceptRevisionAsOriginatorFreddie() {
    const action = `${this.baseIdentifier}/accept-revision-freddie`;

    return thunkReactQueryMiddleware<Record<string, any>, string>(action, (id) => ({
      queryKey: [action, id],
      queryFn: () => this.bidRepository.acceptRevision(id)
    }));
  }

  rejectRevisionAsOriginator() {
    const action = `${this.baseIdentifier}/reject-revision`;

    return thunkReactQueryMiddleware<Record<string, any>, string>(action, (id) => ({
      queryKey: [action, id],
      queryFn: () => this.bidRepository.rejectRevision(id)
    }));
  }

  getAll() {
    const action = `${this.baseIdentifier}/fetchBids`;

    return thunkReactQueryMiddleware<IBid[], void>(action, () => ({ queryFn: this.bidRepository.getAll }));
  }

  add() {
    const action = `${this.baseIdentifier}/addBid`;

    return thunkReactQueryMiddleware<IBid, any>(action, (bid) => ({ queryFn: () => this.bidRepository.add(bid) }));
  }

  fetchByMortgageId() {
    const action = `${this.baseIdentifier}/fetchByMortgageId`;

    return thunkReactQueryMiddleware<Record<string, any>, string>(action, (id) => ({
      queryKey: [action, id],
      queryFn: () => this.bidRepository.fetchByMortgageId(id)
    }));
  }
}
