import { IBid, IBidSecuritySlot, ServicingRate } from './Bid';
import { IMortgageFlat } from './Mortgage';
import { IBulkContract, IBulkContractAPIStatus } from './Bulk';

export interface IBulkLoan {
  /** Used in <UploadTable> and related bid slices to maintain order during sorted data */
  loanId: string;
  loanNumber: string;
  investorId: string;
  interestMinRate: number;
  interestMaxRate: number;
  specPool: string;
  servicerFirm: string;
  servicerFee: ServicingRate;
}

export interface IBulkTrade {
  _id: string;
  clientName: string;
  dealer: string;
  tradeDate: string;
  settlementDate: string;
  originalSize: string;
  type: string;
  coupon: number;
  originalPrice: number;
  cusip: string;
}

export interface IBulkAOTGroup {
  coupon: IBidSecuritySlot['name'];
  loans: IAOTGroupLoan[];
  trades: IAOTGroupTrade[];
}

export interface IAOTGroupLoan extends Pick<IBulkGroupLoan, 'loanNumber' | 'noteAmount'> {
  isSelected: boolean;
  _securitySlot: IBidSecuritySlot;
}

export interface IAOTGroupTrade
  extends Pick<IBulkTrade, 'clientName' | 'dealer' | 'originalSize' | 'tradeDate' | 'originalPrice' | 'cusip'> {
  aotAmount: string;
  _coupon: IBidSecuritySlot['name'];
}

export interface IAOTTableCoupon extends IBulkAOTGroup {
  aggregateLoanAmount: string; // 1000000
  aggregateAOTAmount: string; // 1500000
  overUnderAmount: string; // +32000 / -32000
  mortgagesSelected: string; // 3 of 3 selected
}

export interface IBulkGroupLoan
  extends Pick<IBulkLoan, 'loanNumber' | 'servicerFee' | 'investorId' | 'servicerFirm' | 'specPool'> {
  bidAmount: string;
  noteAmount: string;
  interestRate: string;
  _bidAgent: IBid['agent'];
  _bidSeller: IBid['seller'];
  _securitySlots: IBid['securitySlots'];
  _productId: string | null; // '1002'/'1006'/...
  _interestMaxRate: IBulkLoan['interestMaxRate'];
  _interestMinRate: IBulkLoan['interestMinRate'];
  _internalMortgageId: IMortgageFlat['internalId'];
}

export interface IBulkLoansGroup
  extends Omit<IBulkLoan, 'loanId' | 'loanNumber' | 'interestMaxRate' | 'interestMinRate'>,
    Pick<IBulkGroupLoan, '_productId' | '_interestMaxRate' | '_interestMinRate' | '_bidAgent' | '_bidSeller'>,
    Pick<IBulkContract, 'aot'> {
  id: string;
  isPolling?: boolean;
  apiStatus?: IBulkContractAPIStatus;
  apiCommitErrorMessage?: IBulkContract['statusMessage'];
  contractName: string;
  contractAmount: string;
  status: ContractStatus | null;
  loans: IBulkGroupLoan[];
  interestRateRange: string;
}

export enum ContractStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE'
}
