import useGetCurrentUserQuery from './useGetCurrentUserQuery';
import { useEffect } from 'react';
import { setCurrentUser } from '../user.slice';
import { useDispatch } from 'react-redux';

const useGetCurrentUser = () => {
  const dispatch = useDispatch();
  const query = useGetCurrentUserQuery();

  useEffect(() => {
    if (query.data) {
      dispatch(setCurrentUser(query.data));
    }
  }, [query.data, dispatch]);

  return query;
};

export default useGetCurrentUser;
