import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { ChangePasswordData } from '../slices/authentication/authentication.slice';
import { IAuthRepository } from 'shared/api/interfaces/IAuthRepository';

export class AuthThunks {
  private readonly authRepository!: IAuthRepository;

  constructor(_authRepository: IAuthRepository) {
    this.authRepository = _authRepository;

    if (this.authRepository === null) {
      throw new Error('authRepository has not been instantiated!');
    }
  }

  private baseIdentifier = 'auth';

  changePassword = (): AsyncThunk<AxiosResponse<string, any>, ChangePasswordData, Record<string, unknown>> => {
    const action = `${this.baseIdentifier}/changePassword`;

    return createAsyncThunk(action, (data) => this.authRepository.changePassword(data));
  };
}
