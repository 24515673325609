import { PayloadAction } from '@reduxjs/toolkit';

export type CreateAuth0UserAPIError =
  | {
      message: string;
      status: 500;
    }
  | {
      message: string[];
      statusCode: 400;
    };

export type CreateAuth0UserErrorPayload = PayloadAction<{ data?: CreateAuth0UserAPIError }>;

export const CREATE_USER_API_ERROR_TO_MSG: Record<string, string> = {
  'user.auth0Id or user.email is duplicated': 'A user with that email already exists.'
};

export const DEFAULT_ERROR_MESSAGE = 'Connection error while creating user. Try Again.';

export const getCreateAuth0UserErrorMessage = (data?: CreateAuth0UserAPIError) => {
  if (!data || !Array.isArray(data.message) || !data.message.length) {
    return DEFAULT_ERROR_MESSAGE;
  }

  const firstErrorMsg = data.message[0];

  return CREATE_USER_API_ERROR_TO_MSG[firstErrorMsg] || DEFAULT_ERROR_MESSAGE;
};
