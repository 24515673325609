// This is a similar implementation to what the backend uses with minor
// differences to account for browser vs nodejs interfaces.
export interface ReconnectingWebSocketOptions {
  /**
   * Max time in seconds to wait before retrying a connection. Defaults to 30.
   */
  maxBackoffSeconds: number;

  /**
   * If true, closes and restarts the connection on errors. Defaults to true.
   */
  restartOnError: boolean;

  /**
   * Number of seconds that need to elapse before considering the connection
   * stable and reset the connection attempt count. Defaults to 30.
   */
  minStableSeconds: number;

  /**
   * Handle the socket open event. This is called after the socket is opened
   * @returns A promise that resolves after socket open is handled.
   */
  onOpen: (socket: any) => Promise<void>;

  /**
   * The message handler. This is called after a message is received from the socket.
   * @param message String message received from the socket.
   * @returns A promise that resolves after the message is handled.
   */
  onMessage: (message: string) => Promise<void>;
}

/**
 * Websocket that will reconnect if the socket connection closes or encounters
 * an error.
 */
export class ReconnectingWebSocket {
  private logger = console;

  private currentSocket?: WebSocket;

  private intentionallyStopped = false;

  private isStarted = false;

  private initTimeout: NodeJS.Timeout | null = null;

  private attempts = 0;

  private lastStartTimestamp = Date.now();

  private options: ReconnectingWebSocketOptions;

  constructor(private socketFactory: () => Promise<WebSocket>, options?: Partial<ReconnectingWebSocketOptions>) {
    this.options = {
      maxBackoffSeconds: options?.maxBackoffSeconds ?? 30,
      restartOnError: options?.restartOnError ?? true,
      minStableSeconds: options?.minStableSeconds ?? 30,
      onOpen: options?.onOpen ?? Promise.resolve,
      onMessage: options?.onMessage ?? Promise.resolve
    };
  }

  private get backoffMilliseconds(): number {
    return Math.min(2 ** this.attempts++, this.options.maxBackoffSeconds) * 1000;
  }

  public stop(): void {
    this.intentionallyStopped = true;
    this.currentSocket?.close();
    this.currentSocket = undefined;
  }

  public async start(): Promise<void> {
    while (!this.isStarted && !this.intentionallyStopped) {
      try {
        await this.initSocket();
        this.checkStability();
        this.isStarted = true;
        this.intentionallyStopped = false;
      } catch (error) {
        this.logger.debug({ message: 'Encountered error: {error}', error });
        await this.wait(this.backoffMilliseconds);
      }
    }
  }

  public send(message: string): void {
    this.currentSocket?.send(message);
  }

  public wait(milliseconds: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  }

  private checkStability(): void {
    this.lastStartTimestamp = Date.now();

    if (this.initTimeout) {
      clearTimeout(this.initTimeout);
    }

    this.initTimeout = setTimeout(() => {
      const secondsSinceLastStart = (Date.now() - this.lastStartTimestamp) / 1000;

      if (secondsSinceLastStart >= this.options.minStableSeconds) {
        this.attempts = 0;
      }
    }, this.options.minStableSeconds * 1000);
  }

  private handleError(error?: unknown) {
    this.logger.error({ message: 'Encountered error in web socket.', error });

    // Terminate the current socket if we should consider all errors as fatal
    if (this.options.restartOnError) {
      if (this.currentSocket?.readyState !== WebSocket.CLOSED) {
        this.currentSocket?.close();
      } else {
        // If the websocket is already closed, manually trigger the close handler
        this.handleClose(-1, `${error}`);
      }
    }
  }

  private handleClose(code: number, reason: string) {
    this.logger.debug({
      message: 'Handling close event',
      code,
      reason: reason.toString()
    });
    this.isStarted = false;

    // If we were expecting this close event, stop here and don't attempt a reconnect
    if (this.intentionallyStopped) {
      this.logger.debug({ message: 'Received expected stop. Not restarting.' });
      this.intentionallyStopped = false;
      return;
    }

    this.wait(this.backoffMilliseconds).then(() => this.start());
  }

  private async initSocket(): Promise<void> {
    this.logger.debug({ message: 'Initializing new socket' });
    this.currentSocket = await this.socketFactory();

    this.currentSocket.onopen = async () => {
      try {
        await this.options.onOpen(this.currentSocket);
      } catch (error) {
        this.handleError(error);
      }
    };

    this.currentSocket.onerror = (error: Event) => {
      this.handleError(error);
    };

    this.currentSocket.onclose = (event: CloseEvent) => {
      const { code, reason } = event;

      this.handleClose(code, reason);
    };

    this.currentSocket.onmessage = async (event: MessageEvent) => {
      try {
        await this.options.onMessage(event.data.toString());
      } catch (error) {
        this.handleError(error);
      }
    };
  }
}
