import { createSlice } from '@reduxjs/toolkit';

export type TableFilterType = {
  searchText: string;
  executeDownload: boolean;
};

export const initialState: TableFilterType = {
  searchText: '',
  executeDownload: false
};

export const tableFiltersSlice = createSlice({
  name: 'tableFilters',
  initialState,
  reducers: {
    setSearchText(state, action) {
      state.searchText = action.payload;
    },
    fireDownload(state: TableFilterType) {
      state.executeDownload = true;
    },
    clearDownload(state: TableFilterType) {
      state.executeDownload = false;
    }
  }
});

export const { setSearchText, fireDownload, clearDownload } = tableFiltersSlice.actions;
export const tableFiltersReducer = tableFiltersSlice.reducer;
