import { AxiosResponse } from 'axios';

import { injectable } from 'inversify';

import 'reflect-metadata';

import { client } from 'shared/api/clients/client';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';
import { IMortgageRepository, MarketplaceFilter } from 'shared/api/interfaces/IMortgageRepository';
import { IMortgage, IMortgageHistory } from 'shared/models/Mortgage';
import { IPreviewBidTapeQueryParams } from 'shared/models/BidTape';
import { IResponseStatus } from 'shared/models/shared';

@injectable()
export class MortgageRepository implements IMortgageRepository {
  private baseRepository = new BaseRepository();

  apiUrl = async (): Promise<string> => {
    return this.baseRepository.getApiUrl((await this.baseRepository.getProductString()) + '/v1/mortgage');
  };

  apiUrl2 = async (): Promise<string> => {
    return this.baseRepository.getApiUrl((await this.baseRepository.getProductString()) + '/v2/mortgage');
  };

  apiUrl3 = async (): Promise<string> => {
    return this.baseRepository.getApiUrl((await this.baseRepository.getProductString()) + '/v3/mortgage');
  };

  getAll = async (): Promise<AxiosResponse<any, any>> => {
    throw new Error('Method not implemented.');
  };

  getDraftMortgages = async (marketplace: MarketplaceFilter): Promise<AxiosResponse<IMortgage[]>> => {
    return await client.get(`${await this.apiUrl3()}?status=Draft&marketplace=${marketplace}`);
  };

  getOpenMortgages = async (marketplace: MarketplaceFilter): Promise<AxiosResponse<IMortgage[]>> => {
    return await client.get(`${await this.apiUrl3()}?status=Open&marketplace=${marketplace}`);
  };

  getPendingMortgages = async (marketplace: MarketplaceFilter): Promise<AxiosResponse<IMortgage[]>> => {
    return await client.get(`${await this.apiUrl3()}?status=Pending&marketplace=${marketplace}`);
  };

  getSoldMortgages = async (): Promise<AxiosResponse<IMortgage[]>> => {
    return await client.get(`${await this.apiUrl3()}/sold`);
  };

  getDeactivatedMortgages = async (): Promise<AxiosResponse<IMortgage[]>> => {
    return await client.get(`${await this.apiUrl3()}/deactivated`);
  };

  getOne = async (id: string): Promise<AxiosResponse<IMortgage>> => {
    return await client.get(`${await this.apiUrl3()}/${id}`);
  };

  // todo: add full interface
  getOneFull = async (id: string): Promise<AxiosResponse<IMortgage>> => {
    return await client.get(`${await this.apiUrl3()}/full-data/${id}`);
  };

  add = async (mortgage: any): Promise<AxiosResponse> => {
    return await client.post(await this.apiUrl(), mortgage);
  };

  update = async (id: string, mortgage: any): Promise<AxiosResponse> => {
    return await client.put(`${await this.apiUrl()}/${id}`, mortgage);
  };

  openMortgages = async (internalIds: string[]): Promise<AxiosResponse<IResponseStatus>> => {
    return await client.post(`${await this.apiUrl3()}/open-mortgages`, internalIds);
  };

  delete = async (id: string): Promise<AxiosResponse> => {
    return await client.delete(`${await this.apiUrl3()}/${id}`);
  };

  queryMortgages = async (
    params: IPreviewBidTapeQueryParams,
    signal?: AbortSignal
  ): Promise<AxiosResponse<IMortgage[]>> => {
    return await client.post(`${await this.apiUrl3()}/preview-bid-tape`, params, { signal });
  };

  transfer = async (mortgageId: string): Promise<AxiosResponse> => {
    return await client.put(`${await this.apiUrl3()}/transfer/${mortgageId}`);
  };

  deactivateMortgage = async (internalId: string): Promise<AxiosResponse<IResponseStatus>> => {
    return await client.put(`${await this.apiUrl3()}/deactivate-mortgage/${internalId}`);
  };

  reactivateMortgage = async (internalId: string): Promise<AxiosResponse<IResponseStatus>> => {
    return await client.put(`${await this.apiUrl3()}/reactivate-mortgage/${internalId}`);
  };

  getMortgageXmlById = async (internalId: string): Promise<AxiosResponse<IResponseStatus>> => {
    return await client.get(`${await this.apiUrl3()}/export-mismo/${internalId}/uldd`);
  };

  getBulkMortgageXml = async (mortgageIds: string[]): Promise<AxiosResponse<IResponseStatus>> => {
    return await client.post(`${await this.apiUrl3()}/export-bulk/uldd`, { mortgageIds });
  };

  getMortgageCsvById = async (internalId: string): Promise<AxiosResponse<IResponseStatus>> => {
    return await client.get(`${await this.apiUrl3()}/${internalId}/csv`);
  };

  getMortgageDocumentsZip = async (mortgageId: string): Promise<AxiosResponse<IResponseStatus>> => {
    return await client.get(`${await this.apiUrl()}-document/${mortgageId}/zip-file`);
  };

  getMortgageDocumentsStatus = async (mortgageId: string): Promise<AxiosResponse<IResponseStatus>> => {
    return await client.get(`${await this.apiUrl()}-document/${mortgageId}/transfer-status`);
  };

  getMortgageHistory = async (
    mortgageId: string,
    offset: number,
    limit: number
  ): Promise<AxiosResponse<IMortgageHistory>> => {
    return await client.get(`${await this.apiUrl3()}/history/${mortgageId}?offset=${offset}&limit=${limit}`);
  };
}
