import { IMortgageFlat, MortgageStatus } from 'shared/models/Mortgage';
import useGetMortgageByIdQuery from './useGetMortgageByIdQuery';
import { useEffect } from 'react';
import { useAppDispatch } from 'state-management/hooks';
import {
  appendToDeactivatedMortgages,
  appendToDraftMortgages,
  appendToOpenMortgages,
  appendToPendingMortgages,
  appendToSoldMortgages
} from '../mortgage.slice';

const useFetchOneMortgage = (mortgageId: IMortgageFlat['internalId']) => {
  const dispatch = useAppDispatch();

  const { data, refetch, isLoading } = useGetMortgageByIdQuery(mortgageId);

  useEffect(() => {
    if (data?.status === MortgageStatus.Draft) {
      dispatch(appendToDraftMortgages({ data }));
    }

    if (data?.status === MortgageStatus.Open) {
      dispatch(appendToOpenMortgages({ data }));
    }

    if (data?.status === MortgageStatus.Pending) {
      dispatch(appendToPendingMortgages({ data }));
    }

    if (data?.status === MortgageStatus.Sold) {
      dispatch(appendToSoldMortgages({ data }));
    }

    if (data?.status === MortgageStatus.Deactivated) {
      dispatch(appendToDeactivatedMortgages({ data }));
    }
  }, [data, dispatch]);

  return { refetch, isLoading };
};

export default useFetchOneMortgage;
