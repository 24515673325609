import { IBulkAOTGroup, IBulkLoansGroup } from '../../../../shared/models/Loan';
import { _ungroupAOTByDealerAccount } from './aot.grouping';
import { AOT } from '../../../../shared/models/Bulk';
import { groupBy, isArray, mergeWith, partition } from 'lodash';
import { getNewContractId } from '../bulk-grouping/utils';

const aotContractGroupingAttributed: (keyof AOT)[] = [
  'couponRate',
  'settlementDate',
  'securityInstrument',
  'originalSize',
  'price',
  'tradeDate',
  'dealer',
  'size'
];

const mergeIds = <T extends AOT>(objValue: T[keyof T], srcValue: keyof T[keyof T]) => {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
};

const getMultiGroupKey = (aot: AOT): string =>
  aotContractGroupingAttributed.map((attribute) => aot[attribute]).join(',');

export const _createBulkGroupsWithAOT = (
  aotGroups: IBulkAOTGroup[],
  contractGroups: IBulkLoansGroup[]
): IBulkLoansGroup[] => {
  /* Select loans by trade */
  // Filter out AOT groups that do not have any selected loans
  let aotGroupsWithActiveLoans = aotGroups.filter((group) => group.loans.some((loan) => loan.isSelected));

  if (!aotGroupsWithActiveLoans.length) {
    return contractGroups;
  }

  // Filter out loans that are not selected
  aotGroupsWithActiveLoans = aotGroupsWithActiveLoans.map((group) => ({
    ...group,
    loans: group.loans.filter((loan) => loan.isSelected)
  }));

  const loansByTrade = groupBy(_ungroupAOTByDealerAccount(aotGroupsWithActiveLoans), 'loans[0].loanNumber');
  /**/

  return [...contractGroups].flatMap((contractGroup) => {
    const [contractLoansWithAot, contractLoansWithoutAot] = partition(
      contractGroup.loans,
      (loan) => loansByTrade[loan.loanNumber]
    );

    // If no loans in the contract have AOT, return the contract as is
    if (!contractLoansWithAot.length) {
      return contractGroup;
    }

    // Otherwise add AOT trades to the contract
    let aotTrades = [...(contractGroup.aot || [])];

    contractLoansWithAot.forEach((loan) => {
      loansByTrade[loan.loanNumber].forEach((aotGroup) => {
        aotTrades.push({
          couponRate: aotGroup.loans[0]._securitySlot.couponRate,
          settlementDate: aotGroup.loans[0]._securitySlot.settlementDate,
          securityInstrument: aotGroup.loans[0]._securitySlot.securityInstrument,
          originalSize: `${aotGroup.trades[0].originalSize}`, // integer
          price: `${aotGroup.trades[0].originalPrice}`,
          tradeDate: aotGroup.trades[0].tradeDate,
          dealer: aotGroup.trades[0].dealer,
          size: `${aotGroup.trades[0].aotAmount}`,
          mortgages: [loan._internalMortgageId],
          cusip: aotGroup.trades[0].cusip
        });
      });
    });

    // Because aotTrades are by loan, we need to group them by the same values
    aotTrades = Object.values(groupBy(aotTrades, getMultiGroupKey)).map((aotGroupsWithSameValues) =>
      aotGroupsWithSameValues.reduce((accum, aot) => mergeWith(accum, aot, mergeIds), {} as AOT)
    );

    // If there are loans without AOT, Split contract group into two
    if (contractLoansWithoutAot.length) {
      return [
        {
          ...contractGroup,
          id: getNewContractId(),
          aot: aotTrades,
          loans: contractLoansWithAot
        },
        {
          ...contractGroup,
          id: getNewContractId(),
          loans: contractLoansWithoutAot
        }
      ];
    } else {
      return [
        {
          ...contractGroup,
          id: getNewContractId(),
          aot: aotTrades
        }
      ];
    }
  });
};
