import { createSlice } from '@reduxjs/toolkit';
import { mapPayupsToMortgageId } from '../../helpers/slice.helper';
import { PayupThunks } from '../../thunks/payup.thunks';
import { IPayupRepository } from 'shared/api/interfaces/IPayupRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { IPayup } from 'shared/models/Payup';

export type PayupMap<T = IPayup> = { [internalMortgageId: string]: T[] };

export type PayupSlice = {
  payups: PayupMap;
};

const initialState: PayupSlice = {
  payups: {}
};
const payupRepository = container.get<IPayupRepository>(SERVICE_KEYS.PAYUP_REPOSITORY);
const payupThunks = new PayupThunks(payupRepository);

export const fetchAllPayups = payupThunks.getAll();
export const fetchPayups = payupThunks.fetchByMortgageId();
const payupSlice = createSlice({
  name: 'payup',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllPayups.fulfilled, (state, { payload }) => {
      const { data: incomingPayups = [] } = payload ?? {};

      if (incomingPayups.length === 0) {
        state.payups = {};
      } else {
        state.payups = mapPayupsToMortgageId(incomingPayups);
      }
    });

    builder.addCase(fetchPayups.fulfilled, (state, { payload }) => {
      const { data: incomingPayups = [] } = payload ?? {};

      if (incomingPayups.length > 0 && incomingPayups[0]?.internalMortgageId !== undefined)
        state.payups[incomingPayups[0].internalMortgageId] = incomingPayups;
    });
  }
});

export default payupSlice.reducer;
