import { createSelector } from 'reselect';
import { RootState } from '../../store';
import { selectOriginatorAvailableMortgages } from '../bid/bid.selectors';
import {
  selectSoldMortgages,
  selectDraftMortgages,
  selectPendingMortgages,
  selectDeactivatedMortgages
} from '../mortgage/mortgage.selectors';
import { IMortgageFlat } from 'shared/models/Mortgage';
import { VIEW_ALL_CATEGORY_ID } from 'Sections/Originator/Pages/QualityControl/DocumentReview/hooks/useSidebarNav';
import { MortgageStatus } from 'shared/models/Mortgage';
import {
  _selectChecklistDocumentViewersByRuleId,
  _selectChecklistRulesForMortgageDocument,
  _selectChecklistTableDataForMortgageFn
} from './selector-functions';
import { ChecklistReviewFilter } from '../../../Sections/Originator/Pages/Quality/CloseChecklist/components/ChecklistTablePanel/ChecklistTableHeader';
import { LQAValidationResultKey } from 'shared/models/Validations';
import { LPADataCompareValidationResult, NormalizedLQAValidationInfo } from '../quality/types';
import { AnalysisDocumentStatus, IAnalysisCategory } from 'shared/models/Analysis-Document';

const selectChecklistDocumentsForMortgage = (state: RootState) =>
  state.analysisDocuments.analysisChecklistDocumentsById;

export const selectDocumentDetails = (state: RootState) => state.analysisDocuments.documentDetails;
export const selectPreviewDocuments = (state: RootState) => state.analysisDocuments.previewDocuments;
export const selectLQAValidations = (state: RootState) => state.analysisDocuments.lqaValidations;
export const selectChecklistCategories = (state: RootState) => state.analysisDocuments.analysisChecklistCategories;

export const makeSelectLQAValidationsByMortgageId = () =>
  createSelector(
    selectLQAValidations,
    (_: RootState, mortgageId: string) => mortgageId,
    (lqaValidations, mortgageId) => lqaValidations[mortgageId]
  );

export const selectDocumentStatus = createSelector(
  (state: RootState) => state.analysisDocuments.documentStatus,
  (documentStatus) => documentStatus
);

export const _selectPreviewDocumentsByMortgageId = createSelector(
  selectPreviewDocuments,
  (_: RootState, mortgageId: string) => mortgageId,
  (previewDocuments, mortgageId) => previewDocuments[mortgageId] ?? []
);

export const makeSelectPreviewDocumentsByMortgageId = () => _selectPreviewDocumentsByMortgageId;

export const makeSelectUnarchivedPreviewDocumentsByMortgageId = () => {
  const selectPreviewDocumentsByMortgageId = makeSelectPreviewDocumentsByMortgageId();

  return createSelector(
    selectPreviewDocumentsByMortgageId,
    (_: RootState, mortgageId: string) => mortgageId,
    (previewDocuments) => previewDocuments?.filter((doc) => doc.state !== AnalysisDocumentStatus.ARCHIVED)
  );
};

export const _selectPreviewDocumentsByMortgageIdAndCategory = createSelector(
  _selectPreviewDocumentsByMortgageId,
  (_: RootState, __: string, category: string) => category,
  (documents, category) => {
    // all mortgage docs with a category
    if (category.toLowerCase() === VIEW_ALL_CATEGORY_ID) {
      return documents.filter(({ category: _category }) => !!_category);
    }

    const documentsByCategory = documents.filter(({ category: _category }) => _category && _category === category);

    return documentsByCategory;
  }
);

export const makeSelectPreviewDocumentsByMortgageIdAndCategory = () => _selectPreviewDocumentsByMortgageIdAndCategory;

export const makeUncategorizedDocsForMortgageId = () =>
  createSelector(_selectPreviewDocumentsByMortgageId, (documents) => {
    const uncategorizedDocs = documents.filter(({ category: _category }) => !_category);

    return uncategorizedDocs;
  });

export const _selectDocumentDetailsByDocumentId = createSelector(
  selectDocumentDetails,
  (_: RootState, mortgageId: string) => mortgageId,
  (_: RootState, __: string, documentId: string) => documentId,
  (documentDetails, mortgageId, documentId) => {
    return documentDetails?.[mortgageId]?.[documentId] || null;
  }
);

export const makeSelectDocumentDetailsByDocumentId = () => _selectDocumentDetailsByDocumentId;

export const _selectPercentageCompleteByMortgageId = createSelector(
  selectPreviewDocuments,
  (_: RootState, mortgageId: string) => mortgageId,
  (documents, mortgageId) => {
    const docs = documents[mortgageId];

    if (!docs?.length) return 0;

    const completedDocs = docs.filter(({ category }) => !!category).length;

    return Math.round((completedDocs / docs.length) * 100);
  }
);

export const makeSelectPercentageCompleteByMortgageId = () => _selectPercentageCompleteByMortgageId;

export const _getMortgageSelectorByLoanStatus = (status: MortgageStatus) => {
  switch (status) {
    case MortgageStatus.Draft:
      return selectDraftMortgages;
    case MortgageStatus.Open:
      return selectOriginatorAvailableMortgages;
    case MortgageStatus.Pending:
      return selectPendingMortgages;
    case MortgageStatus.Sold:
      return selectSoldMortgages;
    case MortgageStatus.Deactivated:
      return selectDeactivatedMortgages;
    default:
      console.error('Invalid mortgage status passed');
      return () => [];
  }
};

export const makeSelectMortgageByMortgageId = (status: MortgageStatus) => {
  const _selectAllMortgages = _getMortgageSelectorByLoanStatus(status);

  return createSelector(
    _selectAllMortgages,
    (_: RootState, mortgageId: IMortgageFlat['internalId']) => mortgageId,
    (mortgages, mortgageId) => {
      return mortgages?.find((mortgage) => mortgage.internalId === mortgageId) || null;
    }
  );
};

export const makeSelectLoanNumberByMortgageId = (status: MortgageStatus) => {
  const _selectAllMortgages = _getMortgageSelectorByLoanStatus(status);

  return createSelector(
    _selectAllMortgages,
    (_: RootState, mortgageId: IMortgageFlat['internalId']) => mortgageId,
    (mortgages, mortgageId) => {
      return mortgages?.find((mortgage) => mortgage.internalId === mortgageId)?.loanNumber || '';
    }
  );
};

export const hasDocumentsIncompleteState = (category: IAnalysisCategory) =>
  category.differentiators.some((item) => {
    return item.documents.some((doc) =>
      [AnalysisDocumentStatus.MISSING, AnalysisDocumentStatus.PROCESSING, AnalysisDocumentStatus.ERROR].includes(
        doc.state
      )
    );
  });

export const makeSelectChecklistCategoriesForMortgage = () =>
  createSelector(
    selectChecklistCategories,
    (_: RootState, mortgageId: string) => mortgageId,
    (_: RootState, __: string, filter: ChecklistReviewFilter) => filter,
    (analysisChecklistCategories, mortgageId, filter) => {
      // Filter categories based on the filter
      let filteredCategories = analysisChecklistCategories[mortgageId] || [];

      if (filter === ChecklistReviewFilter.Complete) {
        filteredCategories = filteredCategories.filter(
          (category) => category.rulesToComplete === 0 && !hasDocumentsIncompleteState(category)
        );
      } else if (filter === ChecklistReviewFilter.Review) {
        filteredCategories = filteredCategories.filter((category) => {
          return category.rulesToComplete !== 0 || hasDocumentsIncompleteState(category);
        });
      }

      return filteredCategories;
    }
  );

// todo: remove ChecklistReviewFilter param is not used
export const makeSelectChecklistTableDataForMortgage = () => {
  const _selectChecklistCategories = makeSelectChecklistCategoriesForMortgage();

  return createSelector(
    _selectChecklistCategories,
    (_: RootState, mortgageId: string) => mortgageId,
    (_: RootState, __: string, filter: ChecklistReviewFilter) => filter,
    _selectChecklistTableDataForMortgageFn
  );
};

export const makeSelectChecklistCategoryNames = () => {
  const _selectChecklistCategories = makeSelectChecklistCategoriesForMortgage();

  return createSelector(
    _selectChecklistCategories,
    (_: RootState, mortgageId: string) => mortgageId,
    // todo: remove ChecklistReviewFilter param is not used
    (_: RootState, __: string, filter: ChecklistReviewFilter) => filter,

    (analysisChecklistCategories: IAnalysisCategory[]) => {
      return analysisChecklistCategories.map((category) => category.category);
    }
  );
};

export const makeSelectChecklistRulesForMortgageByDocumentId = () => {
  const _selectChecklistDocumentForMortgageById = makeSelectChecklistDocumentForMortgageById();

  return createSelector(_selectChecklistDocumentForMortgageById, (document) => {
    if (!document) return null;
    return _selectChecklistRulesForMortgageDocument(document);
  });
};

export const makeSelectChecklistDocumentViewersByRuleId = () => {
  const _selectChecklistDocumentForMortgageById = makeSelectChecklistDocumentForMortgageById();

  return createSelector(
    _selectChecklistDocumentForMortgageById,
    _selectDocumentDetailsByDocumentId,
    (_: RootState, __: string, ___: string, ruleId: string) => ruleId,
    _selectChecklistDocumentViewersByRuleId
  );
};

export const makeSelectChecklistDocumentForMortgageById = () =>
  createSelector(
    selectChecklistDocumentsForMortgage,
    (_: RootState, mortgageId: string) => mortgageId,
    (_: RootState, __: string, documentId: string) => documentId,
    (documents, mortgageId, documentId) => {
      if (!documents[mortgageId] || !documents[mortgageId][documentId]) return null;
      return documents[mortgageId][documentId];
    }
  );

export const makeSelectLQAValidationResultsTableRows = () => {
  const _selectValidationsByMortgageId = makeSelectLQAValidationsByMortgageId();

  return createSelector(
    _selectValidationsByMortgageId,
    (_: RootState, mortgageId: string) => mortgageId,
    (validationsForMortgage) =>
      (validationsForMortgage?.validations ?? []).map((validation) => ({
        label: validation.name,
        value: validation?.results?.length ?? 0,
        type: validation.type as LQAValidationResultKey
      }))
  );
};

export const makeSelectLQAValidationErrorsTableRows = () => {
  const _selectValidationsByMortgageId = makeSelectLQAValidationsByMortgageId();

  return createSelector(
    _selectValidationsByMortgageId,
    (_: RootState, mortgageId: string) => mortgageId,
    (_: RootState, __: string, type: NormalizedLQAValidationInfo['type'] | null) => type,
    (validationsForMortgage, mortgageId, type) => {
      if (!type) {
        return [];
      }
      const errors = validationsForMortgage?.validations.find(
        (validation: NormalizedLQAValidationInfo) => validation.type === type
      );
      const results = errors?.results ?? [];

      switch (type) {
        case LQAValidationResultKey.FREDDIE_LQA_LP_DATA_COMPARE:
          // LPA Data Compare (Data Field | LQA Value | LPA Value | Result | Message)
          return (results as LPADataCompareValidationResult[]).map((entry) => ({
            dataField: entry.name,
            lqaValue: entry['LQA Value'],
            lpaValue: entry['LPA Value'],
            result: entry.status,
            message: entry.message,
            nested: entry.nested
          }));
        default:
          // Default (Name | Message | Result)
          return results.map(({ name, message, status }) => ({
            name,
            message,
            result: status
          }));
      }
    }
  );
};
