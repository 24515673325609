import { MortgageStatus } from '../../../../../shared/models/Mortgage';

export interface ISuggestedCategory {
  category: string;
  name: string;
  confidence: number;
}

export type DocumentReviewParams = {
  status: MortgageStatus;
  mortgageId: string;
  documentId?: string;
  category?: string;
};

export enum DocumentReviewError {
  'DOCUMENT_LOAD_ERROR' = 'DOCUMENT_LOAD_ERROR',
  'INVALID_LOAN_ERROR' = 'INVALID_LOAN_ERROR',
  'INVALID_DOCUMENT_ERROR' = 'INVALID_DOCUMENT_ERROR',
  'INVALID_CATEGORY_ERROR' = 'INVALID_CATEGORY_ERROR',
  'INVALID_DOCUMENT_FILE' = 'INVALID_DOCUMENT_FILE'
}

export const ERROR_MESSAGE: Record<DocumentReviewError, string> = {
  [DocumentReviewError.DOCUMENT_LOAD_ERROR]: 'An error occurred while trying to load your document.',
  [DocumentReviewError.INVALID_LOAN_ERROR]: 'Invalid loan number. Specified loan number does not exist.',
  [DocumentReviewError.INVALID_DOCUMENT_ERROR]: 'Invalid document. Specified document does not exist.',
  [DocumentReviewError.INVALID_CATEGORY_ERROR]: 'Invalid category. Specified category does not exist.',
  [DocumentReviewError.INVALID_DOCUMENT_FILE]: 'Invalid file. Specified file does not exist.'
};
