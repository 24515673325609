import { IBaseThunk, thunkReactQueryMiddleware } from '../thunks/base.thunks';
import { IUserRepository } from 'shared/api/interfaces/IUserRepository';
import { ICreateAuth0User, IUser, IUserData } from 'shared/models/User';

export class UserThunks implements IBaseThunk<IUserData> {
  private readonly userRepository!: IUserRepository;

  constructor(_userRepository: IUserRepository) {
    this.userRepository = _userRepository;

    if (this.userRepository === null) {
      throw new Error('userRepository has not been instantiated!');
    }
  }

  private baseIdentifier = 'user';

  getAll() {
    const action = `${this.baseIdentifier}/fetchUsers`;

    return thunkReactQueryMiddleware<IUserData[], void>(action, () => ({
      queryFn: this.userRepository.getAll
    }));
  }

  getOne() {
    const action = `${this.baseIdentifier}/fetchUser`;

    return thunkReactQueryMiddleware<IUserData, string>(action, (id) => ({
      queryKey: [action, id],
      queryFn: () => this.userRepository.getOne(id)
    }));
  }

  getCurrentUser() {
    const action = `${this.baseIdentifier}/fetchCurrentUser`;

    return thunkReactQueryMiddleware<IUserData, void>(action, () => ({
      queryFn: this.userRepository.getCurrentUser
    }));
  }

  add() {
    const action = `${this.baseIdentifier}/addUser`;

    return thunkReactQueryMiddleware<IUserData, IUser>(action, (user) => ({
      queryKey: [action, user.email],
      queryFn: () => this.userRepository.add(user)
    }));
  }

  update() {
    const action = `${this.baseIdentifier}/updateUser`;

    return thunkReactQueryMiddleware<IUserData, { id: string; item: any }>(action, ({ id, item }) => ({
      queryKey: [action, id],
      queryFn: () => this.userRepository.update(id, item)
    }));
  }

  delete() {
    const action = `${this.baseIdentifier}/deleteUser`;

    return thunkReactQueryMiddleware<IUserData, string>(action, (id) => ({
      queryKey: [action, id],
      queryFn: () => this.userRepository.delete(id)
    }));
  }

  createAuth0User() {
    const action = `${this.baseIdentifier}/createAuth0User`;

    return thunkReactQueryMiddleware<IUserData, ICreateAuth0User>(action, (user) => ({
      queryKey: [action, user.email],
      queryFn: () => this.userRepository.createAuth0User(user)
    }));
  }

  resetPassword() {
    const action = `${this.baseIdentifier}/resetPassword`;

    return thunkReactQueryMiddleware<IUserData, { email: string; auth0ClientId: string }>(
      action,
      ({ email, auth0ClientId }) => ({
        queryKey: [action, email],
        queryFn: () => this.userRepository.resetPassword(email, auth0ClientId)
      })
    );
  }

  resetMFA() {
    const action = `${this.baseIdentifier}/resetMFA`;

    return thunkReactQueryMiddleware<IUserData, string>(action, (id) => ({
      queryKey: [action, id],
      queryFn: () => this.userRepository.resetMFA(id)
    }));
  }

  removeUser() {
    const action = `${this.baseIdentifier}/removeUser`;

    return thunkReactQueryMiddleware<IUserData, string>(action, (id) => ({
      queryKey: [action, id],
      queryFn: () => this.userRepository.removeUser(id)
    }));
  }

  updateAuth0User() {
    const action = `${this.baseIdentifier}/updateAuth0User`;

    return thunkReactQueryMiddleware<IUserData, { id: string; user: any }>(action, ({ id, user }) => ({
      queryKey: [action, id],
      queryFn: () => this.userRepository.updateAuth0User(id, user)
    }));
  }
}
