import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { client } from 'shared/api/clients/client';
import { IUserRepository } from 'shared/api/interfaces/IUserRepository';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';
import 'reflect-metadata';
import { ICreateAuth0User, IUpdateAuth0User, IUser, IUserData } from 'shared/models/User';

@injectable()
export class UserRepository implements IUserRepository {
  private baseRepository = new BaseRepository();

  apiUrl: string = this.baseRepository.getApiUrl('v2/users');

  getAll = async (): Promise<AxiosResponse<IUserData[]>> => {
    return await client.get(this.apiUrl);
  };

  getOne = async (id: string): Promise<AxiosResponse<IUserData[]>> => {
    return await client.get(`${this.apiUrl}/${id}`);
  };

  getCurrentUser = async (): Promise<AxiosResponse<IUser>> => {
    return await client.get(`${this.apiUrl}/currentUser`);
  };

  add = async (user: IUser): Promise<AxiosResponse> => {
    return await client.post(this.apiUrl, user);
  };

  update = async (id: string, user: IUser): Promise<AxiosResponse> => {
    return await client.put(`${this.apiUrl}/${id}`, user);
  };

  delete = async (id: string): Promise<AxiosResponse> => {
    return await client.delete(`${this.apiUrl}/${id}`);
  };

  createAuth0User = async (user: ICreateAuth0User): Promise<AxiosResponse> => {
    return await client.post(`${this.apiUrl}/createAuth0User`, user);
  };

  resetPassword = async (email: string, auth0ClientId: string): Promise<AxiosResponse> => {
    return await client.post(`${this.apiUrl}/resetPassword`, { email, auth0ClientId });
  };

  resetMFA = async (id: string): Promise<AxiosResponse> => {
    return await client.delete(`${this.apiUrl}/resetMFA/${id}`);
  };

  removeUser = async (id: string): Promise<AxiosResponse> => {
    return await client.delete(`${this.apiUrl}/removeUser/${id}`);
  };

  updateAuth0User = async (id: string, user: IUpdateAuth0User): Promise<AxiosResponse> => {
    return await client.put(`${this.apiUrl}/updateAuth0User/${id}`, user);
  };
}
