import { RootState } from '../../store';
import { createDeepEqualSelector } from '../../utils/selectors';

export const selectServicers = (state: RootState) => state.servicers;

export const makeSelectServicer = () =>
  createDeepEqualSelector(
    selectServicers,
    (_: RootState, bidKey: string) => bidKey,
    (servicers, bidKey) => servicers[bidKey]
  );
