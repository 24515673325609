import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getGlobalConfig } from '../../../state-management/slices/config/config.slice';
import { authManager } from 'shared/auth/auth-manager';
import { handleLogout } from 'shared/utils/logoutHelper';

export enum StatusCode {
  CONTINUE = 100,
  SWITCHING_PROTOCOLS = 101,
  PROCESSING = 102,
  EARLYHINTS = 103,
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NON_AUTHORITATIVE_INFORMATION = 203,
  NO_CONTENT = 204,
  RESET_CONTENT = 205,
  PARTIAL_CONTENT = 206,
  AMBIGUOUS = 300,
  MOVED_PERMANENTLY = 301,
  FOUND = 302,
  SEE_OTHER = 303,
  NOT_MODIFIED = 304,
  TEMPORARY_REDIRECT = 307,
  PERMANENT_REDIRECT = 308,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  NOT_ACCEPTABLE = 406,
  PROXY_AUTHENTICATION_REQUIRED = 407,
  REQUEST_TIMEOUT = 408,
  CONFLICT = 409,
  GONE = 410,
  LENGTH_REQUIRED = 411,
  PRECONDITION_FAILED = 412,
  PAYLOAD_TOO_LARGE = 413,
  URI_TOO_LONG = 414,
  UNSUPPORTED_MEDIA_TYPE = 415,
  REQUESTED_RANGE_NOT_SATISFIABLE = 416,
  EXPECTATION_FAILED = 417,
  I_AM_A_TEAPOT = 418,
  MISDIRECTED = 421,
  UNPROCESSABLE_ENTITY = 422,
  FAILED_DEPENDENCY = 424,
  PRECONDITION_REQUIRED = 428,
  TOO_MANY_REQUESTS = 429,
  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
  HTTP_VERSION_NOT_SUPPORTED = 505
}
export interface TokenRequestBody {
  client_id: string;
  grant_type: string;
  refresh_token?: string;
  client_secret?: string;
  audience?: string;
}

function headers(): Readonly<Record<string, string | boolean>> {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Origin': window.location.origin,
    'X-Requested-With': 'XMLHttpRequest'
  };
}

export abstract class Http {
  private instance: Promise<AxiosInstance> | null = null;

  public get http(): Promise<AxiosInstance> {
    return this.instance || (this.instance = this.initHttp());
  }

  private async initHttp() {
    const config = await getGlobalConfig();
    const http = axios.create({
      baseURL: config.backendUrl.replace('/api', ''),
      headers: headers()
    });

    http.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error;

        return this.handleError(response);
      }
    );

    return http;
  }

  private async addToken(axiosConfig?: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    return {
      ...axiosConfig,
      headers: {
        ...axiosConfig?.headers,
        Authorization: `Bearer ${authManager.accessToken}`
      }
    };
  }

  async request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    const configWithToken = await this.addToken(config);

    return (await this.http).request<T, R>(configWithToken);
  }

  async get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    const configWithToken = await this.addToken(config);

    return (await this.http).get<T, R>(url, configWithToken);
  }

  async post<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    const configWithToken = await this.addToken(config);

    return (await this.http).post<T, R>(url, data, configWithToken);
  }

  async put<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    const configWithToken = await this.addToken(config);

    return (await this.http).put<T, R>(url, data, configWithToken);
  }

  async patch<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    const configWithToken = await this.addToken(config);

    return (await this.http).patch<T, R>(url, data, configWithToken);
  }

  async delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    const configWithToken = await this.addToken(config);

    return (await this.http).delete<T, R>(url, configWithToken);
  }

  private async retryRequest(error: AxiosError) {
    const originalRequest = error.config;

    originalRequest.headers = {
      ...originalRequest.headers,
      ['Authorization']: `Bearer ${authManager.accessToken}`
    };

    return (await this.http)(originalRequest);
  }

  protected async handleError(error: any) {
    const status = error?.status;

    switch (status) {
      case StatusCode.INTERNAL_SERVER_ERROR: {
        if (error.data === 'jwt expired') {
          const refreshed = await authManager.refreshAccessToken();

          if (refreshed) {
            return this.retryRequest(error);
          }
        }
        break;
      }
      case StatusCode.FORBIDDEN: {
        break;
      }
      case StatusCode.UNAUTHORIZED: {
        handleLogout();
        break;
      }
      case StatusCode.TOO_MANY_REQUESTS: {
        break;
      }
    }

    return Promise.reject(error);
  }
}
