import { AsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IBaseThunk, thunkReactQueryMiddleware } from '../thunks/base.thunks';
import { IPayupRepository } from 'shared/api/interfaces/IPayupRepository';
import { IPayup } from 'shared/models/Payup';

export class PayupThunks implements IBaseThunk<IPayup> {
  private readonly payupRepository!: IPayupRepository;

  private baseIdentifier = 'payup';

  constructor(_payupRepository: IPayupRepository) {
    this.payupRepository = _payupRepository;

    if (this.payupRepository === null) {
      throw new Error('payupRepository has not been instantiated!');
    }
  }

  add(): AsyncThunk<AxiosResponse<IPayup, any>, any, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  update(): AsyncThunk<AxiosResponse<IPayup, any>, { id: string; item: IPayup }, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  delete(): AsyncThunk<AxiosResponse<IPayup, any>, string, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  getAll() {
    const action = `${this.baseIdentifier}/getAll`;

    return thunkReactQueryMiddleware<IPayup[], void>(action, () => ({ queryFn: this.payupRepository.getAll }));
  }

  fetchByMortgageId() {
    const action = `${this.baseIdentifier}/fetchPayupsByMortgageId`;

    return thunkReactQueryMiddleware<IPayup[], string>(action, (mortgageId) => ({
      queryKey: [action, mortgageId],
      queryFn: () => this.payupRepository.fetchByMortgageId(mortgageId)
    }));
  }
}
