import { AnyAction, createListenerMiddleware, isAnyOf, TypedStartListening } from '@reduxjs/toolkit';
import { commitSingleBulkV2, getBulkV2, getSingleBulkV2 } from './bulk.api.slice';
import { INITIAL_BULK_CONTRACT_STATUS } from './bulk.constants';
import { setSingleContractCompleted } from '../single-bulk/slice';
import { setContractCompleted, startPollingContract } from './bulk.slice';
import { AppDispatch, RootState } from '../../store';

type TBulkPollingStartListener = TypedStartListening<RootState, AppDispatch>;

const bulkPollingListener = createListenerMiddleware();
const startBulkPollingListening = bulkPollingListener.startListening as TBulkPollingStartListener;

const _getIsSingleBulk = (action: AnyAction) =>
  action.type === commitSingleBulkV2.fulfilled.type || action.type === getSingleBulkV2.fulfilled.type;
const _getPollingAction = (isSingle: boolean) => (isSingle ? getSingleBulkV2 : getBulkV2);

const _getPollingCompletedAction = (action: AnyAction) =>
  _getIsSingleBulk(action) ? setSingleContractCompleted : setContractCompleted;

const POLLING_INTERVAL = 1000;

// V2
startBulkPollingListening({
  actionCreator: startPollingContract,
  effect: async (action, api) => {
    const pollingAction = _getPollingAction(Boolean(action.payload.isSingle));

    await api.dispatch(pollingAction({ internalId: action.payload.id }));
  }
});

// For some reason inference does not work inline
// https://github.com/reduxjs/redux-toolkit/discussions/4018#discussioncomment-7952268
const isGetBulkAction = isAnyOf(getBulkV2.fulfilled, getSingleBulkV2.fulfilled);

startBulkPollingListening({
  matcher: isGetBulkAction,
  effect: (action, api) => {
    const { internalId, status } = action.payload.data;

    // Keep polling until status is Committed
    if (status !== INITIAL_BULK_CONTRACT_STATUS) {
      const completedAction = _getPollingCompletedAction(action);

      return void api.dispatch(completedAction(action.payload.data));
    }

    const pollingAction = _getPollingAction(_getIsSingleBulk(action));

    setTimeout(() => {
      api.dispatch(pollingAction({ internalId }));
    }, POLLING_INTERVAL);
  }
});

export default bulkPollingListener;
