import { createListenerMiddleware } from '@reduxjs/toolkit';
import { fetchBids } from './bid.slice';
import { initServicers, reconcileServicers } from '../servicer/servicer.slice';
import { initServicingRate, reconcileServicingRate } from '../servicing-rate/servicing-rate.slice';
import { RootState } from '../../store';

const bidListener = createListenerMiddleware();

bidListener.startListening({
  actionCreator: fetchBids.fulfilled,
  effect: (action, api) => {
    const bids = action.payload.data;

    const stateBeforeAction = api.getOriginalState() as RootState;
    const isInitialLoad = !stateBeforeAction.bids.initialLoadComplete;

    if (isInitialLoad) {
      const stateAfterAction = api.getState() as RootState;

      api.dispatch(initServicers(stateAfterAction.bids.bids));
      api.dispatch(initServicingRate(stateAfterAction.bids.bids));
    }

    api.dispatch(reconcileServicers(bids));
    api.dispatch(reconcileServicingRate(bids));
  }
});

export default bidListener;
