import { AsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IBaseThunk, thunkReactQueryMiddleware } from '../thunks/base.thunks';
import { IInvestorRepository } from 'shared/api/interfaces/IInvestorRepository';
import { IInvestor } from 'shared/models/Investor';
import { STALE_TIME_LONG } from '../../shared/api/react-query';

export class InvestorThunks implements IBaseThunk<IInvestor> {
  private readonly investorRepository!: IInvestorRepository;

  private baseIdentifier = 'investor';

  constructor(_investorRepository: IInvestorRepository) {
    this.investorRepository = _investorRepository;

    if (this.investorRepository === null) {
      throw new Error('investorRepository has not been instantiated!');
    }
  }

  add(): AsyncThunk<AxiosResponse<IInvestor, any>, any, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  update(): AsyncThunk<AxiosResponse<IInvestor, any>, { id: string; item: IInvestor }, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  delete(): AsyncThunk<AxiosResponse<IInvestor, any>, string, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  getAll() {
    const action = `${this.baseIdentifier}/fetchInvestors`;

    return thunkReactQueryMiddleware<IInvestor[], void>(action, () => ({
      staleTime: STALE_TIME_LONG,
      queryFn: this.investorRepository.getAll
    }));
  }
}
