import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { ChangePasswordData } from '../../../../state-management/slices/authentication/authentication.slice';
import { client } from 'shared/api/clients/client';
import { IAuthRepository } from 'shared/api/interfaces/IAuthRepository';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';
import 'reflect-metadata';

@injectable()
export class AuthRepository implements IAuthRepository {
  private baseRepository = new BaseRepository();

  apiUrl: string = this.baseRepository.getApiUrl('dbconnections/');

  changePassword = async (data: ChangePasswordData): Promise<AxiosResponse> => {
    return await client.post(this.apiUrl + 'change_password', data);
  };
}
