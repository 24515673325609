import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import {
  ContractStatus,
  IAOTGroupLoan,
  IBulkAOTGroup,
  IBulkLoan,
  IBulkLoansGroup,
  IBulkTrade
} from 'shared/models/Loan';
import {
  BulkLoansErrorsMap,
  BulkStep,
  DeleteBulkLoansPayload,
  UpdateBulkLoansErrorsPayload as UpdateBulkLoansErrorsPayload,
  InitBulkImportLoansPayload,
  IUpdateStepPayload,
  RemoveContractRowsByStatusPayload,
  RemoveBulkLoansErrorsPayload as RemoveBulkLoansErrorsPayload,
  RemoveContractRowsPayload,
  RemoveLoansByContractStatusPayload,
  InitBulkGroupsPayload,
  SetLoansSpreadsheetErrorPayload,
  UpdateBulkLoanPayload,
  StartPollingContractPayload,
  RemoveBulkTradesErrorsPayload,
  UpdateBulkTradesErrorsPayload,
  BulkTradesErrorsMap,
  InitBulkImportTradesPayload,
  DeleteBulkTradesPayload,
  SetTradesSpreadsheetErrorPayload,
  UpdateBulkTradePayload,
  SetAOTTradeAmountPayload
} from './types';
import {
  getBulkLoanKey,
  getBulkTradeKey,
  getLoanIdentifiersFromContractsByStatus,
  getNewBulkLoanId,
  getNewTradeId
} from './bulk.utils';
import bulkApiSlice from './bulk.api.slice';
import { IBulkContract, IBulkContractAPIStatus } from '../../../shared/models/Bulk';
import { _createBulkGroupsWithAOT } from './aot/save-aot';
import { getAOTGroups } from './aot/aot.grouping';

export type BulkSlice = {
  currentStep: BulkStep;
  importedSourceLoans: IBulkLoan[];
  importedSourceTrades: IBulkTrade[];
  importedSourceLoansErrors: BulkLoansErrorsMap;
  importedSourceTradesErrors: BulkTradesErrorsMap;
  importedContractData: IBulkLoansGroup[];
  importedAotGroupData: IBulkAOTGroup[];
  spreadsheetLoansImportError: string;
  spreadsheetTradesImportError: string;
  manageAotLoanNumberFilter: string;
};

// Whenever we add something to the slice state, we need to increment the version number and ensure the restore function can handle it
export const bulkStateVersion = '1.4.0';

export const initialBulkSliceState: BulkSlice = {
  importedContractData: [],
  importedSourceLoans: [],
  importedSourceTrades: [],
  importedAotGroupData: [],
  importedSourceLoansErrors: {},
  importedSourceTradesErrors: {},
  currentStep: BulkStep.UPLOAD_LOANS,
  spreadsheetLoansImportError: '',
  spreadsheetTradesImportError: '',
  manageAotLoanNumberFilter: ''
};

const bulkSlice = createSlice({
  name: 'bulk',
  initialState: initialBulkSliceState,
  extraReducers: bulkApiSlice,
  reducers: {
    initBulkImportLoans: (state, action: InitBulkImportLoansPayload) => {
      const { data } = action.payload;

      state.importedSourceLoans = data.map((x) => ({ ...x, loanId: getNewBulkLoanId() }));

      state.importedSourceLoans = data;
      state.importedSourceLoansErrors = {};
    },
    initBulkImportTrades: (state, action: InitBulkImportTradesPayload) => {
      const { data } = action.payload;

      state.importedSourceTrades = data.map((x) => ({ ...x, loanId: getNewTradeId() }));
      state.importedSourceTradesErrors = {};
    },
    addBulkLoans: (state, action: InitBulkImportLoansPayload) => {
      const { data } = action.payload;

      state.importedSourceLoans = [
        ...state.importedSourceLoans,
        ...data.map((x) => ({ ...x, loanId: getNewBulkLoanId() }))
      ];
      state.importedSourceLoansErrors = {};
    },
    addBulkTrades: (state, action: InitBulkImportTradesPayload) => {
      const { data } = action.payload;

      state.importedSourceTrades = [
        ...state.importedSourceTrades,
        ...data.map((x) => ({ ...x, _id: getNewTradeId() }))
      ];
      state.importedSourceTradesErrors = {};
    },
    resetState: (state) => {
      state.importedSourceLoans = [];
      state.importedSourceTrades = [];
      state.importedContractData = [];
      state.importedAotGroupData = [];
      state.importedSourceLoansErrors = {};
      state.importedSourceTradesErrors = {};
      state.currentStep = BulkStep.UPLOAD_LOANS;
      state.spreadsheetLoansImportError = '';
      state.spreadsheetTradesImportError = '';
      state.manageAotLoanNumberFilter = '';
    },
    resetUploadTradesState: (state) => {
      state.importedSourceTrades = [];
      state.importedAotGroupData = [];
      state.manageAotLoanNumberFilter = '';
      state.importedSourceTradesErrors = {};
      state.spreadsheetTradesImportError = '';
    },
    updateBulkLoansErrors: (state, action: UpdateBulkLoansErrorsPayload) => {
      const newErrors = { ...state.importedSourceLoansErrors, ...action.payload };

      // Remove any errors that are now empty
      Object.keys(newErrors).forEach((key) => {
        if (!Object.values(newErrors[key]).length) {
          delete newErrors[key];
        }
      });

      state.importedSourceLoansErrors = { ...newErrors };
    },
    updateBulkTradesErrors: (state, action: UpdateBulkTradesErrorsPayload) => {
      state.importedSourceTradesErrors = { ...action.payload };
    },
    removeLoanErrors: (state, action: RemoveBulkLoansErrorsPayload) => {
      const loans = action.payload;
      const updatedErrors = { ...state.importedSourceLoansErrors };

      loans.forEach((loan) => {
        delete updatedErrors[loan.loanId];
      });

      state.importedSourceLoansErrors = updatedErrors;
    },
    removeTradeErrors: (state, action: RemoveBulkTradesErrorsPayload) => {
      const trades = action.payload;
      const updatedErrors = { ...state.importedSourceTradesErrors };

      trades.forEach((trade) => {
        delete updatedErrors[trade._id];
      });

      state.importedSourceTradesErrors = updatedErrors;
    },
    deleteBulkLoans: (state, action: DeleteBulkLoansPayload) => {
      const { loanIds } = action.payload;
      const loanIdsSet = new Set(loanIds);

      state.importedSourceLoans = state.importedSourceLoans.filter((loan) => !loanIdsSet.has(loan.loanId));
    },
    deleteBulkTrades: (state, action: DeleteBulkTradesPayload) => {
      const { tradeIds } = action.payload;
      const tradeIdsSet = new Set(tradeIds);

      state.importedSourceTrades = state.importedSourceTrades.filter((trade) => !tradeIdsSet.has(trade._id));
    },
    initBulkGroups: (state, action: InitBulkGroupsPayload) => {
      state.importedContractData = action.payload;
    },
    triggerInitBulkGroups: (state) => {
      state.importedContractData = [];
    },
    removeContractRows: (state, action: RemoveContractRowsPayload) => {
      const data = current(state.importedContractData);
      const { groupId } = action.payload;
      const updatedContractRows = data.filter((contract) => contract.id !== groupId);

      state.importedContractData = updatedContractRows;
    },
    removeContractRowsByStatus: (state, action: RemoveContractRowsByStatusPayload) => {
      const data = state.importedContractData;
      const { status } = action.payload;
      const updatedContractRows = data.filter((contract) => contract.status !== status);

      state.importedContractData = updatedContractRows;
    },
    removeLoansByContractStatus: (state, action: RemoveLoansByContractStatusPayload) => {
      const successfulLoanIdentifiers = getLoanIdentifiersFromContractsByStatus(
        state.importedContractData,
        action.payload.status
      );

      state.importedSourceLoans = state.importedSourceLoans.filter(
        (loan) => !successfulLoanIdentifiers[getBulkLoanKey(loan)]
      );
    },
    updateStep: (state, action: IUpdateStepPayload) => {
      const { stepTo } = action.payload;

      state.currentStep = stepTo;
    },
    nextStep: (state) => {
      switch (state.currentStep) {
        case BulkStep.UPLOAD_LOANS:
          state.currentStep = BulkStep.REVIEW;
          break;
        case BulkStep.REVIEW:
          state.currentStep = BulkStep.FINAL;
          break;
        case BulkStep.UPLOAD_TRADES:
          state.currentStep = BulkStep.MANAGE_AOT;
          break;
        case BulkStep.MANAGE_AOT:
          state.currentStep = BulkStep.REVIEW;
          break;
      }
    },
    previousStep: (state) => {
      switch (state.currentStep) {
        case BulkStep.REVIEW:
          state.currentStep = BulkStep.UPLOAD_LOANS;
          break;
        case BulkStep.UPLOAD_TRADES:
          state.currentStep = BulkStep.REVIEW;
          break;
        case BulkStep.MANAGE_AOT:
          state.currentStep = BulkStep.UPLOAD_TRADES;
          break;
        case BulkStep.FINAL:
          state.currentStep = BulkStep.REVIEW;
          break;
      }
    },
    setLoansSpreadsheetImportError: (state, action: SetLoansSpreadsheetErrorPayload) => {
      state.spreadsheetLoansImportError = action.payload.error;
    },
    setTradesSpreadsheetImportError: (state, action: SetTradesSpreadsheetErrorPayload) => {
      state.spreadsheetTradesImportError = action.payload.error;
    },
    removeLoansSpreadsheetImportError: (state) => {
      state.spreadsheetLoansImportError = '';
    },
    removeTradesSpreadsheetImportError: (state) => {
      state.spreadsheetTradesImportError = '';
    },
    updateBulkLoan: (state, action: UpdateBulkLoanPayload) => {
      const { loan, newLoan } = action.payload;
      const loanToUpdateIdx = state.importedSourceLoans.findIndex((x) => x.loanId === loan.loanId);
      const loanToUpdate = state.importedSourceLoans[loanToUpdateIdx];

      if (!loanToUpdate) {
        return void console.error(`Could not find loan to update matching generated loan ID ${loan.loanId}.`);
      }

      if (getBulkLoanKey(loanToUpdate) !== getBulkLoanKey(loan)) {
        return void console.error('Loan to update does not match the loan in the store.');
      }

      state.importedSourceLoans[loanToUpdateIdx] = { ...newLoan };
    },
    updateBulkTrade: (state, action: UpdateBulkTradePayload) => {
      const { trade, newTrade } = action.payload;
      const tradeToUpdateIdx = state.importedSourceTrades.findIndex((x) => x._id === trade._id);
      const tradeToUpdate = state.importedSourceTrades[tradeToUpdateIdx];

      if (!tradeToUpdate) {
        return void console.error(`Could not find trade to update matching generated trade ID ${trade._id}.`);
      }

      if (getBulkTradeKey(tradeToUpdate) !== getBulkTradeKey(trade)) {
        return void console.error('Trade to update does not match the trade in the store.');
      }

      state.importedSourceTrades[tradeToUpdateIdx] = { ...newTrade };
    },
    startPollingContract: (state, action: StartPollingContractPayload) => {
      if (!action.payload.isSingle) {
        const contractIdx = state.importedContractData.findIndex((contract) => contract.id === action.payload.id);

        state.importedContractData[contractIdx].isPolling = true;
      }
    },
    setContractCompleted: (state, action: PayloadAction<IBulkContract>) => {
      const { internalId, status, statusMessage } = action.payload;
      const contractIdx = state.importedContractData.findIndex((contract) => contract.id === internalId);

      if (contractIdx === -1) {
        // This should never happen, log it just in case
        return void console.error('Contract not found in state');
      }

      if (status === IBulkContractAPIStatus.Committed) {
        state.importedContractData[contractIdx].status = ContractStatus.SUCCESS;
      } else {
        state.importedContractData[contractIdx].status = ContractStatus.FAILURE;
        state.importedContractData[contractIdx].apiCommitErrorMessage = statusMessage;
      }

      state.importedContractData[contractIdx].isPolling = false;
      state.importedContractData[contractIdx].apiStatus = status;
    },
    swapContractIds: (state, action: PayloadAction<Array<[oldId: string, newId: string]>>) => {
      const ids = action.payload;

      state.importedContractData = state.importedContractData.map((contract) => {
        const newId = ids.find(([oldId]) => oldId === contract.id)?.[1];

        if (newId) {
          return { ...contract, id: newId };
        }

        return contract;
      });
    },
    createAOTGroups: (state) => {
      const loans = state.importedContractData.flatMap((contract) => contract.loans);

      state.importedAotGroupData = getAOTGroups(loans, state.importedSourceTrades);
    },
    setAOTGroupSelected: (state, action: PayloadAction<{ isSelected: boolean; group: IBulkAOTGroup }>) => {
      const { isSelected, group } = action.payload;

      const aotGroupIdx = state.importedAotGroupData.findIndex((aotGroup) => aotGroup.coupon === group.coupon);

      // In theory this shouldn't happen but just in case
      if (aotGroupIdx === -1) {
        return void console.error(`Could not find group to update matching generated group ID ${group.coupon}.`);
      }

      state.importedAotGroupData[aotGroupIdx].loans.forEach((loan) => {
        loan.isSelected = isSelected;
      });
    },
    setAOTLoanSelected: (state, action: PayloadAction<{ isSelected: boolean; loan: IAOTGroupLoan }>) => {
      const { isSelected, loan } = action.payload;

      const aotGroupIdx = state.importedAotGroupData.findIndex(
        (aotGroup) => aotGroup.coupon === loan._securitySlot.name
      );

      // In theory this shouldn't happen but just in case
      if (aotGroupIdx === -1) {
        return void console.error(`Could not find loan to update matching generated loan ID ${loan.loanNumber}.`);
      }

      const loanToUpdateIdx = state.importedAotGroupData[aotGroupIdx].loans.findIndex(
        (aotLoan) => aotLoan.loanNumber === loan.loanNumber
      );

      // In theory this shouldn't happen but just in case
      if (loanToUpdateIdx === -1) {
        return void console.error(`Could not find loan to update matching generated loan ID ${loan.loanNumber}.`);
      }

      state.importedAotGroupData[aotGroupIdx].loans[loanToUpdateIdx] = { ...loan, isSelected };
    },
    saveAOTGroupsToContractData: (state) => {
      state.importedContractData = _createBulkGroupsWithAOT(state.importedAotGroupData, state.importedContractData);
    },
    setAOTTradeAmount: (state, action: SetAOTTradeAmountPayload) => {
      const { existingTrade, aotAmount } = action.payload;
      const couponGroupToUpdateIdx = state.importedAotGroupData.findIndex(
        (couponGroup) => couponGroup.coupon === existingTrade._coupon
      );

      if (couponGroupToUpdateIdx === -1) {
        return void console.error(
          `Could not find parent coupon group to update matching coupon ${existingTrade._coupon}.`
        );
      }

      const tradeToUpdateIdx = state.importedAotGroupData[couponGroupToUpdateIdx].trades.findIndex(
        (x) => x.clientName === existingTrade.clientName && x.dealer === existingTrade.dealer
      );

      if (tradeToUpdateIdx === -1) {
        return void console.error(
          `Found parent coupon group ${existingTrade._coupon}, but could not find child trade to update matching client name ${existingTrade.clientName} and dealer ${existingTrade.dealer}.`
        );
      }

      state.importedAotGroupData[couponGroupToUpdateIdx].trades[tradeToUpdateIdx].aotAmount = aotAmount;
    },
    setAOTGroups: (state, action: PayloadAction<IBulkAOTGroup[]>) => {
      state.importedAotGroupData = action.payload;
    },
    setManageAotLoanNumberFilter: (state, action: PayloadAction<string>) => {
      state.manageAotLoanNumberFilter = action.payload;
    }
  }
});

export const {
  updateBulkLoan,
  updateBulkTrade,
  initBulkImportLoans,
  initBulkImportTrades,
  addBulkLoans,
  addBulkTrades,
  updateBulkLoansErrors,
  updateBulkTradesErrors,
  resetState,
  resetUploadTradesState,
  deleteBulkLoans,
  deleteBulkTrades,
  initBulkGroups,
  triggerInitBulkGroups,
  removeContractRows,
  updateStep,
  nextStep,
  previousStep,
  removeContractRowsByStatus,
  removeLoansByContractStatus,
  removeLoanErrors,
  removeTradeErrors,
  setLoansSpreadsheetImportError,
  setTradesSpreadsheetImportError,
  removeLoansSpreadsheetImportError,
  removeTradesSpreadsheetImportError,
  setContractCompleted,
  startPollingContract,
  swapContractIds,
  createAOTGroups,
  setAOTLoanSelected,
  setAOTGroupSelected,
  saveAOTGroupsToContractData,
  setAOTTradeAmount,
  setAOTGroups,
  setManageAotLoanNumberFilter
} = bulkSlice.actions;

export default bulkSlice.reducer;
