import { createSlice } from '@reduxjs/toolkit';
import { IBid } from '../../../shared/models/Bid';
import { getBidKey } from '../bid/bid.utils';
import { TInitServicingRateAction, TReconcileServicingRateAction, TUpdateServicingRateAction } from './types';

export type TServicingRateByBid = { [bidKey: string]: IBid['pricingOption'] };
export type ServicingRateState = TServicingRateByBid;

const initialState = {} as ServicingRateState;

const servicingRateSlice = createSlice({
  name: 'servicingRate',
  initialState,
  reducers: {
    // TODO TEST CASE: Restored servicers exist in bids fetched for the first time
    initServicingRate: (state, action: TInitServicingRateAction) => {
      const bids = action.payload;

      Object.keys(state).forEach((bidKey) => {
        const bid = bids[bidKey];

        if (!bid) {
          delete state[bidKey];
        }
      });
    },
    // TODO TEST CASE: Bids are fetched for the first time
    // TODO TEST CASE: Bids are re-fetch but servicing was locally updated, it should not be overwritten
    // TODO TEST CASE: A bid is added to a mortgage
    reconcileServicingRate: (state, action: TReconcileServicingRateAction) => {
      let bidKey;
      const bids = action.payload;

      bids.forEach((bid) => {
        bidKey = getBidKey(bid);
        if (!state[bidKey]) {
          state[bidKey] = bid.pricingOption;
        }
      });
    },
    updateServingRate: (state, action: TUpdateServicingRateAction) => {
      // TODO TEST CASE: Update local servicing rate
      const { bidKey, servicingRate } = action.payload;

      state[bidKey] = servicingRate;
    }
  }
});

export const { initServicingRate, reconcileServicingRate, updateServingRate } = servicingRateSlice.actions;

export const SERVICING_RATE_PERSIST_NAME = 'SERVICING_RATE';
export const SERVICING_RATE_ACTIONS_TO_TRIGGER_PERSIST = [initServicingRate, reconcileServicingRate, updateServingRate];
export default servicingRateSlice.reducer;
