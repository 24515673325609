import { AxiosResponse } from 'axios';

import { injectable } from 'inversify';

import 'reflect-metadata';

import { client } from 'shared/api/clients/client';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';
import { IBidRepository } from 'shared/api/interfaces/IBidRepository';
import { IBidCreate, IBid, IBidAccept } from 'shared/models/Bid';

@injectable()
export class BidRepository implements IBidRepository {
  private baseRepository = new BaseRepository();

  apiUrl = async (): Promise<string> => {
    return this.baseRepository.getApiUrl((await this.baseRepository.getProductString()) + '/v2/bid');
  };

  add = async (bid: IBidCreate): Promise<AxiosResponse<IBid>> => {
    return await client.post(await this.apiUrl(), bid);
  };

  getAll = async (): Promise<AxiosResponse<IBid[]>> => {
    return await client.get(await this.apiUrl());
  };

  fetchByMortgageId = async (internalId: string): Promise<AxiosResponse<IBid[]>> => {
    return await client.get(`${await this.apiUrl()}/${internalId}`);
  };

  acceptBid = async (bid: IBidAccept): Promise<AxiosResponse> => {
    return await client.post((await this.apiUrl()) + '/accept-bid', bid);
  };

  revertBid = async (id: string): Promise<AxiosResponse> => {
    return await client.post(`${await this.apiUrl()}/accepted/${id}/revert`);
  };

  confirmBid = async (id: string): Promise<AxiosResponse> => {
    return await client.post(`${await this.apiUrl()}/accepted/${id}/confirm`);
  };

  proposeRevision = async (id: string, bidAmount: string): Promise<AxiosResponse> => {
    return await client.post(`${await this.apiUrl()}/accepted/${id}/propose-revision`, { bidAmount });
  };

  acceptRevision = async (id: string): Promise<AxiosResponse> => {
    return await client.post(`${await this.apiUrl()}/accepted/${id}/accept-revision`);
  };

  rejectRevision = async (id: string): Promise<AxiosResponse> => {
    return await client.post(`${await this.apiUrl()}/accepted/${id}/reject-revision`);
  };

  cancelBid = async (id: string): Promise<AxiosResponse> => {
    return await client.delete(`${await this.apiUrl()}/${id}`);
  };

  update(): Promise<AxiosResponse<any, any>> {
    throw new Error('Method not implemented.');
  }

  delete(): Promise<AxiosResponse<any, any>> {
    throw new Error('Method not implemented.');
  }
}
