import { AxiosResponse } from 'axios';

import { injectable } from 'inversify';

import 'reflect-metadata';

import { client } from 'shared/api/clients/client';
import { IPricingOptionRepository } from 'shared/api/interfaces/IPricingOptionRepository';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';

import { PricingOption } from '../../../models/PricingOption';

@injectable()
export class PricingOptionRepository implements IPricingOptionRepository {
  private baseRepository = new BaseRepository();

  apiUrl = async (): Promise<string> => {
    return this.baseRepository.getApiUrl((await this.baseRepository.getProductString()) + '/v2/bid');
  };

  add(): Promise<AxiosResponse<any, any>> {
    throw new Error('Method not implemented.');
  }

  update(): Promise<AxiosResponse<any, any>> {
    throw new Error('Method not implemented.');
  }

  delete(): Promise<AxiosResponse<any, any>> {
    throw new Error('Method not implemented.');
  }

  getAll(): Promise<AxiosResponse<any, any>> {
    throw new Error('Method not implemented.');
  }

  getServicerInfo = async (): Promise<AxiosResponse<PricingOption[]>> => {
    return await client.get(`${await this.apiUrl()}/servicing-options`);
  };
}
