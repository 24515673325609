import { IBestPrice, IBestPriceBid, IBestPricePayup } from './types';
import { lowLoanBalanceStrings } from '../../../../shared/components/BidFlow/OriginatorBidRows/columns/PayupIconCellRenderer';

export const _sortPayupsByName = (a: IBestPricePayup, b: IBestPricePayup) => {
  // Sort low loan balance payups to the top
  if (lowLoanBalanceStrings.has(a.name)) {
    return -1;
  }

  if (lowLoanBalanceStrings.has(b.name)) {
    return 1;
  }

  // Continue sorting by name
  if (a.name < b.name) {
    return -1;
  }

  if (a.name > b.name) {
    return 1;
  }

  return 0;
};

export const _sortByBestPriceDesc = (a: IBestPrice, b: IBestPrice) => Number(b.bestPrice) - Number(a.bestPrice);

export const _sortByInvestorId = (a: IBestPrice, b: IBestPrice) => a.investorId.localeCompare(b.investorId);

export const _isBid = (bidOrPayup: IBestPriceBid | IBestPricePayup): bidOrPayup is IBestPriceBid =>
  'bidHistory' in bidOrPayup;
