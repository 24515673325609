import { RootState } from 'state-management/store';
import { createSelector } from '@reduxjs/toolkit';

export const _NP_DOMAIN = '@novaprime.com';

export const selectUsers = (state: RootState) => state.user.users;
export const selectCurrentUser = (state: RootState) => state.user.currentUser;
export const selectIsCurrentUserNP = createSelector(selectCurrentUser, (currentUser) => {
  if (!currentUser) return false;

  return currentUser.email.endsWith(_NP_DOMAIN);
});

// We want to hide NP users, when the current user is not an NP user
export const selectNonNPUsers = createSelector(selectUsers, selectIsCurrentUserNP, (users, isCurrentUserNP) => {
  // If the current user is not an NP user, we want to hide NP users
  if (!isCurrentUserNP) {
    return users.filter((user) => !user.email.endsWith(_NP_DOMAIN));
  }

  return users;
});
