import { FC, SVGProps } from 'react';
import {
  GreenChoice,
  FicoLess700,
  HomePossible,
  Investment,
  LowLoanBalence,
  StateTX,
  StateNY,
  StateFL,
  FannieMae,
  FreddieMac,
  MrCooper,
  SecondHome,
  JpMorgan,
  SuperConforming,
  NYSuperConforming,
  LennarHomes,
  HomeReady,
  PennyMac,
  MissionScore2,
  MissionScore3,
  AOT
} from 'shared/images';

export enum PayupNames {
  FREDDIE_MAC = 'Freddie Mac',
  FANNIE_MAE = 'Fannie Mae',
  PENNY_MAC = 'Penny Mac',
  LOW_LOAN = 'Low Loan',
  AOT = 'AOT'
}

export const PayupNamesByCompanyName = Object.freeze(
  Object.entries(PayupNames).reduce<{ [companyName: string]: string }>((acc, [iconKey, name]) => {
    acc[name] = iconKey;
    return acc;
  }, {})
);

type IconMapperType = {
  [key: string]: {
    Icon: FC<SVGProps<SVGSVGElement>>;
    hasCircularShape: boolean;
  };
};

const FREDDIE_MAC_ICON_MAPPER = {
  Green: { hasCircularShape: false, Icon: GreenChoice },
  'FICO < 700': { hasCircularShape: false, Icon: FicoLess700 },
  'Home Possible': { hasCircularShape: false, Icon: HomePossible },
  Investment: { hasCircularShape: false, Icon: Investment },
  'State TX': { hasCircularShape: false, Icon: StateTX },
  'State NY': { hasCircularShape: false, Icon: StateNY },
  'State FL': { hasCircularShape: false, Icon: StateFL },
  'Mr Cooper': { hasCircularShape: true, Icon: MrCooper },
  'Second Home': { hasCircularShape: false, Icon: SecondHome },
  'Jp Morgan': { hasCircularShape: true, Icon: JpMorgan },
  'Super Conforming': { hasCircularShape: false, Icon: SuperConforming },
  'State NY SuperConforming': { hasCircularShape: false, Icon: NYSuperConforming },
  boole: { hasCircularShape: true, Icon: JpMorgan },
  'Lennar Homes': { hasCircularShape: true, Icon: LennarHomes }
};

const FANNIE_MAE_ICON_MAPPER = {
  Homeready: { hasCircularShape: false, Icon: HomeReady },
  'New York': { hasCircularShape: false, Icon: StateNY },
  'Second Home': { hasCircularShape: false, Icon: SecondHome },
  'High Balance': { hasCircularShape: false, Icon: SuperConforming },
  'Investment Property': { hasCircularShape: false, Icon: Investment },
  'Credit Score less than 700': { hasCircularShape: false, Icon: FicoLess700 },
  'Mission Score 2': { hasCircularShape: false, Icon: MissionScore2 },
  'Mission Score 3': { hasCircularShape: false, Icon: MissionScore3 }
};

export const IconMapper: IconMapperType = {
  // Investor Icons
  [PayupNames.FREDDIE_MAC]: { hasCircularShape: true, Icon: FreddieMac },
  [PayupNames.FANNIE_MAE]: { hasCircularShape: true, Icon: FannieMae },
  [PayupNames.PENNY_MAC]: { hasCircularShape: true, Icon: PennyMac },
  // AOT Icon
  [PayupNames.AOT]: { hasCircularShape: true, Icon: AOT },
  // Freddie Mac Icons
  ...FREDDIE_MAC_ICON_MAPPER,
  // Fannie Mae Icons
  ...FANNIE_MAE_ICON_MAPPER,
  [PayupNames.LOW_LOAN]: { hasCircularShape: false, Icon: LowLoanBalence }
};
