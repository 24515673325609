import { generatePath, useParams } from 'react-router-dom';
import { RouteUtils } from '../RouteUtils';

const useGetAllLoansPath = () => {
  const { mortgageId = '' } = useParams();

  return {
    pathWithNoMortgage: generatePath(RouteUtils.homepageUrl, { mortgageId: '' }),
    pathWithMortgage: generatePath(RouteUtils.homepageUrl, { mortgageId })
  };
};

export default useGetAllLoansPath;
