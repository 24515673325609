import { createSlice } from '@reduxjs/toolkit';
import { PricingOptionThunks } from '../../thunks/pricingOption.thunks';
import { IPricingOptionRepository } from 'shared/api/interfaces/IPricingOptionRepository';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { container } from 'shared/api/inversify.config';
import { PricingOption } from '../../../shared/models/PricingOption';

export type PricingOptionState = Record<PricingOption['investorHint'], PricingOption>;

const initialState: PricingOptionState = {};

const pricingOptionRepository = container.get<IPricingOptionRepository>(SERVICE_KEYS.PRICING_OPTION_REPOSITORY);
const pricingOptionThunks = new PricingOptionThunks(pricingOptionRepository);

export const getServicerInfo = pricingOptionThunks.getServicerInfo();

const pricingOptionSlice = createSlice({
  name: 'pricingOption',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getServicerInfo.fulfilled, (state, { payload }) => {
      payload.data.forEach((pricingOption) => {
        state[pricingOption.investorHint] = pricingOption;
      });
    });
  }
});

export default pricingOptionSlice.reducer;
