import { useMemo } from 'react';
import { Config } from '../../../state-management/slices/config/config.slice';
import { useGlobalConfig } from 'shared/hooks/useGlobalConfig';

export interface FirmNameProps {
  firm: string;
}

type PartialConfig = Pick<Config, 'companies'>;

export const getFirmName = (firm: string, config: PartialConfig | undefined) => config?.companies?.[firm]?.name || firm;

export const FirmName = ({ firm }: FirmNameProps) => {
  const config = useGlobalConfig();
  const firmName = useMemo(() => getFirmName(firm, config), [config, firm]);

  return <span>{firmName}</span>;
};
