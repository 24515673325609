import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatusesSlice } from './types';

const initialState: StatusesSlice = {
  lqa: {}
};

const statusesSlice = createSlice({
  name: 'statuses',
  initialState,
  reducers: {
    appendToLQAStatuses: (state, action: PayloadAction<StatusesSlice['lqa']>) => {
      state.lqa = { ...state.lqa, ...action.payload };
    }
  }
});

export const { appendToLQAStatuses } = statusesSlice.actions;
export default statusesSlice.reducer;
