import { FetchQueryOptions, QueryClient } from '@tanstack/react-query';

// Increase stale time for local development
const STALE_TIME_LONG = 60 * 1000;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 0
    }
  }
});

const fetchQueryThunk = (options: FetchQueryOptions) =>
  queryClient.fetchQuery<any>({
    retry: false,
    retryDelay: 1000, // retry every second, otherwise retry is exponential
    ...options
  });

export { fetchQueryThunk };
export { STALE_TIME_LONG };
export default queryClient;
