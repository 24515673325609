import LoadingSpinner from 'shared/NovaPrimeUI/Components/LoadingSpinner/LoadingSpinner';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

export function Login(): JSX.Element {
  const auth = useAuth0();
  const { loginWithRedirect, isAuthenticated, isLoading } = auth;

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({
        authorizationParams: {
          redirect_uri: window.location.origin
        }
      });
    }
  }, [isAuthenticated, isLoading]);

  return <LoadingSpinner />;
}

export default Login;
