import { createSlice } from '@reduxjs/toolkit';

import { AuthThunks } from '../../thunks/auth.thunks';
import { IAuthRepository } from 'shared/api/interfaces/IAuthRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';

export type AuthSlice = {
  resetPasswordMessage: string;
};

export const initialState: AuthSlice = {
  resetPasswordMessage: 'Please change your password'
};

export interface ChangePasswordData {
  client_id: string | undefined;
  email: string | undefined;
  connection: string;
}

const authRepository = container.get<IAuthRepository>(SERVICE_KEYS.AUTH_REPOSITORY);
const authThunks = new AuthThunks(authRepository);

export const createChangePasswordEmail = authThunks.changePassword();

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    changePassword(state, action) {
      state.resetPasswordMessage = action.payload.message;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createChangePasswordEmail.fulfilled, (state, { payload }) => {
      state.resetPasswordMessage = payload.data;
    });
  }
});

export const { changePassword } = authenticationSlice.actions;
export const authenticationReducer = authenticationSlice.reducer;
