import { Navigate, Outlet, useParams } from 'react-router-dom';
import { MortgageStatus } from 'shared/models/Mortgage';
import useGetAllLoansPath from '../Quality/hooks/useGetAllLoansPath';

const ALLOWED_STATUSES = [
  MortgageStatus.Draft,
  MortgageStatus.Open,
  MortgageStatus.Pending,
  MortgageStatus.Sold,
  MortgageStatus.Deactivated
];

function MortgageStatusValidator() {
  const { pathWithMortgage } = useGetAllLoansPath();
  const { status } = useParams<{ status: MortgageStatus }>();

  if (status && ALLOWED_STATUSES.includes(status)) {
    return <Outlet />;
  }

  return <Navigate to={pathWithMortgage} replace />;
}

export default MortgageStatusValidator;
