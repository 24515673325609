import { AxiosResponse } from 'axios';

import { injectable } from 'inversify';

import 'reflect-metadata';

import { client } from 'shared/api/clients/client';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';
import { IInvestorRepository } from 'shared/api/interfaces/IInvestorRepository';
import { IInvestor } from 'shared/models/Investor';

@injectable()
export class InvestorRepository implements IInvestorRepository {
  private baseRepository = new BaseRepository();

  apiUrl: string = this.baseRepository.getApiUrl('originator/v2/counterparty');

  getAll = async (): Promise<AxiosResponse<IInvestor[]>> => {
    const response = await client.get(this.apiUrl);

    return response;
  };

  add(): Promise<AxiosResponse<any, any>> {
    throw new Error('Method not implemented.');
  }

  update(): Promise<AxiosResponse<any, any>> {
    throw new Error('Method not implemented.');
  }

  delete(): Promise<AxiosResponse<any, any>> {
    throw new Error('Method not implemented.');
  }
}
