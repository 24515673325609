import { useEffect, useMemo } from 'react';
import { NavLink, Route, Routes, useParams, useRoutes } from 'react-router-dom';
import { useAppSelector } from '../../../../state-management/hooks';
import { Draft, Folders } from '../../../images';
import { IAllLoansTableData } from '../../../../Sections/Originator/Pages/Quality/types';
import routeStrings from '../../../../Routing/routeStrings';
import { RouteUtils } from '../../../../Sections/Originator/Pages/Quality/RouteUtils';
import { ChecklistReviewFilter } from 'Sections/Originator/Pages/Quality/CloseChecklist/components/ChecklistTablePanel/ChecklistTableHeader';
import { selectAllStatusMortgages } from '../../../../state-management/slices/quality/selectors';
import { QualityBadgeNumber } from '../../../../Sections/Originator/Pages/Quality/components/QualityBadgeCell';
import useGetAllLoansPath from '../../../../Sections/Originator/Pages/Quality/hooks/useGetAllLoansPath';
import RuleManagerButton from '../../../components/RuleManager/RuleManagerButton';
import useFetchOneMortgage from 'state-management/slices/mortgage/hooks/useFetchOneMortage';
import useGetAnalysisDocumentStatusByMortgagesId from 'state-management/slices/analysis-documents/hooks/useGetAnalysisDocumentStatusByMortgagesId';

const { quality } = routeStrings.originator;

const SidebarNavigationQuality = () => {
  const allLoans = useAppSelector(selectAllStatusMortgages);

  const routesWithMortgage = useMemo(
    () =>
      [RouteUtils.homepageUrl, RouteUtils.closeChecklistUrl, `${RouteUtils.documentCategorizationReviewPureUrl}/*`].map(
        (path) => ({ path, element: <AllLoansLink withMortgage /> })
      ),
    []
  );

  const allLinkWithMortgage = useRoutes([
    { path: '*', element: <AllLoansLink withMortgage={false} /> },
    ...routesWithMortgage
  ]);

  return (
    <div className="panel-items">
      {/* All loans link */}
      {allLinkWithMortgage}

      {/* Mortgage data */}
      <Routes>
        <Route path="*" element={null} />
        <Route path={RouteUtils.homepageUrl} element={<QualityMortgagePanel allLoans={allLoans} />} />
        <Route path={`${RouteUtils.closeChecklistUrl}/*`} element={<QualityMortgagePanel allLoans={allLoans} />} />
        <Route
          path={`${RouteUtils.documentCategorizationReviewPureUrl}/*`}
          element={<QualityMortgagePanel allLoans={allLoans} />}
        />
        <Route path={`${RouteUtils.loanQualityAdvisorUrl}/*`} element={<QualityMortgagePanel allLoans={allLoans} />} />
      </Routes>

      <RuleManagerButton className="panel-item" />
    </div>
  );
};

const AllLoansLink = ({ withMortgage = false }: { withMortgage?: boolean }) => {
  const { pathWithMortgage, pathWithNoMortgage } = useGetAllLoansPath();
  const path = withMortgage ? pathWithMortgage : pathWithNoMortgage;

  return (
    <NavLink end to={path} className="panel-item">
      <div className="panel-item-icon">
        <Folders />
      </div>
      All Loans
    </NavLink>
  );
};

const QualityMortgagePanel = ({ allLoans }: { allLoans: IAllLoansTableData[] }) => {
  const { mortgageId: selectedMortgageId = '' } = useParams();

  useFetchOneMortgage(selectedMortgageId);
  const { fetch: fetchAnalysisStatus } = useGetAnalysisDocumentStatusByMortgagesId();

  const selectedMortgage = useMemo(() => {
    if (!selectedMortgageId) {
      return;
    }

    return allLoans.find((loan) => loan.internalId === selectedMortgageId);
  }, [allLoans, selectedMortgageId]);

  // fetch analysis-checklist for single mortgage
  useEffect(() => {
    if (!selectedMortgageId) {
      return;
    }

    fetchAnalysisStatus([selectedMortgageId]);
  }, [fetchAnalysisStatus, selectedMortgageId]);

  if (!selectedMortgage) {
    return null;
  }

  const { loanNumber, internalId, status, closeChecklist } = selectedMortgage;

  return (
    <>
      <div className="panel-item selected-loan not-actionable">
        <div className="panel-item-icon">
          <Draft />
        </div>
        {loanNumber}{' '}
      </div>

      {/* TODO: [LQA] Restore when LQA is ready */}
      {/* <LQASidebarEntry mortgageId={internalId} /> */}

      <NavLink
        to={RouteUtils.closeChecklistWithParams(internalId, ChecklistReviewFilter.Review)}
        className="panel-item nested"
      >
        Checklist <QualityBadgeNumber value={closeChecklist} />
      </NavLink>

      <NavLink
        className="panel-item nested"
        to={`/${quality.base}/${status}/${quality.documentCategorization}/${internalId}`}
      >
        Doc Categorization
      </NavLink>
    </>
  );
};

export default SidebarNavigationQuality;
