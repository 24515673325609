import { IMortgage } from 'shared/models/Mortgage';
import { LQAValidationResultKey } from 'shared/models/Validations';

export enum NormalizedLQAValidationStatus {
  ERROR = 'ERROR',
  COMPLETE = 'COMPLETE',
  PROCESSING = 'PROCESSING'
}

export enum LQAValidationResultStatus {
  CRITICAL = 'CRITICAL',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS'
}

interface LQAValidationResult {
  name: string;
  message: string;
  status: LQAValidationResultStatus;
}

export interface LPADataCompareValidationResult extends LQAValidationResult {
  'LPA Value': string;
  'LQA Value': string;
  nested: boolean;
}

export type NormalizedLQAValidationInfo =
  | {
      type:
        | LQAValidationResultKey.FREDDIE_LQA_ASSET_SERVICE
        | LQAValidationResultKey.FREDDIE_LQA_GPC_SERVICE
        | LQAValidationResultKey.FREDDIE_LQA_INCOME_SERVICE
        | LQAValidationResultKey.FREDDIE_LQA_PURCHASE_ELIGIBILITY;
      name: string;
      results: LQAValidationResult[];
    }
  | {
      type: LQAValidationResultKey.FREDDIE_LQA_LP_DATA_COMPARE;
      name: string;
      results: LPADataCompareValidationResult[];
    };

export interface NormalizedLQAValidation {
  mortgageId: IMortgage['internalId'];
  validationId: string;
  errorsCount: number;
  completedCount: number;
  isCritical: boolean;
  status: NormalizedLQAValidationStatus;
  validations: NormalizedLQAValidationInfo[];
}
