import { useMutation } from '@tanstack/react-query';
import { client } from 'shared/api/clients/client';
import { DocumentQualityBaseRepository } from 'shared/api/repositories/DocumentQualityBaseRepository';
import { setDocumentStatus } from '../slice';
import { addToastError } from '../../../slices/toast/toast.slice';
import { useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';
import { IMortgage } from '../../../../shared/models/Mortgage';
import { IDocumentStatus } from '../../../../shared/models/Analysis-Document';

const _repository = new DocumentQualityBaseRepository();

const useGetAnalysisDocumentStatusByMortgagesId = () => {
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: (ids: IMortgage['internalId'][]): Promise<AxiosResponse<IDocumentStatus[]>> => {
      return client.post(_repository.getApiUrl('/analysis-status'), ids);
    },
    onSuccess: (response) => {
      dispatch(setDocumentStatus({ data: response.data }));
    },
    onError: () => {
      dispatch(addToastError('There was an error loading document statuses. Please try again'));
    }
  });

  return { fetch: mutation.mutate, isPending: mutation.isPending };
};

export default useGetAnalysisDocumentStatusByMortgagesId;
