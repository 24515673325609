const mortgages = {
  mortgages: {
    winningBidAmount: 'Winning Bid Amount',
    soldDate: 'Closed Date',
    internalId: 'Internal Mortgage ID',
    loanNumber: 'Loan Number',
    mersMIN: 'MersMIN',
    type: 'Type',
    loanProgram: 'Loan Program',
    status: 'Status',
    bids: 'Bids',
    ficoScore: 'Fico Score',
    ltv: 'LTV',
    cltv: 'CLTV',
    noteAmount: 'Note Amount',
    unpaidPrincipleBalance: 'Unpaid Principle Balance',
    totalAmount: 'Total Amount',
    location: 'Location',
    zip: 'Zip',
    currentInterestRate: 'Current Interest Rate',
    currentTerm: 'Current Term',
    originalTerm: 'Original Term',
    automatedUnderwritingSystemType: 'Automated Underwriting System Type',
    loanDocument: 'Loan Document',
    escrowWaived: 'Escrow Waived',
    address: 'Address',
    city: 'City',
    state: 'State',
    purpose: 'Purpose',
    propertyType: 'Property Type',
    occupancy: 'Occupancy',
    livingUnits: 'Living Units',
    appraisedValue: 'Appraised Value',
    salesPrice: 'Sales Price',
    borrower: 'Borrower',
    borrowerIncome: 'Borrower Income',
    debtToIncome: 'Debt to Income',
    plannedClosingDate: 'Planned Closing Date',
    openDate: 'Open Date',
    numInvestors: 'Share Count',
    soldTo: 'Sold To Firm',
    bidSubmitter: 'Sold To Name',
    bidApprover: 'Approved By',
    purchasedFrom: 'Purchased From Firm'
  }
} as const;

export default mortgages;
