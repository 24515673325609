const admin = {
  admin: {
    userPermission: {
      title: 'User Permissions',
      body: `Access granted to particular products provides users with predefined
      permissions to features. In other words, users can have access to what they
      need depending on their job function.`,
      addUser: 'Add User',
      addNewUser: 'Add a New User',
      bannerTitle: 'Add Teammates to Your Workspace',
      bannerBody: `Your workspace is ready to go. Add coworkers to the team and give them access
      to collaborate`,
      newMember: 'Add a team member'
    },
    confirmation: {
      header: 'Are you sure?',
      body: 'This will delete the user from the workspace.',
      confirm: 'Yes',
      cancel: 'Go back'
    },
    userTemplate: {
      name: 'Name',
      products: 'Products',
      duns: 'Duns',
      longName: 'Long Name',
      isActive: 'Is Active',
      createdBy: 'Created By',
      createdAt: 'Created At',
      modifiedBy: 'Modified By',
      updatedAt: 'Updated At',
      status: 'Status',
      activity: 'Last activity',
      invited: 'Date invited'
    },
    userDrawer: {
      title: 'Account Info:',
      name: 'Full Name',
      role: 'Role',
      email: 'Email Address',
      phone: 'Phone Number',
      edit: 'Edit',
      cancel: 'Cancel',
      save: 'Save',
      about: 'about',
      contact: 'contact',
      productsHeader: 'Products',
      productsDescription: 'Manage this user\'s product capabilities and grant access.',
      productHeader: 'Product',
      productAccess: 'Access'
    },
    accessError: 'You do not have access to this company. Please navigate to the appropriate company\'s three-homes URL.'
  }
} as const;

export default admin;
