import { useQuery } from '@tanstack/react-query';
import { UserRepository } from 'shared/api/repositories/UserRepository/UserRepository';

const _repo = new UserRepository();

const useGetCurrentUserQuery = () => {
  const query = useQuery({
    enabled: false,
    queryKey: ['get-current-user'],
    queryFn: _repo.getCurrentUser,
    select: (response) => response.data
  });

  return {
    ...query,
    getCurrentUser: query.refetch
  };
};

export default useGetCurrentUserQuery;
