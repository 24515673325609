import { SortDirection, SortModelItem } from 'ag-grid-community';
import { IAPIAllLoansTableData, SortField, TAllLoansGetRowsParams } from '.';
import { GovernmentType, IMortgage, MortgageStatus } from 'shared/models/Mortgage';
import { reversedLoanStatusTextMapping } from 'shared/components/SharedQCTableWrapper/utils';

export const normalizeData = (data: IAPIAllLoansTableData) => {
  const normalizeLoans = data.loans.map((item) => {
    return {
      ...item,
      internalId: item.mortgageId
    };
  });

  return { ...data, loans: normalizeLoans };
};

const normalizeSortModel = (sort: SortModelItem[]) => {
  return sort.map((item) => ({
    dir: item.sort as SortDirection,
    field: item.colId as SortField
  }));
};

export const normalizeLoanNumbers = (loanNumbers: string) => {
  if (!loanNumbers) return [];
  // Split by commas and/or spaces and trim each item
  return loanNumbers
    .trim()
    .split(/[\s,]+/)
    .map((item) => item.trim());
};

export enum APIAllLoansLoanTypes {
  USDARuralDevelopment = 'USDARuralDevelopment',
  Other = 'Other'
}

export enum UIAllLoansLoanTypes {
  NonQM = 'Non-QM'
}

export const loanTypeFilterValues = [
  GovernmentType.Conventional,
  GovernmentType.FHA,
  GovernmentType.VA,
  GovernmentType.USDARuralDevelopment,
  UIAllLoansLoanTypes.NonQM
];

type RequestParams = Omit<TAllLoansGetRowsParams, 'sortModel'> & {
  sort?: { dir: SortDirection; field: SortField }[];
  type?: (GovernmentType | APIAllLoansLoanTypes)[];
  status?: MortgageStatus[];
  loanNumbers?: IMortgage['loanNumber'][];
  closeDate?: { start: Date | string; end: Date | string };
};

// todo: unit test this function
export const buildRequestParams = (params: TAllLoansGetRowsParams) => {
  const request: RequestParams = {};

  if (params.sortModel) {
    request.sort = normalizeSortModel(params.sortModel);
    delete params.sortModel;
  }

  if (params.filterModel) {
    if (params.filterModel?.type) {
      const typeFilters = params.filterModel?.type.values.map((loanType) => {
        if (loanType === GovernmentType.USDARuralDevelopment) {
          return APIAllLoansLoanTypes.USDARuralDevelopment;
        }
        if (loanType === UIAllLoansLoanTypes.NonQM) {
          return APIAllLoansLoanTypes.Other;
        }
        return loanType;
      });

      request.type = typeFilters;
    }

    if (params.filterModel?.status) {
      const typeFilters = params.filterModel?.status.values.map((status) => reversedLoanStatusTextMapping[status]);

      request.status = typeFilters;
    }

    if (params.filterModel?.loanNumbers) {
      const loanNumbers = normalizeLoanNumbers(params.filterModel?.loanNumbers);

      request.loanNumbers = loanNumbers;
    }

    if (params.filterModel?.closeDate) {
      request.closeDate = {
        start: params.filterModel?.closeDate.startDate,
        end: params.filterModel?.closeDate.endDate
      };
    }

    delete params.filterModel;
  }

  return { ...request, ...params };
};
