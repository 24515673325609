/*
  Please keep these organized alphabetically
*/

export const SERVICE_KEYS = {
  AUTH_REPOSITORY: Symbol('AUTH_REPOSITORY'),
  BID_REPOSITORY: Symbol('BID_REPOSITORY'),
  BIDTAPE_REPOSITORY: Symbol('BIDTAPE_REPOSITORY'),
  BULK_REPOSITORY: Symbol('BULK_REPOSITORY'),
  DELIVERY_DATE_REPOSITORY: Symbol('DELIVERY_DATE_REPOSITORY'),
  INVESTOR_REPOSITORY: Symbol('INVESTOR_REPOSITORY'),
  MORTGAGE_DOCUMENT_REPOSITORY: Symbol('MORTGAGE_DOCUMENT_REPOSITORY'),
  MORTGAGE_REPOSITORY: Symbol('MORTGAGE_REPOSITORY'),
  PAYUP_REPOSITORY: Symbol('PAYUP_REPOSITORY'),
  PRICING_OPTION_REPOSITORY: Symbol('PRICING_OPTION_REPOSITORY'),
  TRADER_REPOSITORY: Symbol('TRADER_REPOSITORY'),
  USER_REPOSITORY: Symbol('USER_REPOSITORY')
};
