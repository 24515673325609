import { ReactNode, useEffect } from 'react';
import { useAppDispatch } from '../../../state-management/hooks';
import { init } from '../../../state-management/actions';

interface AppContentWrapperProps {
  children: ReactNode;
}

const AppContentWrapper = ({ children }: AppContentWrapperProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(init());
  }, []);

  return <>{children}</>;
};

export default AppContentWrapper;
