import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import 'reflect-metadata';
import { client } from 'shared/api/clients/client';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';
import { IDeliveryDateRepository } from 'shared/api/interfaces/IDeliveryDateRepository';
import { IDeliveryDate } from 'shared/models/DeliveryDate';

@injectable()
export class DeliveryDateRepository implements IDeliveryDateRepository {
  private baseRepository = new BaseRepository();

  apiUrl = async (): Promise<string> => {
    return this.baseRepository.getApiUrl((await this.baseRepository.getProductString()) + '/v1/delivery-date');
  };

  get = async (): Promise<AxiosResponse<IDeliveryDate>> => {
    return await client.get(await this.apiUrl());
  };

  async updateDeliveryDate(deliveryDate: IDeliveryDate): Promise<AxiosResponse<any, any>> {
    return await client.post(await this.apiUrl(), deliveryDate);
  }

  add(): Promise<AxiosResponse<any, any>> {
    throw new Error('Method not implemented.');
  }

  update(): Promise<AxiosResponse<any, any>> {
    throw new Error('Method not implemented.');
  }

  delete(): Promise<AxiosResponse<any, any>> {
    throw new Error('Method not implemented.');
  }

  getAll(): Promise<AxiosResponse<any, any>> {
    throw new Error('Method not implemented.');
  }
}
