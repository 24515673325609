import Big, { BigSource } from 'big.js';

export const updateItemInArray = <T extends { id?: unknown }, Y extends { id?: unknown }>(
  array: T[],
  payload: Y
): T[] => {
  const { id } = payload;

  return array.map((item: T) => (item.id === id ? { ...item, ...payload } : item));
};

export const removeItemInArray = <T extends { id?: unknown }>(array: T[], id: unknown): T[] => {
  return array.filter((item: T) => item.id !== id);
};

export const uniqueBy = <T extends { [key: string]: any }>(arr: T[], prop: string): T[] => {
  return arr.filter((a, i) => arr.findIndex((s) => a[prop] === s[prop]) === i);
};

export const basisPointsToPrice = (basisPoints: BigSource) => new Big(basisPoints).div(100).plus(100);
