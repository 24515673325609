import { IBidTapeParameters } from 'shared/models/BidTape';
import { IMortgageFlat, State } from 'shared/models/Mortgage';
import { filterValuesBidTape } from 'state-management/helpers/slice.helper';

export const filterMortgagesByBidTapeParams = (
  mortgages: IMortgageFlat[],
  filterParams: IBidTapeParameters
): IMortgageFlat[] => {
  const {
    ficoScoreMin,
    ficoScoreMax,
    rateMin,
    rateMax,
    termMin,
    termMax,
    governmentType,
    state,
    propertyType,
    occupancy
    // Apply min/max defaults to FICO score, interest rate, and original term
    // Reuse specific min/max numeric constants from filterValuesBidTape
  } = filterValuesBidTape(filterParams);

  const governmentTypeSet = new Set(governmentType);
  const stateSet = new Set(state);
  const propertyTypeSet = new Set(propertyType);
  const occupancySet = new Set(occupancy);

  return mortgages.filter((mortgage) => {
    // Check if numeric values are within specified ranges
    const currentInterestRateWithinRange =
      Number(mortgage.currentInterestRate) >= rateMin && Number(mortgage.currentInterestRate) <= rateMax;

    const originalTermWithinRange = mortgage.originalTerm >= termMin && mortgage.originalTerm <= termMax;

    const ficoScoreWithinRange = mortgage.ficoScore >= ficoScoreMin && mortgage.ficoScore <= ficoScoreMax;

    // Set filters can be an empty array, this means that all values are accepted
    const hasGovernmentTypeFilter = governmentTypeSet.size > 0;
    const matchesGovernmentType = !hasGovernmentTypeFilter || governmentTypeSet.has(mortgage.type);

    const hasPropertyTypeFilter = propertyTypeSet.size > 0;
    const matchesPropertyType = !hasPropertyTypeFilter || propertyTypeSet.has(mortgage.propertyType);

    const hasOccupancyFilter = occupancySet.size > 0;
    const matchesOccupancy = !hasOccupancyFilter || occupancySet.has(mortgage.occupancy);

    const hasStateFilter = stateSet.size > 0;
    const matchesState = !hasStateFilter || stateSet.has(mortgage.state as State);

    return (
      currentInterestRateWithinRange &&
      originalTermWithinRange &&
      ficoScoreWithinRange &&
      matchesGovernmentType &&
      matchesPropertyType &&
      matchesOccupancy &&
      matchesState
    );
  });
};
