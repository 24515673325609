import { createSlice } from '@reduxjs/toolkit';
import { TraderThunks } from '../../thunks/trader.thunks';
import { ITraderRepository } from 'shared/api/interfaces/ITraderRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { ITraderData } from 'shared/models/Trader';

export type TraderMap<T = ITraderData> = { [key: string]: T };

export type TraderSlice = {
  traders: TraderMap;
};

const initialState: TraderSlice = {
  traders: {}
};

const traderRepository = container.get<ITraderRepository>(SERVICE_KEYS.TRADER_REPOSITORY);
const traderThunks = new TraderThunks(traderRepository);

export const fetchTraders = traderThunks.getAll();

const traderSlice = createSlice({
  name: 'trader',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTraders.fulfilled, (state, { payload }) => {
      const { data: incomingTraders = [] } = payload;

      state.traders = incomingTraders.reduce<TraderMap>(
        (acc, trader) => ({
          ...acc,
          [trader.userId]: trader
        }),
        {}
      );
    });
  }
});

export default traderSlice.reducer;
