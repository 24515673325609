import { Header } from '@3homes/libs';
import { NavLink, useNavigate } from 'react-router-dom';
import { handleLogout } from '../../../utils/logoutHelper';
import { useAppSelector } from '../../../../state-management/hooks';
import { getPageLinks, InvestorRoutes } from '../../../../Routing/investorRoutes';
import { useProductRole } from '../../../hooks/useProductRole';
import { useAuth0 } from '@auth0/auth0-react';
import './index.scss';

const InvestorLayout = () => {
  const auth = useAuth0();
  const navigate = useNavigate();
  const { role } = useProductRole();
  const pageLinks = getPageLinks(role);

  return (
    <>
      <Header
        auth={auth}
        handleLogout={handleLogout}
        useAppSelector={useAppSelector}
        pageLinks={pageLinks}
        NavLink={NavLink}
        navigate={navigate}
      />
      <InvestorRoutes role={role} />;
    </>
  );
};

export default InvestorLayout;
