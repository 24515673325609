const bidTape = {
  bidTape: {
    loanNumber: 'Loan Number',
    type: 'Type',
    status: 'Status',
    openDate: 'Open Date',
    shareCount: 'Share Count',
    bids: 'Bids',
    loanProgram: 'Loan Program',
    ficoScore: 'Fico Score',
    ltv: 'LTV',
    cltv: 'CLTV',
    noteAmount: 'Note Amount',
    unpaidPrincipleBalance: 'Unpaid Principle Balance',
    totalAmount: 'Total Amount',
    location: 'Location',
    zip: 'Zip',
    currentInterestRate: 'Current Interest Rate',
    currentTerm: 'Current Term',
    originalTerm: 'Original Term',
    automatedUnderwritingSystemType: 'Automated Underwriting System Type',
    loanDocument: 'Loan Document',
    escrowWaived: 'Escrow Waived',
    address: 'Address',
    city: 'City',
    state: 'State',
    purpose: 'Purpose',
    propertyType: 'Property Type',
    occupancy: 'Occupancy',
    livingUnits: 'Living Units',
    appraisedValue: 'Appraised Value',
    salesPrice: 'Sales Price',
    borrower: 'Borrower',
    borrowerIncome: 'Borrower Income',
    debtToIncome: 'Debt to Income',
    numInvestors: 'Share Count',
    button: {
      edit: 'Edit Bid Tape',
    }
  }
} as const;

export default bidTape;
