import { container } from '../../../shared/api/inversify.config';
import { SERVICE_KEYS } from '../../../shared/api/keys.const';
import { IBulkRepository } from '../../../shared/api/interfaces/IBulkRepository';
import { BulkThunks } from '../../thunks/bulk.thunks';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { BulkSlice } from './bulk.slice';
import { ContractStatus } from '../../../shared/models/Loan';
import { IBulkContractAPIStatus } from '../../../shared/models/Bulk';
import {
  BULK_COMMIT_API_ERROR_TO_MSG,
  DEFAULT_BULK_COMMIT_ERROR
} from '../../../shared/components/BulkFlow/ContractsDataStep/constants';
import { CommitBulkErrorPayload } from './types';

const bulkRepository = container.get<IBulkRepository>(SERVICE_KEYS.BULK_REPOSITORY);
const bulkThunks = new BulkThunks(bulkRepository);

// V2
export const commitBulkV2 = bulkThunks.commitBulkV2();
export const commitSingleBulkV2 = bulkThunks.commitSingleBulkV2();
export const getBulkV2 = bulkThunks.getBulkV2();
export const getSingleBulkV2 = bulkThunks.getSingleBulkV2();

const bulkApiSlice = (builder: ActionReducerMapBuilder<BulkSlice>) => {
  builder.addCase(commitBulkV2.pending, (state, action) => {
    // Reset api status of committed contract, as a new api call will be made
    const contracts = action.meta.arg;

    contracts.forEach(({ id }) => {
      const groupIdx = state.importedContractData.findIndex((contract) => contract.id === id);

      // Reset api status of failed committed contract
      if (state.importedContractData[groupIdx].apiStatus === IBulkContractAPIStatus.FailedToCommit) {
        state.importedContractData[groupIdx].apiStatus = undefined;
      }
    });
  });
  builder.addCase(commitBulkV2.fulfilled, (state, action) => {
    const contracts = action.payload.data;

    contracts.forEach(({ id, error }) => {
      // TODO: Improve / measure performance
      const groupIdx = state.importedContractData.findIndex((contract) => contract.id === id);

      if (error) {
        state.importedContractData[groupIdx].status = ContractStatus.FAILURE;
        state.importedContractData[groupIdx].apiStatus = IBulkContractAPIStatus.FailedToCommit;
        state.importedContractData[groupIdx].apiCommitErrorMessage =
          BULK_COMMIT_API_ERROR_TO_MSG[error] || DEFAULT_BULK_COMMIT_ERROR;
      } else {
        state.importedContractData[groupIdx].apiStatus = IBulkContractAPIStatus.NotCommitted;
      }
    });
  });
  builder.addCase(commitBulkV2.rejected, (state, action) => {
    const contracts = action.meta.arg;
    const { data } = (action as CommitBulkErrorPayload).payload;

    contracts.forEach(({ id }) => {
      // TODO: Improve / measure performance
      const groupIdx = state.importedContractData.findIndex((contract) => contract.id === id);

      state.importedContractData[groupIdx].status = ContractStatus.FAILURE;
      state.importedContractData[groupIdx].apiStatus = IBulkContractAPIStatus.FailedToCommit;
      state.importedContractData[groupIdx].apiCommitErrorMessage = data?.message || DEFAULT_BULK_COMMIT_ERROR;
    });
  });
};

export default bulkApiSlice;
