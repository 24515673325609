import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import 'reflect-metadata';
import { client } from 'shared/api/clients/client';
import { IMortgageDocumentRepository } from 'shared/api/interfaces/IMortgageDocumentRepository';
import { IMortgageDocumentList } from 'shared/models/MortgageDocument';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';
import { MortgageDocumentMap } from '../../../../state-management/slices/mortgage-document/mortgage-document.slice';

@injectable()
export class MortgageDocumentRepository implements IMortgageDocumentRepository {
  private baseRepository = new BaseRepository();

  apiUrl = async (): Promise<string> => {
    return this.baseRepository.getApiUrl((await this.baseRepository.getProductString()) + '/v1/mortgage-document');
  };

  add(): Promise<AxiosResponse<any, any>> {
    throw new Error('Method not implemented.');
  }

  update(): Promise<AxiosResponse<any, any>> {
    throw new Error('Method not implemented.');
  }

  delete(): Promise<AxiosResponse<any, any>> {
    throw new Error('Method not implemented.');
  }

  getAll(): Promise<AxiosResponse<any, any>> {
    throw new Error('Method not implemented.');
  }

  getOne = async (): Promise<AxiosResponse<any, any>> => {
    throw new Error('Method not implemented.');
  };

  getLists = async (status: string): Promise<AxiosResponse<MortgageDocumentMap>> => {
    return await client.get(`${await this.apiUrl()}/file-lists?status=${status}`);
  };

  getList = async (id: string): Promise<AxiosResponse<IMortgageDocumentList[]>> => {
    return await client.get(`${await this.apiUrl()}/${id}/file-list`);
  };

  getDocumentFile = async (id: string, documentId: string): Promise<AxiosResponse<ArrayBuffer>> => {
    return await client.get(`${await this.apiUrl()}/${id}/documents/${documentId}/file`, {
      responseType: 'arraybuffer'
    });
  };
}
