import { RootState } from '../../store';
import { createSelector } from '@reduxjs/toolkit';
import { IPayup } from '../../../shared/models/Payup';

export const selectPayupMap = (state: RootState) => state.payup.payups;

/**
 * @deprecated Use makeSelectPayupsByMortgageId
 */
export const _selectPayupsByMortgageId = createSelector(
  selectPayupMap,
  (state: RootState, mortgageId: string) => mortgageId,
  (payups, mortgageId): IPayup[] | null => payups[mortgageId] || null
);

export const makeSelectPayupsByMortgageId = () => _selectPayupsByMortgageId;
