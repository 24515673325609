// Redux Toolkit
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
// Thunks and repository
import { UserThunks } from '../../thunks/user.thunks';
import { IUserRepository } from 'shared/api/interfaces/IUserRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { IUser, IUserData } from 'shared/models/User';

export type UserSlice = {
  currentUser: IUser | null;
  users: IUserData[];
  userIdToModify: string;
  isResetPasswordModalOpen: boolean;
  isResetMFAModalOpen: boolean;
  isRemoveUserModalOpen: boolean;
};

// Const
const initialState: UserSlice = {
  currentUser: null,
  users: [],
  userIdToModify: '',
  isResetPasswordModalOpen: false,
  isResetMFAModalOpen: false,
  isRemoveUserModalOpen: false
};

const userRepository = container.get<IUserRepository>(SERVICE_KEYS.USER_REPOSITORY);
const userThunks = new UserThunks(userRepository);

export const fetchUser = userThunks.getOne();
export const fetchUsers = userThunks.getAll();
export const createAuth0User = userThunks.createAuth0User();
export const resetPassword = userThunks.resetPassword();
export const resetMFA = userThunks.resetMFA();
export const removeUser = userThunks.removeUser();
export const updateAuth0User = userThunks.updateAuth0User();

// Slice
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<IUser>) => {
      state.currentUser = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setToggleIsResetPasswordModalOpen: (state, action) => {
      state.userIdToModify = !state.isResetPasswordModalOpen ? action.payload : '';
      state.isResetPasswordModalOpen = !state.isResetPasswordModalOpen;
    },
    setToggleIsResetMFAModalOpen: (state, action) => {
      state.userIdToModify = !state.isResetMFAModalOpen ? action.payload : '';
      state.isResetMFAModalOpen = !state.isResetMFAModalOpen;
    },
    setToggleIsRemoveUserModalOpen: (state, action) => {
      state.userIdToModify = !state.isRemoveUserModalOpen ? action.payload : '';
      state.isRemoveUserModalOpen = !state.isRemoveUserModalOpen;
    }
  },
  extraReducers: (builder) => {
    // TODO: Create a filter for only the users of the fetching user organization
    builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
      const users = payload.data || [];

      state.users = users;
    });

    builder.addCase(createAuth0User.fulfilled, (state, { payload }) => {
      state.users.push(payload.data);
    });

    builder.addCase(removeUser.fulfilled, (state, { payload }) => {
      const userId = (payload.data as any).id;

      state.users = state.users.filter((user) => user.auth0Id !== userId);
      state.userIdToModify = '';
      state.isRemoveUserModalOpen = false;
    });

    builder.addCase(removeUser.rejected, (state) => {
      state.userIdToModify = '';
      state.isRemoveUserModalOpen = false;
    });

    builder.addCase(updateAuth0User.fulfilled, (state, { payload }) => {
      const updatedUser = payload.data;

      state.users = state.users.map((user) => {
        if (user.auth0Id === updatedUser.auth0Id) user = updatedUser;
        return user;
      });
    });
  }
});

// Exported Action Functions
export const {
  setCurrentUser,
  setUsers,
  setToggleIsResetPasswordModalOpen,
  setToggleIsResetMFAModalOpen,
  setToggleIsRemoveUserModalOpen
} = userSlice.actions;

// TODO: Move to the user.selectors.ts file
export const selectIsResetPasswordModalOpen = (state: RootState) => state.user.isResetPasswordModalOpen;
export const selectIsResetMFAModalOpen = (state: RootState) => state.user.isResetMFAModalOpen;
export const selectUserByID = (state: RootState, userId: string) =>
  state.user.users.find((user) => user.auth0Id === userId);

export const selectUserToModify = (state: RootState) => state.user.userIdToModify;
export const selectIsRemoveUserModalOpen = (state: RootState) => state.user.isRemoveUserModalOpen;

// Export Reducer
export default userSlice.reducer;
