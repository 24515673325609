import { titleCaseWord } from '@3homes/libs';

export enum Product {
  Originator = 'originator',
  Investor = 'investor'
}

export enum Role {
  UserManagement = 'user management',
  Trader = 'trader',
  TradeViewer = 'trade viewer',
  FullDataViewer = 'full data viewer',
  FullDataAction = 'full data action'
}

export const dataRoles = Object.values(Role).filter((role) => role !== Role.UserManagement);

export interface Permissions {
  read: boolean;
  write: boolean;
}

export interface ProductPermission {
  key: Product | 'noProduct';
  read: boolean;
  write: boolean;
}

export interface ICreateAuth0User {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  roles: Role[];
  auth0ClientId?: string;
}
export interface IUpdateAuth0User {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  roles: Role[];
}

interface IUser {
  auth0Id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phone: string; // Can be empty
  roles: Role[];
  lastActivity: string; // Date string - Can be empty
}

interface IUserData extends IUser {
  status: UserStatus;
  isDeleted: boolean;
  buy: Permissions;
  sell: Permissions;
  admin: Permissions;
  role: Role[];
  createdAt?: Date;
  updatedAt?: Date;
}

enum UserStatus {
  Pending = 'Pending',
  Active = 'Active',
  Suspended = 'Suspended',
  Deleted = 'Deleted'
}

const upperCaseFirstLetters = (firstName: string[]) => {
  const transformedName = firstName.map((word) => titleCaseWord(word));

  return transformedName.join(' ');
};

export enum UserPreferences {
  DONT_SHOW_BID_HISTORY = 'isDontShowToggled'
}

export const getFullName = (firstName: string, lastName: string) => {
  if (!firstName) return;
  const firstNameParts = firstName.split(' ');
  const first = firstNameParts.length > 1 ? upperCaseFirstLetters(firstNameParts) : titleCaseWord(firstName);
  const last = titleCaseWord(lastName);

  return `${first} ${last}`;
};

export { UserStatus };
export type { IUser, IUserData };
