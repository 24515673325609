import SidebarNavigation from './SidebarNavigation';
import { OriginatorRoutes } from '../../../../Routing/originatorRoutes';
import { useProductRole } from '../../../hooks/useProductRole';

const OriginatorLayout = () => {
  const { role } = useProductRole();

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: '100vh' }}>
      <SidebarNavigation />
      <OriginatorRoutes role={role} />
    </div>
  );
};

export default OriginatorLayout;
