import { BaseRepository } from './BaseRepository';

export class DocumentQualityBaseRepository extends BaseRepository {
  protected suffixUrl = '/v1/document-quality';

  constructor(suffixFragment?: string) {
    super();

    if (suffixFragment) {
      this.suffixUrl += suffixFragment;
    }
  }

  getApiUrl(urlFragment: string) {
    return this.baseUrl + this.getProductString() + this.suffixUrl + urlFragment;
  }
}
