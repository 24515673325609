import { RootState } from '../../store';
import { createDeepEqualSelector } from '../../utils/selectors';

export const selectServicingRates = (state: RootState) => state.servicingRate;

export const makeSelectServicingRate = () =>
  createDeepEqualSelector(
    selectServicingRates,
    (_: RootState, bidKey: string) => bidKey,
    (servicingRates, bidKey) => servicingRates[bidKey]
  );
