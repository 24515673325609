import { AsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IBaseThunk, thunkReactQueryMiddleware } from '../thunks/base.thunks';
import { ITraderRepository } from 'shared/api/interfaces/ITraderRepository';
import { ITraderData } from 'shared/models/Trader';
import { STALE_TIME_LONG } from '../../shared/api/react-query';

export class TraderThunks implements IBaseThunk<ITraderData> {
  private readonly traderRepository!: ITraderRepository;

  constructor(_traderRepository: ITraderRepository) {
    this.traderRepository = _traderRepository;

    if (this.traderRepository === null) {
      throw new Error('traderRepository has not been instantiated!');
    }
  }

  private baseIdentifier = 'trader';

  getAll() {
    const action = `${this.baseIdentifier}/fetchTraders`;

    return thunkReactQueryMiddleware<ITraderData[], void>(action, () => ({
      queryFn: this.traderRepository.getAll,
      staleTime: STALE_TIME_LONG
    }));
  }

  getOne() {
    const action = `${this.baseIdentifier}/fetchTrader`;

    return thunkReactQueryMiddleware<ITraderData, string>(action, (id) => ({
      queryKey: [action, id],
      queryFn: () => this.traderRepository.getOne(id)
    }));
  }

  add(): AsyncThunk<AxiosResponse<ITraderData, any>, any, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  update(): AsyncThunk<AxiosResponse<ITraderData, any>, { id: string; item: ITraderData }, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  delete(): AsyncThunk<AxiosResponse<ITraderData, any>, string, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }
}
