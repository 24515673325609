import { createSlice } from '@reduxjs/toolkit';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { DeliveryDateThunks } from '../../thunks/deliveryDate.thunk';
import { IDeliveryDateRepository } from 'shared/api/interfaces/IDeliveryDateRepository';
import { IDeliveryDate } from 'shared/models/DeliveryDate';
import { PayloadAction } from '@reduxjs/toolkit';

export type DeliveryDateSlice = {
  deliveryDate: string;
  previousDeliveryDate: string;
  isLoaded: boolean;
};

const initialState: DeliveryDateSlice = {
  deliveryDate: '',
  previousDeliveryDate: '',
  isLoaded: false
};

const deliveryDateRepository = container.get<IDeliveryDateRepository>(SERVICE_KEYS.DELIVERY_DATE_REPOSITORY);
const deliveryDateThunks = new DeliveryDateThunks(deliveryDateRepository);

export const getDeliveryDate = deliveryDateThunks.get();
export const updateDeliveryDate = deliveryDateThunks.updateDeliveryDate();

const deliveryDateSlice = createSlice({
  name: 'deliveryDate',
  initialState,
  reducers: {
    setDeliveryDate: (state, action: PayloadAction<{ data: IDeliveryDate }>) => {
      const { payload } = action;

      state.deliveryDate = payload?.data.deliveryDate;
      state.previousDeliveryDate = payload?.data.deliveryDate;
      state.isLoaded = true;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDeliveryDate.fulfilled, (state, { payload }) => {
      state.deliveryDate = payload.data.deliveryDate;
      state.previousDeliveryDate = payload.data.deliveryDate;
      state.isLoaded = true;
    });

    builder.addCase(updateDeliveryDate.pending, (state, { meta }) => {
      state.deliveryDate = meta.arg.deliveryDate;
    });

    builder.addCase(updateDeliveryDate.fulfilled, (state, { payload }) => {
      state.previousDeliveryDate = payload.data.deliveryDate;
      // In theory this is not needed as is updated in pending, but just in case the BE responds with  something different
      state.deliveryDate = payload.data.deliveryDate;
    });

    builder.addCase(updateDeliveryDate.rejected, (state) => {
      state.deliveryDate = state.previousDeliveryDate;
    });
  }
});

export const deliveryDateReducer = deliveryDateSlice.reducer;

export const { setDeliveryDate } = deliveryDateSlice.actions;
