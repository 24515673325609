export enum s3BucketSuffix {
  unsold = 'unsold',
  sold = 'sold'
}

interface IDocument {
  Key: string;
  ETag: string;
  MetaData: Record<string, any>;
}

interface IMortgagesDocumentList {
  documents: IDocument[];
}

interface IMortgageDocumentList {
  documents: string[];
}

export type { IMortgagesDocumentList, IMortgageDocumentList, IDocument };
