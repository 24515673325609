import { createSlice } from '@reduxjs/toolkit';
import { IAnalysisCategory } from 'shared/models/Analysis-Categories';
import { PayloadAction } from '@reduxjs/toolkit';

export interface IAnalysisCategoriesState {
  documentCategories: IAnalysisCategory[];
}

const initialState: IAnalysisCategoriesState = {
  documentCategories: []
};

export type ISetAnalysisCategoriesPayload = PayloadAction<IAnalysisCategory[]>;

const analysisCategoriesSlice = createSlice({
  initialState,
  name: 'analysisCategories',
  reducers: {
    setAnalysisCategories: (state, action: ISetAnalysisCategoriesPayload) => {
      state.documentCategories = action.payload;
    }
  }
});

export const { setAnalysisCategories } = analysisCategoriesSlice.actions;

export default analysisCategoriesSlice.reducer;
