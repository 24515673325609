import classNames from 'classnames';
import './index.scss';
import { CheckSvg, ErrorBadgeTriangle } from 'shared/images';
import React from 'react';

export enum NumberBadgeStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INFO = 'INFO'
}

type TNumberBadgeProps = {
  value?: number;
  status?: NumberBadgeStatus;
  tooltip?: string;
  isLoading?: boolean;
  isError?: boolean;
  isRounded?: boolean;
  isPending?: boolean;
};

type TNumberBadgeContainerProps = { children: React.ReactNode } & Pick<
  TNumberBadgeProps,
  'tooltip' | 'status' | 'isRounded' | 'isError'
>;

const LoadingDots = () => {
  return (
    <div className="loading-dots">
      <div className="dot dot-flashing"></div>
    </div>
  );
};

const NumberBadgeContainer = ({
  children,
  tooltip,
  isRounded,
  isError,
  status = NumberBadgeStatus.INFO
}: TNumberBadgeContainerProps) => (
  <div title={tooltip} className={classNames('number-badge', status, { rounded: isRounded, error: isError })}>
    {children}
  </div>
);

const NumberBadge = ({
  value = 0,
  status = NumberBadgeStatus.INFO,
  tooltip = '',
  isLoading,
  isError,
  isPending,
  isRounded = false
}: TNumberBadgeProps) => {
  if (isError) {
    return (
      <NumberBadgeContainer isRounded={isRounded} tooltip={tooltip} isError={isError}>
        <ErrorBadgeTriangle width="12" height="12" />
      </NumberBadgeContainer>
    );
  }

  if (isLoading) {
    return (
      <NumberBadgeContainer isRounded={isRounded} tooltip={tooltip}>
        <LoadingDots />
      </NumberBadgeContainer>
    );
  }

  if (isPending) {
    return (
      <NumberBadgeContainer
        isRounded={isRounded}
        tooltip={tooltip}
        status={NumberBadgeStatus[NumberBadgeStatus.INFO]} // it's info to use gray colors
      >
        {<CheckSvg width={12} height={12} />}
      </NumberBadgeContainer>
    );
  }

  return (
    <NumberBadgeContainer isRounded={isRounded} status={NumberBadgeStatus[status]} tooltip={tooltip}>
      {status === NumberBadgeStatus.SUCCESS ? <CheckSvg width={12} height={12} /> : value}
    </NumberBadgeContainer>
  );
};

export default NumberBadge;
