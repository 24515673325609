import { createSlice } from '@reduxjs/toolkit';
import { IMortgage } from '../../../shared/models/Mortgage';
import { getSingleBulkV2 } from '../bulk/bulk.api.slice';
import { IBulkContract } from '../../../shared/models/Bulk';
import { RemoveMortgageContractPayload } from './types';

export type SingleBulkState = Record<IMortgage['internalId'], IBulkContract>;

export const initialSingleBulkState: SingleBulkState = {};

const singleBulkSlice = createSlice({
  name: 'singleBulk',
  initialState: initialSingleBulkState,
  reducers: {
    setSingleContractCompleted(state, action: RemoveMortgageContractPayload) {
      const { internalId, mortgageIds } = action.payload;
      const id = mortgageIds[0];

      if (state[id]?.internalId === internalId) {
        delete state[id];
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSingleBulkV2.fulfilled, (state, action) => {
      const contract = action.payload.data;

      state[contract.mortgageIds[0]] = contract;
    });
  }
});

export const PERSISTED_SINGLE_BULK_STORAGE_KEY = 'SINGLE_BULK';

export const { setSingleContractCompleted } = singleBulkSlice.actions;
export default singleBulkSlice.reducer;
