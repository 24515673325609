import { useCallback } from 'react';
import './index.scss';
import classNames from 'classnames';
import LoaderSkeleton from '../ChecklistReviewPanel/LoaderSkeleton';

interface TFilterButtonsProps<T> {
  onChange: (s: T) => void;
  selected?: string;
  buttons: string[];
  isLoading?: boolean;
}

const FilterButtons = <T,>({ buttons = [], onChange, selected = '', isLoading = false }: TFilterButtonsProps<T>) => {
  const _onClick = useCallback((n: T) => () => onChange(n), [onChange]);

  if (isLoading) {
    return <LoaderSkeleton height={20} />;
  }

  return (
    <div className="filter-buttons">
      {buttons.map((button) => {
        return (
          <button
            key={button}
            onClick={_onClick(button as T)}
            className={classNames('filter-button', { selected: selected === button })}
          >
            {button}
          </button>
        );
      })}
    </div>
  );
};

export default FilterButtons;
