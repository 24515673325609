import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { PricingOption } from '../../../shared/models/PricingOption';

export const selectPricingOption = createSelector(
  (state: RootState) => state.pricingOption,
  (pricingOption) => pricingOption
);

export const makeSelectServicersByInvestor = () =>
  createSelector(
    selectPricingOption,
    (_: RootState, investorHint: PricingOption['investorHint']) => investorHint,
    (pricingOption, investorHint) => pricingOption[investorHint]?.servicers || []
  );

export const makeSelectServicingFeeOptionsByInvestor = () =>
  createSelector(
    selectPricingOption,
    (_: RootState, investorHint: PricingOption['investorHint']) => investorHint,
    (pricingOption, investorHint) => pricingOption[investorHint]?.servicingFeeOptions || []
  );
