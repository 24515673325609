import { useEffect, useRef } from 'react';
import { useAppSelector, useAppDispatch } from '../../state-management/hooks';
import {
  selectToastsQueue,
  selectToastsTimerId,
  selectCurrentToastMessage
} from '../../state-management/slices/toast/toast.selectors';
import { _openToastAndInitializeTimer } from '../../state-management/slices/toast/toast.listener';

export const useToast = () => {
  const dispatch = useAppDispatch();
  const currentQueue = useAppSelector(selectToastsQueue);
  const currentTimerId = useAppSelector(selectToastsTimerId);
  const currentToast = useAppSelector(selectCurrentToastMessage);
  const previousQueueLength = useRef(currentQueue.length);

  useEffect(() => {
    // only set timer when something is added to queue
    if (currentQueue.length && currentQueue.length > previousQueueLength.current && !currentTimerId) {
      _openToastAndInitializeTimer(dispatch);
    }

    previousQueueLength.current = currentQueue.length;
  }, [currentQueue.length, currentTimerId, dispatch]);

  return currentToast;
};
