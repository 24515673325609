import { useParams, useNavigate, useLocation, generatePath } from 'react-router-dom';
import { DocumentReviewParams } from '../types';
import { useAppDispatch } from '../../../../../../state-management/hooks';
import { IAnalysisDocumentPreview } from 'shared/models/Analysis-Document';
import { VIEW_ALL_CATEGORY_ID } from '../hooks/useSidebarNav';
import { useEffect } from 'react';
import LoadingSpinner from 'shared/NovaPrimeUI/Components/LoadingSpinner/LoadingSpinner';
import useLoadPreviewDocuments from '../hooks/useLoadPreviewDocuments';
import { RouteUtils } from '../../../Quality/RouteUtils';

function DocumentIdValidator() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { status, mortgageId = '' } = useParams<DocumentReviewParams>();

  const { previewDocuments } = useLoadPreviewDocuments(mortgageId);

  useEffect(() => {
    if (previewDocuments) {
      // redirect to first document or view-all
      const firstLoanDocument = previewDocuments?.find((doc: IAnalysisDocumentPreview) => !doc.category);
      const category = VIEW_ALL_CATEGORY_ID;
      const documentId = firstLoanDocument?.id || '';
      let path = '';

      if (documentId) {
        path = RouteUtils.documentCategorizationReviewUrl;
      } else {
        path = RouteUtils.documentCategorizationCategoryUrl;
      }

      return navigate(generatePath(path, { status, mortgageId, documentId, category }) + search, { replace: true });
    }
  }, [previewDocuments, dispatch, mortgageId, navigate, search, status]);

  return <LoadingSpinner />;
}

export default DocumentIdValidator;
