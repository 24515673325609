import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IMortgage } from '../../../shared/models/Mortgage';
import { isSingleBulkContractInProgress } from './utils';

export const selectSingleBulk = (state: RootState) => state.singleBulk;

// Keeping this as a function to avoid breaking changes
export const makeSelectSingleBulkContract = () => {
  return (state: RootState, mortgageId: IMortgage['internalId']) => selectSingleBulk(state)[mortgageId];
};

export const makeSelectIsSingleBulkInProgress = () =>
  createSelector(
    selectSingleBulk,
    (_: RootState, mortgageId: IMortgage['internalId']) => mortgageId,
    (singleBulkState, mortgageId) => isSingleBulkContractInProgress(singleBulkState[mortgageId])
  );
