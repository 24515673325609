import { createSelector } from 'reselect';
import { RootState } from '../../store';

export const selectLQAStatuses = (state: RootState) => state.statuses.lqa;

export const makeSelectLQAStatusesByMortgageId = () =>
  createSelector(
    selectLQAStatuses,
    (_: RootState, mortgageId: string) => mortgageId,
    (statuses, mortgageId) => statuses[mortgageId]
  );
