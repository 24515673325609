import LoaderSkeleton from '../ChecklistReviewPanel/LoaderSkeleton';
import { useNavigate, useParams } from 'react-router-dom';
import FilterButtons from '../FilterButtons';
import './index.scss';
import { RouteUtils } from 'Sections/Originator/Pages/Quality/RouteUtils';
import { loanTypeTextMapping } from 'shared/components/SharedQCTableWrapper/utils';

interface ICategoryHeaderContentProps {
  expectedCloseDate?: string;
  loanNumber?: string;
  isLoading?: boolean;
  loanProgram?: string;
}

const LabelValueDisplay = ({
  label,
  value,
  isLoading = false
}: {
  label?: string;
  value?: string;
  isLoading?: boolean;
}) => {
  if (isLoading) {
    return (
      <div className="meta-data-loading">
        <LoaderSkeleton height={20} />
        <LoaderSkeleton height={20} />
      </div>
    );
  }

  return (
    <div className="meta">
      <span className="label">{label}</span>
      <span className="val">{value}</span>
    </div>
  );
};

export enum ChecklistReviewFilter {
  Review = 'Review',
  Complete = 'Complete',
  All = 'All'
}

const buttons = [ChecklistReviewFilter.Review, ChecklistReviewFilter.Complete, ChecklistReviewFilter.All];

const ChecklistTablePanelHeader = ({
  expectedCloseDate,
  loanNumber,
  loanProgram,
  isLoading = false
}: ICategoryHeaderContentProps) => {
  const navigate = useNavigate();
  const { mortgageId = '', filter = '' } = useParams();

  const onFilterChange = (nextStatus: ChecklistReviewFilter) => {
    navigate(RouteUtils.closeChecklistWithParams(mortgageId, nextStatus));
  };

  return (
    <div className="column-header">
      <div className="content-section">
        <div className="loan-details">
          {isLoading ? <LoaderSkeleton /> : <h3 className="loan-number">{loanNumber}</h3>}
          <LabelValueDisplay value={expectedCloseDate} label="Expected Close Date" isLoading={isLoading} />
          <LabelValueDisplay value={loanTypeTextMapping(loanProgram)} label="Loan Program" isLoading={isLoading} />
        </div>
        <FilterButtons<ChecklistReviewFilter>
          onChange={onFilterChange}
          buttons={buttons}
          isLoading={isLoading}
          selected={filter}
        />
      </div>
    </div>
  );
};

export default ChecklistTablePanelHeader;
