import { IMortgage } from './Mortgage';

export enum APIValidationStatus {
  PENDING = 'PENDING',
  PRECHECK_ERROR = 'PRECHECK_ERROR',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  DELAYED = 'DELAYED'
}

interface APIVendor {
  validationId: string;
  status: APIValidationStatus;
  totalItemCount: number;
  greenItemCount: number;
  redItemCount: number;
  yellowItemCount: number;
  errorItemCount: number;
  errorMessage: string;
  errorDump: string;
  createdAt: string;
  updatedAt: string;
}

export enum VendorName {
  LQA = 'LQA'
}

export interface APIValidation {
  mortgageId: IMortgage['internalId'];
  vendors: Record<VendorName, APIVendor>;
}

export enum LQAValidationResultKey {
  FREDDIE_LQA_LP_DATA_COMPARE = 'FREDDIE_LQA_LP_DATA_COMPARE',
  FREDDIE_LQA_PURCHASE_ELIGIBILITY = 'FREDDIE_LQA_PURCHASE_ELIGIBILITY',
  FREDDIE_LQA_GPC_SERVICE = 'FREDDIE_LQA_GPC_SERVICE',
  FREDDIE_LQA_INCOME_SERVICE = 'FREDDIE_LQA_INCOME_SERVICE',
  FREDDIE_LQA_ASSET_SERVICE = 'FREDDIE_LQA_ASSET_SERVICE'
}

export interface APIValidationResultLPADataCompare {
  name: string;
  status: string;
  message: string;
  type: LQAValidationResultKey.FREDDIE_LQA_LP_DATA_COMPARE;
  extraData: {
    'LQA Value': string;
    'LPA Value': string;
    subData: Array<{
      name: string;
      'LPA Value': string;
      'LQA Value': string;
    }>;
  };
}

export interface APIValidationResultNonLPADataCompare {
  name: string;
  status: string;
  message: string;
  type:
    | LQAValidationResultKey.FREDDIE_LQA_ASSET_SERVICE
    | LQAValidationResultKey.FREDDIE_LQA_GPC_SERVICE
    | LQAValidationResultKey.FREDDIE_LQA_INCOME_SERVICE
    | LQAValidationResultKey.FREDDIE_LQA_PURCHASE_ELIGIBILITY;
}

export type APIValidationResult = APIValidationResultLPADataCompare | APIValidationResultNonLPADataCompare;

export interface APIValidationInfo {
  id: string;
  mortgageId: IMortgage['internalId'];
  type: string;
  status: string;
  precheckResults: Record<string, APIValidationResult[]>;
  validationResults: Record<LQAValidationResultKey, APIValidationResult[]>;
  greenItemCount: number;
  yellowItemCount: number;
  redItemCount: number;
  errorItemCount: number;
  errorMessage: string;
  errorDump: string;
  createdAt: string;
  updatedAt: string;
}
