export const doDomainsMatch = (urlA: string, urlB: string): boolean => {
  try {
    return getDomain(urlA) === getDomain(urlB);
  } catch (error) {
    console.warn(`Unable to parse domain from: ${JSON.stringify({ urlA, urlB })}`);
    return false;
  }
};

const getDomain = (url: string): string => {
  const { hostname } = new URL(url);

  return hostname;
};
