import Skeleton, { SkeletonProps } from 'react-loading-skeleton';
import './index.scss';
import classNames from 'classnames';

type TLoaderSkeletonProps = SkeletonProps & {
  containerClass?: string;
  className?: string;
};

export const SkeletonWrapper = (props: SkeletonProps) => {
  return <Skeleton baseColor="var(--color-n200)" highlightColor="var(--color-n300)" borderRadius={20} {...props} />;
};

const LoaderSkeleton = ({ count = 1, height = 30, containerClass, className, ...props }: TLoaderSkeletonProps) => {
  const cn = classNames('checklist-review-loading-skeleton', containerClass);

  return (
    <div className={cn}>
      <SkeletonWrapper className={className} count={count} height={height} {...props} />
    </div>
  );
};

export default LoaderSkeleton;
