import { IBulkLoan, IBulkTrade } from '../../../shared/models/Loan';
import { BulkLoansError as BulkLoansError, BulkLoansErrorsMap, BulkTradesError, BulkTradesErrorsMap } from './types';
import { getFirmName } from '../../../shared/components/Other/FirmName';
import { getFirmFromAgent } from '../../helpers/slice.helper';
import { DEFAULT_SERVICING_FEE, findServicer, findSpecPool, isEqualIgnoreCase, isLoanSpecPoolBase } from './bulk.utils';
import { filterPayupsByBid, isBidInDelivery } from '../bid/bid.utils';
import Big from 'big.js';
import { IMortgageFlat } from '../../../shared/models/Mortgage';
import { PayupMap } from '../payup/payup.slice';
import { PricingOptionState } from '../pricingOption/pricingOption.slice';
import { Config } from '../config/config.slice';
import { IBid, IBidSecuritySlot, ServicingRate } from '../../../shared/models/Bid';
import { IPayup } from '../../../shared/models/Payup';
import { getPriceFinalPriceForServicingRate } from '../../../shared/utils/bid.helper';
import { getFormattedCoupon } from './aot/aot.grouping';
import { groupBy } from 'lodash';

type PartialMortgage = Pick<IMortgageFlat, 'internalId' | 'currentInterestRate'>;
type PartialBid = Pick<IBid, 'agent' | 'bidHistory' | 'priceForRate025' | 'priceForRate0375' | 'priceForRate05'>;
type PartialPayup = Pick<IPayup, 'agent' | 'name' | 'amountForRate025' | 'amountForRate0375' | 'amountForRate05'>;

export const _validateLoans = (
  loans: IBulkLoan[],
  openMortgagesById: Record<IMortgageFlat['loanNumber'], PartialMortgage>,
  bidsByMortgage: Record<IMortgageFlat['loanNumber'], PartialBid[]>,
  payupsByMortgage: PayupMap<PartialPayup>,
  pricingOption: PricingOptionState,
  config: Pick<Config, 'companies'>
) => {
  const validatedLoans: Record<IBulkLoan['loanNumber'], true> = {};

  return loans.reduce((accum, loan) => {
    // is loan number blank
    if (!loan.loanNumber) {
      accum[loan.loanId] = {
        loanNumber: BulkLoansError.BLANK_LOAN_NUMBER
      };

      return accum;
    }

    // is loan number duplicated
    if (validatedLoans[loan.loanNumber]) {
      accum[loan.loanId] = {
        loanNumber: BulkLoansError.DUPLICATED_LOAN
      };

      return accum;
    } else {
      validatedLoans[loan.loanNumber] = true;
    }

    // is loan open
    const mortgage = openMortgagesById[loan.loanNumber];

    if (!mortgage) {
      accum[loan.loanId] = {
        loanNumber: BulkLoansError.NOT_OPEN
      };

      return accum;
    }

    // is investor id blank
    const normalizedInvestorId = (loan.investorId || '').trim();

    if (!normalizedInvestorId) {
      accum[loan.loanId] = {
        investorId: BulkLoansError.BLANK_INVESTOR_ID
      };

      return accum;
    }

    // is bid from investor
    const bidsForMortgage = bidsByMortgage[mortgage.internalId] || [];
    const selectedBid = bidsForMortgage.find((bid) => {
      const firmName = getFirmName(getFirmFromAgent(bid.agent), config);

      return isEqualIgnoreCase(firmName, normalizedInvestorId);
    });

    if (!selectedBid) {
      accum[loan.loanId] = {
        investorId: BulkLoansError.NO_EXISTING_BID
      };

      return accum;
    }

    // is loan not in Committed
    if (isBidInDelivery(selectedBid)) {
      accum[loan.loanId] = {
        loanNumber: BulkLoansError.NOT_OPEN
      };

      return accum;
    }

    // if min interest rate blank
    if (!loan.interestMinRate) {
      accum[loan.loanId] = {
        interestMinRate: BulkLoansError.BLANK_INTEREST_MIN_RATE
      };

      return accum;
    }

    // if max interest rate blank
    if (!loan.interestMaxRate) {
      accum[loan.loanId] = {
        interestMaxRate: BulkLoansError.BLANK_INTEREST_MAX_RATE
      };

      return accum;
    }

    const minInterestRate = new Big(loan.interestMinRate);
    const maxInterestRate = new Big(loan.interestMaxRate);

    // if min interest rate less than max interest rate and vice versa
    if (minInterestRate.gt(maxInterestRate) || maxInterestRate.lt(minInterestRate)) {
      accum[loan.loanId] = {
        interestMinRate: BulkLoansError.INTEREST_RATES_NOT_IN_RANGE,
        interestMaxRate: BulkLoansError.INTEREST_RATES_NOT_IN_RANGE
      };

      return accum;
    }

    // If the diff between min and max interest rate is less-equal than 0.5%
    const interestRateDiff = maxInterestRate.minus(minInterestRate);

    if (interestRateDiff.gt(0.5)) {
      accum[loan.loanId] = {
        interestMinRate: BulkLoansError.INTEREST_RATES_DIFF_NOT_IN_RANGE,
        interestMaxRate: BulkLoansError.INTEREST_RATES_DIFF_NOT_IN_RANGE
      };

      return accum;
    }

    // Min/Max interest rate should be divisible by 0.125
    const minInterestRateMod = minInterestRate.mod(0.125);
    const maxInterestRateMod = maxInterestRate.mod(0.125);

    if (!minInterestRateMod.eq(0) || !maxInterestRateMod.eq(0)) {
      accum[loan.loanId] = {
        interestMinRate: BulkLoansError.INTEREST_RATES_DIFF_NOT_DIVISIBLE,
        interestMaxRate: BulkLoansError.INTEREST_RATES_DIFF_NOT_DIVISIBLE
      };

      return accum;
    }

    // if min interest rate in range
    if (minInterestRate.gt(mortgage.currentInterestRate)) {
      accum[loan.loanId] = {
        interestMinRate: BulkLoansError.INTEREST_RATE_NOT_IN_RANGE
      };

      return accum;
    }

    // if max interest rate in range
    if (maxInterestRate.lt(mortgage.currentInterestRate)) {
      accum[loan.loanId] = {
        interestMaxRate: BulkLoansError.INTEREST_RATE_NOT_IN_RANGE
      };

      return accum;
    }

    // If payup/spec pool exists for mortgage
    // Skip validation if spec pool is base, because we're already validated that a bid exists
    let selectedSpecPool;

    if (!isLoanSpecPoolBase(loan)) {
      const payupsByBid = filterPayupsByBid(payupsByMortgage[mortgage.internalId] || [], selectedBid);

      selectedSpecPool = findSpecPool(payupsByBid, loan);

      if (!selectedSpecPool?.name) {
        accum[loan.loanId] = {
          specPool: BulkLoansError.NO_SPEC_POOL
        };

        return accum;
      }
    }

    // If servicing firm is blank
    if (!(loan.servicerFirm || '').trim()) {
      accum[loan.loanId] = {
        servicerFirm: BulkLoansError.BLANK_SERVICER_FIRM
      };

      return accum;
    }

    // If servicing firm does not exist
    const investor = getFirmFromAgent(selectedBid.agent);
    const selectedServicer = findServicer(pricingOption[investor].servicers, loan);

    if (!selectedServicer) {
      accum[loan.loanId] = {
        servicerFirm: BulkLoansError.NO_SERVICER_FIRM
      };

      return accum;
    }

    const hasServicerFeeOptions = pricingOption[investor].servicingFeeOptions?.length > 0;

    let selectedServicingFee: ServicingRate | null = null;

    if (hasServicerFeeOptions) {
      // If there are servicing fee options, but none was entered
      if (!loan.servicerFee) {
        accum[loan.loanId] = {
          servicerFee: BulkLoansError.BLANK_SERVICER_FEE
        };
        return accum;
      }

      try {
        selectedServicingFee =
          pricingOption[investor].servicingFeeOptions
            .map((fee) => `${fee}` as ServicingRate)
            .find((fee) => Big(fee).eq(loan.servicerFee)) || null;
      } catch (e) {
        selectedServicingFee = null;
      }

      // If the entered servicing fee is not a valid servicing fee option
      if (!selectedServicingFee) {
        accum[loan.loanId] = {
          servicerFee: BulkLoansError.NO_SERVICER_FEE
        };
        return accum;
      }
    } else {
      // If a servicing fee was entered, but there are no servicing fee options
      if (loan.servicerFee) {
        accum[loan.loanId] = {
          servicerFee: BulkLoansError.BLANK_SERVICER_FEE_REQUIRED
        };
        return accum;
      }
    }

    // For Pennymac with no servicing fee, use the default for final price calculation
    const selectedServicingFeeOrDefault = selectedServicingFee || DEFAULT_SERVICING_FEE;

    // Validate 0 Price
    const finalPrice = getPriceFinalPriceForServicingRate(selectedBid, selectedServicingFeeOrDefault, selectedSpecPool);

    if (Big(finalPrice).lte(0)) {
      accum[loan.loanId] = {
        investorId: BulkLoansError.INVALID_PRICE
      };
      return accum;
    }

    // If there was a previous error, remove it
    accum[loan.loanId] = {};

    return accum;
  }, {} as BulkLoansErrorsMap);
};

const mmDDYYRegex = /^\d{2}\/\d{2}\/\d{2}$/;

export const doesTradeMatchAnySecuritySlot = (trade: IBulkTrade, securitySlotsSet: Set<IBidSecuritySlot['name']>) => {
  const securitySlotFromTrade = getFormattedCoupon(trade.type, trade.coupon, trade.settlementDate);

  return securitySlotsSet.has(securitySlotFromTrade);
};

export const _validateUniqueSecuritySlotForClientDealer = (
  trades: IBulkTrade[],
  tradeToErrors: BulkTradesErrorsMap
) => {
  // Reset all duplicate security slot errors first
  for (const trade of Object.keys(tradeToErrors)) {
    if (tradeToErrors[trade]?.type === BulkTradesError.DUPLICATE_SECURITY_SLOT) {
      delete tradeToErrors[trade].type;
      delete tradeToErrors[trade].coupon;
      delete tradeToErrors[trade].settlementDate;
    }
  }

  // For any client name + dealer combination, each security slot should be unique
  const tradesGroupedByClientDealer = groupBy(trades, (trade) => `${trade.clientName}-${trade.dealer}`);

  for (const tradesWithSameClientDealer of Object.values(tradesGroupedByClientDealer)) {
    const groupedBySecuritySlot = groupBy(tradesWithSameClientDealer, ({ type, coupon, settlementDate }) =>
      getFormattedCoupon(type, coupon, settlementDate)
    );
    const tradesWithSameSecuritySlotForClientDealer = Object.values(groupedBySecuritySlot).filter(
      (entry) => entry.length > 1
    );

    for (const _trades of tradesWithSameSecuritySlotForClientDealer) {
      // Mark the second instance as duplicate
      tradeToErrors[_trades[1]._id] = {
        type: BulkTradesError.DUPLICATE_SECURITY_SLOT,
        coupon: BulkTradesError.DUPLICATE_SECURITY_SLOT,
        settlementDate: BulkTradesError.DUPLICATE_SECURITY_SLOT
      };
    }
  }
};

export const isValidTradeOriginalPrice = (originalPrice: IBulkTrade['originalPrice']) => {
  // Original price must be a multiple of 1/256
  return Number.isInteger(originalPrice * 256);
};

export const _validateTrades = (trades: IBulkTrade[], securitySlotsSet: Set<IBidSecuritySlot['name']>) => {
  const tradeToErrors: BulkTradesErrorsMap = {};

  for (const trade of trades) {
    const fieldToError: BulkTradesErrorsMap['string'] = {};

    if (!trade.clientName) {
      fieldToError['clientName'] = BulkTradesError.BLANK_CLIENT_NAME;
    }
    if (!trade.dealer) {
      fieldToError['dealer'] = BulkTradesError.BLANK_DEALER;
    }
    if (!trade.tradeDate) {
      fieldToError['tradeDate'] = BulkTradesError.BLANK_TRADE_DATE;
    }
    if (!trade.settlementDate) {
      fieldToError['settlementDate'] = BulkTradesError.BLANK_SETTLEMENT_DATE;
    }
    if (!trade.originalSize) {
      fieldToError['settlementDate'] = BulkTradesError.BLANK_SETTLEMENT_DATE;
    }
    if (!trade.type) {
      fieldToError['type'] = BulkTradesError.BLANK_TYPE;
    }
    if (!trade.coupon) {
      fieldToError['coupon'] = BulkTradesError.BLANK_COUPON;
    }
    if (!trade.originalPrice) {
      fieldToError['originalPrice'] = BulkTradesError.BLANK_ORIGINAL_PRICE;
    }
    if (!trade.cusip) {
      fieldToError['cusip'] = BulkTradesError.BLANK_CUSIP;
    }
    if (!fieldToError['tradeDate'] && !trade.tradeDate.toString().match(mmDDYYRegex)) {
      fieldToError['tradeDate'] = BulkTradesError.INCORRECT_DATE_FORMAT;
    }
    if (!fieldToError['settlementDate'] && !trade.settlementDate.toString().match(mmDDYYRegex)) {
      fieldToError['settlementDate'] = BulkTradesError.INCORRECT_DATE_FORMAT;
    }
    if (!isValidTradeOriginalPrice(trade['originalPrice'])) {
      fieldToError['originalPrice'] = BulkTradesError.INVALID_ORIGINAL_PRICE;
    }
    if (!doesTradeMatchAnySecuritySlot(trade, securitySlotsSet)) {
      fieldToError['type'] = BulkTradesError.NO_MATCHING_SECURITY_SLOT;
      fieldToError['coupon'] = BulkTradesError.NO_MATCHING_SECURITY_SLOT;
      fieldToError['settlementDate'] = BulkTradesError.NO_MATCHING_SECURITY_SLOT;
    }

    if (Object.values(fieldToError).length) {
      tradeToErrors[trade._id] = fieldToError;
    } else {
      delete tradeToErrors[trade._id];
    }
  }

  _validateUniqueSecuritySlotForClientDealer(trades, tradeToErrors);

  return tradeToErrors;
};
