import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IMortgageFlat, IMortgageHistory } from 'shared/models/Mortgage';
import { createDeepEqualSelector } from '../../utils/selectors';
import { filterMortgagesByBidTapeParams } from './filter.utils';
import { selectSelectedBidTape } from '../bid-tape/bid-tape.selectors';
import { selectLQAStatuses } from '../statuses/statuses.selectors';

export const selectOpenMortgages = (state: RootState) => state.mortgage.openMortgages;
export const selectFilteredOpenMortgages = createSelector(
  selectOpenMortgages,
  selectSelectedBidTape,
  (mortgages, selectedBidTape) =>
    selectedBidTape ? filterMortgagesByBidTapeParams(mortgages, selectedBidTape) : mortgages
);

export const selectPendingMortgages = (state: RootState) => state.mortgage.pendingMortgages;
export const selectFilteredPendingMortgages = createSelector(
  selectPendingMortgages,
  selectSelectedBidTape,
  (mortgages, selectedBidTape) =>
    selectedBidTape ? filterMortgagesByBidTapeParams(mortgages, selectedBidTape) : mortgages
);

export const selectDraftMortgages = (state: RootState) => state.mortgage.draftMortgages;
export const selectFilteredDraftMortgages = createSelector(
  selectDraftMortgages,
  selectSelectedBidTape,
  selectLQAStatuses,
  (mortgages, selectedBidTape, lqaStatuses) => {
    const filteredMortgages = selectedBidTape ? filterMortgagesByBidTapeParams(mortgages, selectedBidTape) : mortgages;

    return filteredMortgages.map((mortgage) => ({
      ...mortgage,
      lqaStatus: lqaStatuses[mortgage.internalId]
    }));
  }
);
export const selectClosedMortgages = (state: RootState) => state.mortgage.closedMortgages;
export const selectDeactivatedMortgages = (state: RootState) => state.mortgage.deactivatedMortgages;
export const selectSoldMortgages = (state: RootState) => state.mortgage.soldMortgages;
export const selectMortgagesHistory = (state: RootState) => state.mortgage.mortgageHistory;

export const selectDeactivatedMortgagesInQueue = (state: RootState) => state.mortgage.deactivatedMortgagesQueue.size;
export const selectCurrentDeactivatedCount = (state: RootState) => state.mortgage.currentDeactivatedCount;
export const selectFailedToDeactivateCount = (state: RootState) => state.mortgage.failedToDeactivate.length;

export type MortgageMap = { [mortgageId: string]: IMortgageFlat };

const _getMortgagesMap = (mortgages: IMortgageFlat[]) => {
  return mortgages.reduce<MortgageMap>((acc, mortgage) => {
    acc[mortgage.internalId] = mortgage;
    return acc;
  }, {});
};

export const selectOpenMortgagesMap = createSelector(selectOpenMortgages, _getMortgagesMap);
export const selectPendingMortgagesMap = createSelector(selectPendingMortgages, _getMortgagesMap);
export const selectDraftMortgagesMap = createSelector(selectDraftMortgages, _getMortgagesMap);
export const selectClosedMortgagesMap = createSelector(selectClosedMortgages, _getMortgagesMap);
export const selectSoldMortgagesMap = createSelector(selectSoldMortgages, _getMortgagesMap);

export const makeSelectOpenMortgage = () =>
  createDeepEqualSelector(
    selectOpenMortgagesMap,
    (_: RootState, mortgageId: string) => mortgageId,
    (map: MortgageMap, mortgageId = '') => map[mortgageId] || null
  );

export const makeSelectPendingMortgage = () =>
  createDeepEqualSelector(
    selectPendingMortgagesMap,
    (_: RootState, mortgageId: string) => mortgageId,
    (map: MortgageMap, mortgageId = '') => map[mortgageId] || null
  );

export const makeSelectDraftMortgage = () =>
  createDeepEqualSelector(
    selectDraftMortgagesMap,
    (_: RootState, mortgageId: string) => mortgageId,
    (map: MortgageMap, mortgageId = '') => map[mortgageId] || null
  );

export const makeSelectClosedMortgage = () =>
  createDeepEqualSelector(
    selectClosedMortgagesMap,
    (_: RootState, mortgageId: string) => mortgageId,
    (map: MortgageMap, mortgageId = '') => map[mortgageId] || null
  );
export const makeSelectSoldMortgage = () =>
  createDeepEqualSelector(
    selectSoldMortgagesMap,
    (_: RootState, mortgageId: string) => mortgageId,
    (map: MortgageMap, mortgageId = '') => map[mortgageId] || null
  );

export const makeSelectMortgageHistory = () =>
  createDeepEqualSelector(
    selectMortgagesHistory,
    (_: RootState, mortgageId: string) => mortgageId,
    (map: Record<string, IMortgageHistory>, mortgageId = '') => map[mortgageId] || null
  );

// Quality
export const selectQualityMortgages = createSelector(
  selectDraftMortgages,
  selectPendingMortgages,
  (draft, pending) => ({ draft, pending })
);

export const makeSelectMortgageById = () =>
  createDeepEqualSelector(
    [
      selectDraftMortgagesMap,
      selectOpenMortgagesMap,
      selectPendingMortgagesMap,
      selectClosedMortgagesMap,
      selectSoldMortgagesMap,
      (_: RootState, mortgageId: string) => mortgageId
    ],
    (draftMap, openMap, pendingMap, closedMap, soldMap, mortgageId) => {
      return (
        draftMap[mortgageId] ||
        openMap[mortgageId] ||
        pendingMap[mortgageId] ||
        closedMap[mortgageId] ||
        soldMap[mortgageId] ||
        null
      );
    }
  );
