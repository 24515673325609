import {
  AnalysisDocumentStatus,
  IAnalysisCategory,
  IAnalysisDocumentDetails,
  IAnalysisRule,
  ICategoryDocument,
  IDocumentRegion,
  IDocumentRules,
  RuleResult,
  TBoundingBox
} from '../../../shared/models/Analysis-Document';
import {
  IChecklistReviewTableData,
  IChecklistReviewTableDetailData,
  RuleSections
} from '../../../Sections/Originator/Pages/Quality/types';
import { sortCategoriesAlphabetically } from './utils';
import groupBy from 'lodash/groupBy';
import partition from 'lodash/partition';
import { Dictionary } from 'lodash';

export const _selectChecklistTableDataForMortgageFn = (analysisChecklistCategories: IAnalysisCategory[]) => {
  const categories: IChecklistReviewTableData[] = analysisChecklistCategories.map((category) => {
    const { differentiators, ...categoryProps } = category;

    // get all documents because differentiators can have same name but different documents
    const allDocs = [...differentiators].flatMap((differentiator) =>
      differentiator.documents.map((doc) => ({
        ...doc,
        label: differentiator.differentiator
      }))
    );

    let missingDocument = false;
    let isProcessing = false;
    let isDocumentError = false;
    const hasMultiDocs = allDocs.length > 1;
    let docId = '';

    // if not multi-doc, we check first doc for missing fileUrl
    const firstDoc = allDocs[0];

    if (!hasMultiDocs) {
      missingDocument = firstDoc.state === AnalysisDocumentStatus.MISSING;
      isProcessing = firstDoc.state === AnalysisDocumentStatus.PROCESSING;
      isDocumentError = firstDoc.state === AnalysisDocumentStatus.ERROR;
      docId = firstDoc?.documentId ?? '';
    }

    // pre-compute some props at differentiator level
    const childItems = hasMultiDocs
      ? allDocs.reduce<IChecklistReviewTableDetailData[]>((accum, doc: ICategoryDocument & { label: string }) => {
          accum.push({
            categoryCount: doc.rulesTodo,
            missingDocument: doc.state === AnalysisDocumentStatus.MISSING,
            label: doc.label,
            docId: doc.documentId,
            isProcessing: doc.state === AnalysisDocumentStatus.PROCESSING,
            isDocumentError: doc.state === AnalysisDocumentStatus.ERROR
          });

          return accum;
        }, [])
      : [];

    // todo: this data structure is confusing, we should consider consolidating missingDocument/isProcessing/isDocumentError/docId
    // into the same childItems array
    return {
      childItems,
      missingDocument,
      isProcessing,
      isDocumentError,
      category: categoryProps.category,
      categoryCount: category.rulesTodo,
      categoryCountToComplete: categoryProps.rulesToComplete,
      hasMultiDocs,
      docId
    };
  });

  // sort by missing doc and then alphabetically
  const withDocumentMissing: IChecklistReviewTableData[] = [];
  const withoutDocumentMissing: IChecklistReviewTableData[] = [];

  categories.forEach((_category) => {
    if (_category.missingDocument) {
      withDocumentMissing.push(_category);
    } else {
      withoutDocumentMissing.push(_category);
    }
  });

  return [
    ...withDocumentMissing.sort(sortCategoriesAlphabetically),
    ...withoutDocumentMissing.sort(sortCategoriesAlphabetically)
  ];
};

export type TRulesWithGroupIndex = IAnalysisRule & { groupIndex: number };

const calcBoundingBoxes = (region: IDocumentRegion | null) => {
  if (!region) return null;
  const { top, left, width, height, documentHeight, documentWidth, pageIndex } = region;

  return {
    ...region,
    pageIndex: pageIndex - 1,
    top: (top / documentHeight) * 100,
    left: (left / documentWidth) * 100,
    width: (width / documentWidth) * 100,
    height: (height / documentHeight) * 100
  };
};

// todo: IMPORTANT: Add return type
export const _selectChecklistRulesForMortgageDocument = (document: IDocumentRules) => {
  if (!document || !document?.rules?.length) {
    return null;
  }

  const rules = document.rules;

  const {
    [RuleResult.OVERRIDE_PASS]: completedRules,
    [RuleResult.FAIL]: correctionNeededRules,
    [RuleResult.UPDATED]: updatedRules,
    [RuleResult.AUTO_PASS]: verifiedRules
  } = groupBy(rules, (rule) => rule.result);

  const withGroup = (rule: IAnalysisRule) => rule.group !== null;
  const [correctionNeededRulesWithGroup, singleCorrectionsNeededRules] = partition(correctionNeededRules, withGroup);
  const [updatedRulesWithGroup, singleUpdatedRules] = partition(updatedRules, withGroup);
  const [completedRulesWithGroup, singleCompletedRules] = partition(completedRules, withGroup);
  const [verifiedRulesWithGroup, singleVerifiedRules] = partition(verifiedRules, withGroup);

  const byGroup = (rule: IAnalysisRule) => rule.group;
  const groupedCorrectionNeededRules = groupBy(correctionNeededRulesWithGroup, byGroup);
  const groupedUpdatedRules = groupBy(updatedRulesWithGroup, byGroup);
  const groupedCompletedRules = groupBy(completedRulesWithGroup, byGroup);
  const groupedVerifiedRules = groupBy(verifiedRulesWithGroup, byGroup);

  // store flat rules for bounding boxes
  const flatRules: TRulesWithGroupIndex[] = [];

  // track groupIndex across the statuses
  let groupCountIndex = -1;

  // create a rule mapper to ensure groupCountIndex persists across mappings
  const createRuleMapper = () => {
    return {
      mapSingleRule: (rule: IAnalysisRule) => {
        groupCountIndex++;
        flatRules.push({ ...rule, groupIndex: groupCountIndex });
        return { ...rule, groupIndex: groupCountIndex };
      },
      mapGroupedRules: (
        primaryGroup: Dictionary<IAnalysisRule[]>,
        secondaryGroup: {
          name: string;
          rules: IAnalysisRule[];
          groupIndex: number;
        }[]
      ) => {
        return Object.keys(primaryGroup).map((key) => {
          const groupIndexExists = secondaryGroup.find((group) => group.name === key);
          let idx: number;

          // if there is already a group present in secondaryGroup, use that group's groupIndex
          if (groupIndexExists) {
            idx = groupIndexExists.groupIndex;
          } else {
            idx = ++groupCountIndex;
          }

          primaryGroup[key].forEach((rule) => {
            flatRules.push({ ...rule, groupIndex: idx });
          });

          return { name: key, rules: primaryGroup[key], groupIndex: idx };
        });
      }
    };
  };

  const { mapSingleRule, mapGroupedRules } = createRuleMapper();

  // rule groups in corrections needed section
  const correctionsNeededGroup = mapGroupedRules(groupedCorrectionNeededRules, []);

  // single rule in corrections needed section
  const correctionsNeededSingles = singleCorrectionsNeededRules.map(mapSingleRule);

  // rule groups in updated section
  const updatedGroup = mapGroupedRules(groupedUpdatedRules, correctionsNeededGroup);

  // single rule in updated section
  const updatedSingles = singleUpdatedRules.map(mapSingleRule);

  // rule groups in completed section
  const completedGroup = mapGroupedRules(groupedCompletedRules, updatedGroup);

  // single rule in completed section
  const completedSingles = singleCompletedRules.map(mapSingleRule);

  // rule groups in verified section
  const verifiedGroup = mapGroupedRules(groupedVerifiedRules, completedGroup);

  // single rule in verified section
  const verifiedSingles = singleVerifiedRules.map(mapSingleRule);

  const otherDocumentBoundingBoxes: TBoundingBox[] = [];

  const boundingBoxes =
    flatRules.reduce((acc: TBoundingBox[], rule) => {
      const boundingBox = calcBoundingBoxes(rule.region);

      if (rule.otherDocuments.length) {
        rule.otherDocuments.forEach((doc) => {
          if (doc.region) {
            const otherDocBoundingBox = calcBoundingBoxes(doc.region);

            if (otherDocBoundingBox) {
              otherDocumentBoundingBoxes.push({
                result: rule.result,
                ruleId: rule.ruleId,
                groupIndex: rule.groupIndex,
                documentId: doc.documentId as string,
                ...otherDocBoundingBox
              });
            }
          }
        });
      }

      if (boundingBox) {
        acc.push({
          ...boundingBox,
          result: rule.result,
          ruleId: rule.ruleId,
          groupIndex: rule.groupIndex,
          documentId: document.documentId
        });
      }
      return acc;
    }, []) ?? [];

  return {
    sectionedRules: [
      {
        section: RuleSections.CORRECTIONS_NEEDED,
        groups: correctionsNeededGroup,
        singles: correctionsNeededSingles,
        count: (correctionNeededRules || []).length,
        titleInfo:
          'Discrepancies were found for the following attributes. Please correct the document or update the loan data.'
      },
      {
        section: RuleSections.UPDATES_PENDING,
        groups: updatedGroup,
        singles: updatedSingles,
        count: (updatedRules || []).length,
        titleInfo: ''
      },
      {
        section: RuleSections.VERIFIY_MATCHES,
        groups: verifiedGroup,
        singles: verifiedSingles,
        count: (verifiedRules || []).length,
        titleInfo: ''
      },
      {
        section: RuleSections.COMPLETED,
        groups: completedGroup,
        singles: completedSingles,
        count: (completedRules || []).length,
        titleInfo: ''
      }
    ],
    flatRules,
    boundingBoxes,
    otherDocumentBoundingBoxes
  };
};

type TViewerOtherDocuments = Pick<IAnalysisRule['otherDocuments'][number], 'documentId' | 'category' | 'state'>;
type TViewerRule = Pick<IAnalysisRule, 'ruleId'> & {
  otherDocuments: TViewerOtherDocuments[];
};
type TViewerDoc = Pick<IDocumentRules, 'documentId' | 'uploadDate'> & {
  rules: TViewerRule[];
};
type TViewerDocDetails = Pick<IAnalysisDocumentDetails, 'category' | 'state'>;

type TDocumentViewerByRuleId = {
  documentId: IAnalysisDocumentDetails['id'] | null;
  originalDocumentId: IAnalysisDocumentDetails['id'] | null;
  uploadDate: IDocumentRules['uploadDate'] | undefined;
  category: IAnalysisDocumentDetails['category'] | undefined;
  state: IAnalysisDocumentDetails['state'] | undefined;
};

export const _selectChecklistDocumentViewersByRuleId = (
  document: TViewerDoc | null,
  documentDetails: TViewerDocDetails,
  ruleId: IAnalysisRule['ruleId']
): TDocumentViewerByRuleId[] | null => {
  if (!document) return null;

  const selectedRule = document.rules.find((rule) => rule.ruleId === ruleId);
  const returnDocs = [
    { ...document, state: documentDetails?.state, category: documentDetails?.category },
    ...(selectedRule?.otherDocuments || [])
  ];

  return returnDocs.map((doc) => {
    // otherDocuments is archived so return empty documentId to show uncategorized CTA
    if (!doc.documentId) {
      return {
        documentId: null,
        originalDocumentId: document.documentId,
        uploadDate: undefined,
        state: AnalysisDocumentStatus.MISSING,
        category: undefined
      };
    }
    return {
      originalDocumentId: document.documentId,
      documentId: doc.documentId,
      uploadDate: 'uploadDate' in doc ? doc.uploadDate : undefined,
      state: doc.state,
      category: doc.category
    };
  });
};
