import { ToastStatus } from '@3homes/libs';
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import {
  acceptBidAsOriginator,
  acceptRevisionAsOriginator,
  cancelBid,
  confirmBidAsInvestor,
  proposeRevisionAsInvestor,
  rejectRevisionAsOriginator,
  submitBid
} from '../bid/bid.slice';
import {
  deactivateSingleMortgage,
  openMortgages,
  reactivateMortgage,
  transferMortgage
} from '../mortgage/mortgage.slice';
import { createAuth0User, removeUser, resetMFA, resetPassword, updateAuth0User } from '../user/user.slice';
import { REJECTED_BID_COMMIT } from './toast.messages';
import { TToastMessage } from './types';
import { updateDeliveryDate } from '../deliveryDate/delivery-date.slice';

// TODO: Use ToastProps from @3homes/libs
export type ToastSlice = {
  isOpen: boolean;
  queue: TToastMessage[];
  timerId: null | number;
};

const initialState: ToastSlice = {
  isOpen: false,
  queue: [],
  timerId: null
};

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    addToast(state, action: PayloadAction<TToastMessage>) {
      const { status, text } = action.payload;

      state.queue.push({
        status,
        text
      });
    },
    addToastError(state, action: PayloadAction<TToastMessage['text']>) {
      const text = action.payload;

      state.queue.push({
        text,
        status: ToastStatus.error
      });
    },
    _closeToast(state) {
      state.isOpen = false;
    },
    _openToast(state) {
      state.isOpen = true;
    },
    _setTimerId(state, action: PayloadAction<ToastSlice['timerId']>) {
      state.timerId = action.payload;
    },
    _removePreviousToastFromQueue(state) {
      state.queue.shift();
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitBid.fulfilled, (state) => {
        state.queue.push({
          text: 'Bid placed. Pending commit from seller.',
          status: ToastStatus.success
        });
      })
      .addCase(submitBid.rejected, (state) => {
        state.queue.push({
          text: REJECTED_BID_COMMIT,
          status: ToastStatus.error
        });
      })
      .addCase(cancelBid.rejected, (state) => {
        state.queue.push({
          text: 'There was an error while trying to cancel a bid.',
          status: ToastStatus.error
        });
      })
      .addCase(cancelBid.fulfilled, (state) => {
        state.queue.push({
          text: 'Bid Cancelled',
          status: ToastStatus.success
        });
      })
      .addCase(acceptBidAsOriginator.fulfilled, (state) => {
        state.queue.push({
          text: 'Bid committed. Pending price confirmation from investor.',
          status: ToastStatus.success
        });
      })
      .addCase(acceptBidAsOriginator.rejected, (state) => {
        state.queue.push({
          text: 'Cancel the pending bid before committing to another bid.',
          status: ToastStatus.error
        });
      })
      .addCase(confirmBidAsInvestor.fulfilled, (state) => {
        state.queue.push({
          text: 'Price confirmed. Pending approval from seller.',
          status: ToastStatus.success
        });
      })
      .addCase(proposeRevisionAsInvestor.fulfilled, (state) => {
        state.queue.push({
          text: 'Price confirmed. Pending approval from the seller.',
          status: ToastStatus.success
        });
      })
      .addCase(acceptRevisionAsOriginator.fulfilled, (state) => {
        // TODO, verify why below line was needed when matching a 'fulfilled' action
        // const succeed = state.status === ToastStatus.success;
        state.queue.push({
          text: 'Price change accepted.',
          status: ToastStatus.success
        });
      })
      .addCase(rejectRevisionAsOriginator.fulfilled, (state) => {
        state.queue.push({
          text: 'Price change rejected. Pending price confirmation from investor.',
          status: ToastStatus.success
        });
      })
      .addCase(transferMortgage.fulfilled, (state) => {
        state.queue.push({
          text: 'Mortgage marked sold',
          status: ToastStatus.success
        });
      })
      .addCase(openMortgages.fulfilled, (state, { meta }) => {
        state.queue.push({
          text: `${meta.arg.length} Mortgage(s) made available for sale and have moved to the Bids tab.`,
          status: ToastStatus.success
        });
      })
      .addCase(openMortgages.rejected, (state) => {
        state.queue.push({
          text: 'There was an error while trying to mark selected mortgages as cleared to close.',
          status: ToastStatus.error
        });
      })
      .addCase(deactivateSingleMortgage.fulfilled, (state) => {
        state.queue.push({
          text: 'Mortgage deactivated.',
          status: ToastStatus.success
        });
      })
      .addCase(reactivateMortgage.fulfilled, (state) => {
        state.queue.push({
          text: 'Mortgage reactivated. It has moved into the Drafts tab.',
          status: ToastStatus.success
        });
      })
      .addCase(deactivateSingleMortgage.rejected, (state, action: any) => {
        state.queue.push({
          text: action?.payload?.data?.message ?? 'There was an error while trying to deactivate a mortgage.',
          status: ToastStatus.error
        });
      })
      .addCase(removeUser.fulfilled, (state) => {
        state.queue.push({
          text: 'User removed.',
          status: ToastStatus.success
        });
      })
      .addCase(removeUser.rejected, (state) => {
        state.queue.push({
          text: 'Cannot remove user because this user is assigned as the only admin. Assign another user as admin before removing.',
          status: ToastStatus.error
        });
      })
      .addCase(createAuth0User.fulfilled, (state) => {
        state.queue.push({
          text: 'User Created.',
          status: ToastStatus.success
        });
      })
      .addCase(updateAuth0User.fulfilled, (state) => {
        state.queue.push({
          text: 'User Saved.',
          status: ToastStatus.success
        });
      })
      .addCase(updateAuth0User.rejected, (state) => {
        state.queue.push({
          text: 'Connection error while updating user. Try Again.',
          status: ToastStatus.error
        });
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.queue.push({
          text: 'Password Reset. An email has been sent to the user.',
          status: ToastStatus.success
        });
      })
      .addCase(resetPassword.rejected, (state) => {
        state.queue.push({
          text: 'There was an error while trying to reset the password.',
          status: ToastStatus.success
        });
      })
      .addCase(resetMFA.fulfilled, (state) => {
        state.queue.push({
          text: '2-Factor Authentication Reset.',
          status: ToastStatus.success
        });
      })
      .addCase(updateDeliveryDate.rejected, (state) => {
        state.queue.push({
          text: 'Update Delivery Date failed. Please select a delivery date and submit again.',
          status: ToastStatus.error
        });
      })
      .addMatcher(isAnyOf(acceptBidAsOriginator.rejected, transferMortgage.rejected), (state) => {
        //Placeholder until specific errors implemented
        state.queue.push({
          text: 'An error occurred. Please try again and contact your admin if the issue persists.',
          status: ToastStatus.error
        });
      });
  }
});

export const { addToast, addToastError, _setTimerId, _openToast, _closeToast, _removePreviousToastFromQueue } =
  toastSlice.actions;
export default toastSlice.reducer;
