import { getGlobalConfig } from '../../state-management/slices/config/config.slice';

export function removeFinalForwardSlash(text: string) {
  if (text[text.length - 1] === '/') return text.slice(0, text.length - 1);
  return text;
}

export async function handleLogout() {
  getGlobalConfig().then((config: any) => {
    const authority = removeFinalForwardSlash(config.oauth.authority);

    sessionStorage.clear();
    location.href = `${authority}/v2/logout?client_id=${config.oauth.clientId}&returnTo=${encodeURIComponent(
      location.origin
    )}`;
  });
}
