import { Auth0ContextInterface } from '@auth0/auth0-react';
import { useState } from 'react';
import { Config } from '../../state-management/slices/config/config.slice';
import { authManager } from 'shared/auth/auth-manager';
import { Product } from 'shared/models/User';

function useAuthManager() {
  const [isLoading, setIsLoading] = useState(true);

  const load = async (auth: Auth0ContextInterface, config: Config, product?: Product) => {
    setIsLoading(true);

    await authManager.load(auth, config, product);

    setIsLoading(false);
  };

  return {
    isLoading,
    load
  };
}

export default useAuthManager;
