import { IBulkGroupLoan, IBulkLoan } from '../../../../shared/models/Loan';
import { TBulkBid, TBulkBidsMap, TBulkConfig, TBulkLoanMortgage, TBulkPayupsMap, TExtraDataMortgageMap } from './types';
import Big from 'big.js';
import { v4 as uuidv4 } from 'uuid';
import { getPriceFinalPriceForServicingRate } from '../../../../shared/utils/bid.helper';
import { filterPayupsByBid } from '../../bid/bid.utils';
import { getFirmName } from '../../../../shared/components/Other/FirmName';
import { getFirmFromAgent } from '../../../helpers/slice.helper';
import { findPayupBySpecPool, isEqualIgnoreCase, isLoanSpecPoolBase } from '../bulk.utils';
import { getFannieMaeLoanProductId, getFreddieMacLoanProductId } from '../bulk-product-id';
import { isFreddieMac } from '../../../../shared/utils/freddie.helper';
import { IInvestorProcessed } from 'shared/models/Investor';

export const MAX_GROUP_AMOUNT = 25e6; // 25M

const _getBidForLoan = (loan: IBulkLoan, bidsByMortgage: TBulkBid[], config: TBulkConfig) => {
  return bidsByMortgage.find((bid) => getFirmName(getFirmFromAgent(bid.agent), config) === loan.investorId);
};

const _getBidAmount = (loan: IBulkLoan, mortgage: TBulkLoanMortgage, bid: TBulkBid, payups: TBulkPayupsMap) => {
  const payupsByMortgage = payups[mortgage.internalId];

  let payupForLoan;

  if (!isLoanSpecPoolBase(loan)) {
    const payupsByBid = filterPayupsByBid(payupsByMortgage, bid);

    // TODO: In theory this should never happen, add better handling
    if (!payupsByBid.length) {
      console.error('There is no specPool for this loan: ', loan.loanNumber);
      return new Big(0).toFixed(2);
    }

    payupForLoan = payupsByBid.find(findPayupBySpecPool(loan));
  }

  return getPriceFinalPriceForServicingRate(bid, loan.servicerFee, payupForLoan);
};

export const addMortgageData = (
  mortgages: TExtraDataMortgageMap,
  bids: TBulkBidsMap,
  payups: TBulkPayupsMap,
  config: TBulkConfig,
  isLoanPartySingleGroup: (bidAgent: string) => boolean
) => {
  return (loan: IBulkLoan): IBulkGroupLoan => {
    // We don't want to include these in the final object
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { interestMinRate, interestMaxRate, loanId, ...groupLoanProps } = loan;
    const mortgage = mortgages[loan.loanNumber] || {};
    let bidForLoan = _getBidForLoan(loan, bids[mortgage.internalId], config);

    // TODO: In theory this should never happen, test it and add better handling if needed
    if (!bidForLoan) {
      console.error('There is no bid for this loan: ', loan.loanNumber);
      bidForLoan = {
        agent: '',
        seller: '',
        priceForRate025: '0',
        priceForRate0375: '0',
        priceForRate05: '0',
        servicingRate: '0.25',
        securitySlots: []
      };
    }

    const isFreddie = isFreddieMac(getFirmFromAgent(bidForLoan.agent), config?.freddieMacId || '');
    let _productId = null;

    if (!isLoanPartySingleGroup(bidForLoan.agent)) {
      _productId = isFreddie ? getFreddieMacLoanProductId(mortgage) : getFannieMaeLoanProductId(mortgage);
    }

    return {
      ...groupLoanProps,
      _productId,
      _bidAgent: bidForLoan.agent,
      _bidSeller: bidForLoan.seller,
      _interestMinRate: loan.interestMinRate,
      _interestMaxRate: loan.interestMaxRate,
      _securitySlots: bidForLoan.securitySlots,
      _internalMortgageId: mortgage.internalId,
      bidAmount: _getBidAmount(loan, mortgage, bidForLoan, payups),
      interestRate: new Big(mortgage.currentInterestRate).toFixed(3),
      noteAmount: new Big(mortgage.noteAmount).toFixed(2)
    };
  };
};

export const calculateContractAmount = (loans: Pick<IBulkGroupLoan, 'noteAmount'>[]) =>
  loans.reduce((big, loan) => big.add(loan.noteAmount), new Big(0)).toFixed(2);

export const normalizeSpecPool = (specPool: string) => {
  const normalizedSpecPool = (specPool || '').trim();

  if (!normalizedSpecPool || isEqualIgnoreCase(normalizedSpecPool, 'Base')) {
    return '';
  }

  return normalizedSpecPool;
};

export const splitGroupByContractAmount = <Loan extends Pick<IBulkGroupLoan, 'noteAmount'>>(
  group: Loan[],
  maxAmount = MAX_GROUP_AMOUNT
): Loan[][] => {
  // Split sortedLoans into groups of maxAmount
  const splitGroups: Loan[][] = [];
  let currentGroup: Loan[] = [];
  let currentGroupAmount = new Big(0);

  const tempGroup = [...group];

  while (tempGroup.length) {
    [...tempGroup].forEach((loan, i) => {
      const loanAmount = new Big(loan.noteAmount);

      // Always adds the first loan to the group
      if (
        i === 0 ||
        // if the subsequent loan fit into the group, add it
        currentGroupAmount.add(loanAmount).lte(maxAmount)
      ) {
        // Use currentGroup.length to take index shifting into account
        tempGroup.splice(i - currentGroup.length, 1);

        currentGroup.push(loan);
        currentGroupAmount = currentGroupAmount.add(loanAmount);
      }
    });

    splitGroups.push(currentGroup);
    currentGroup = [];
    currentGroupAmount = new Big(0);
  }

  return splitGroups;
};

export const getNewContractId = (): string => uuidv4();

export const createIsLoanPartySingleGroup = (isFeatureEnabled: boolean, investors: IInvestorProcessed[]) => {
  if (!isFeatureEnabled) {
    return () => false;
  }

  const singleGroupParties: Record<string, true> = investors
    .filter((investor) => Boolean(investor.singleGroup))
    .reduce((result, entry) => ({ ...result, [entry.party]: true }), {});

  return (bidAgent: string) => !!singleGroupParties[bidAgent];
};
