import admin from './en.admin';
import mortgages from './en.mortgages';
import bidTape from 'Resources/en/en.bidTape';

const en = {
  translation: {
    ...admin,
    ...mortgages,
    ...bidTape,
    validation: {
      firstNameRequired: 'First Name is required.',
      lastNameRequired: 'Last Name is required.',
      firstLastNameRequired: 'First and Last Name is required.',
      emailRequired: 'Email is required.',
      emailInvalid: 'Please enter a valid email address.',
      emailDuplicate: 'This email is already in use. Does this user already have an account?',
      phoneRequired: 'Phone is required.',
      phoneInvalid: 'Please enter a valid phone number with 10 digits.',
      productsRequired: 'Products are required.',
      expirationDateRequired: 'Expiration Date is required.',
      dateInvalid: 'Please input a valid date.'
    },
    tooltip: {
      loanNumber: 'Loan Number',
      universalId: 'Universal ID',
      mersMin: 'MERS Min'
    }
  }
};

export default en;
