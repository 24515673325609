import { createSlice } from '@reduxjs/toolkit';
import { InvestorThunks } from '../../thunks/investor.thunks';
import { IInvestorRepository } from 'shared/api/interfaces/IInvestorRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { IInvestor, IInvestorProcessed } from 'shared/models/Investor';
import { getFirmFromAgent } from '../../helpers/slice.helper';
import { SetSelectedInvestor } from './types';

export type InvestorSlice = {
  investors: IInvestorProcessed[];
  selectedInvestors: IInvestorProcessed[];
};

// Whenever we add something to the slice state, we need to increment the version number and ensure the restore function can handle it
export const investorStateVersion = '1.0.0';
export const initialInvestorState: InvestorSlice = {
  investors: [],
  selectedInvestors: []
};

const investorRepository = container.get<IInvestorRepository>(SERVICE_KEYS.INVESTOR_REPOSITORY);
const investorThunks = new InvestorThunks(investorRepository);

const normalizeInvestors = (investors: IInvestor): IInvestorProcessed => ({
  singleGroup: !!investors.singleGroup,
  party: investors.identifier,
  name: getFirmFromAgent(investors.identifier)
});

export const fetchInvestors = investorThunks.getAll();

const investorSlice = createSlice({
  name: 'investor',
  initialState: initialInvestorState,
  reducers: {
    setSelectedInvestor: (state, { payload }: SetSelectedInvestor) => {
      state.selectedInvestors = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInvestors.fulfilled, (state, { payload }) => {
      state.investors = (payload.data || []).map(normalizeInvestors);
    });
  }
});

export const { setSelectedInvestor } = investorSlice.actions;

export const INVESTOR_ACTIONS_TO_TRIGGER_PERSIST = [setSelectedInvestor];
export const INVESTOR_PERSIST_NAME = 'INVESTOR';
export default investorSlice.reducer;
