import { injectable } from 'inversify';
import { IBulkRepository } from 'shared/api/interfaces/IBulkRepository';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';
import 'reflect-metadata';

@injectable()
export class BulkRepository implements IBulkRepository {
  private baseRepository = new BaseRepository();

  apiUrlV2: string = this.baseRepository.getApiUrl('originator/v2/commitments');
}
