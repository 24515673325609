import { ButtonSize, ButtonType, DropDown, Modal } from '@3homes/libs';
import { useMemo, useState } from 'react';
import { User } from 'shared/images';
import { handleLogout } from 'shared/utils/logoutHelper';
import './SidebarEntryUserMenu.scss';

export type SidebarEntryUserMenuProps = {
  name: string;
  email: string;
};
type LogoutButtonProps = {
  name: string;
};

function LogoutButton({ name }: LogoutButtonProps) {
  return (
    <div className="sidebar-entry-action action-item" data-testid="logout-menu-button">
      <div className="action-icon">
        <User />
      </div>
      {name}
    </div>
  );
}

const modalButtons = {
  discardButton: {
    text: 'Log out',
    className: '',
    buttonSize: ButtonSize.default,
    buttonType: ButtonType.danger
  },
  cancelButton: {
    // Prettier re-formats 'I\'ll stay' to "I'll stay" which triggers an ESLint error
    // eslint-disable-next-line quotes
    text: "I'll stay",
    className: '',
    buttonSize: ButtonSize.default,
    buttonType: ButtonType.secondary
  }
};

export default function SidebarEntryUserMenu({ name, email }: SidebarEntryUserMenuProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dropdownContent = useMemo(
    () => [
      {
        location: 'noloc',
        text: name,
        subTxt: email
      },
      {
        text: 'Log Out',
        className: 'sidebar-user-menu-logout-entry',
        handleOnClick: () => {
          setIsModalOpen(true);
        }
      }
    ],
    [email, name]
  );

  return (
    <>
      <DropDown
        dropDownContent={dropdownContent}
        buttonContent={<LogoutButton name={name} />}
        popoverClass="user-logout-menu-popover"
      />
      <Modal
        title="Are you sure you want to log out?"
        width="460px"
        textContent={'Your work will be saved.'}
        open={isModalOpen}
        secondaryButton={modalButtons.cancelButton}
        onClickSecondaryButton={() => setIsModalOpen(false)}
        primaryButton={modalButtons.discardButton}
        onClickPrimaryButton={() => {
          setIsModalOpen(false);
          handleLogout();
        }}
      />
    </>
  );
}
