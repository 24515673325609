import { useEffect, useState } from 'react';
import { Config, getGlobalConfig, initConfig } from '../../state-management/slices/config/config.slice';
import { useDispatch } from 'react-redux';

const useInitGlobalConfig = () => {
  const dispatch = useDispatch();
  const [config, setConfig] = useState<Config>();

  useEffect(() => {
    getGlobalConfig().then((config) => {
      setConfig(config);
      dispatch(initConfig(config));
    });
  }, []);

  return config;
};

export default useInitGlobalConfig;
