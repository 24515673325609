import { createListenerMiddleware } from '@reduxjs/toolkit';
import { CommitContractError, IBulkContractAPIStatus } from 'shared/models/Bulk';
import { init } from '../../actions';
import { RootState } from '../../store';
import { selectSingleBulk } from './selectors';
import { commitSingleBulkV2, getSingleBulkV2 } from '../bulk/bulk.api.slice';
import { addToast } from '../toast/toast.slice';
import { ToastStatus } from '@3homes/libs';
import { REJECTED_BID_COMMIT } from '../toast/toast.messages';
import { INITIAL_BULK_CONTRACT_STATUS } from '../bulk/bulk.constants';
import { setSingleContractCompleted } from './slice';
import { startPollingContract } from '../bulk/bulk.slice';
import { CommitBulkErrorPayload } from '../bulk/types';
import { BULK_COMMIT_API_ERROR_TO_MSG } from '../../../shared/components/BulkFlow/ContractsDataStep/constants';

const singleBulkListener = createListenerMiddleware();

const DEFAULT_ERROR_MESSAGE = REJECTED_BID_COMMIT;

const _getErrorTextByKey = (errorKey?: CommitContractError): string => {
  if (!errorKey) {
    return DEFAULT_ERROR_MESSAGE;
  }

  const errorText = BULK_COMMIT_API_ERROR_TO_MSG[errorKey];

  if (!errorText) {
    console.error(`Error key ${errorKey} not found in BULK_COMMIT_API_ERROR_TO_MSG`);

    return DEFAULT_ERROR_MESSAGE;
  }

  return errorText;
};

/***** Start Resume Long Polling Single Commit *****/
singleBulkListener.startListening({
  actionCreator: init,
  effect: (action, api) => {
    const state = api.getState() as RootState;
    const contracts = selectSingleBulk(state);
    const contractsInProgress = Object.values(contracts).filter(
      (contract) => contract.status === INITIAL_BULK_CONTRACT_STATUS
    );

    if (!contractsInProgress.length) {
      return;
    }

    contractsInProgress.forEach((contract) => {
      api.dispatch(getSingleBulkV2({ internalId: contract.internalId }));
    });
  }
});

singleBulkListener.startListening({
  actionCreator: commitSingleBulkV2.fulfilled,
  effect: (action, api) => {
    const [contract] = action.payload.data;
    const { id, error } = contract;

    if (error) {
      return void api.dispatch(addToast({ status: ToastStatus.error, text: _getErrorTextByKey(error) }));
    }

    api.dispatch(startPollingContract({ id, isSingle: true }));
  }
});
/**** End Resume Long Polling Prime Commit *****/

/***** Start Handle Errors *****/
singleBulkListener.startListening({
  actionCreator: commitSingleBulkV2.rejected,
  effect: (action, api) => {
    const { data } = (action as CommitBulkErrorPayload).payload;

    api.dispatch(addToast({ status: ToastStatus.error, text: _getErrorTextByKey(data?.message) }));
  }
});

singleBulkListener.startListening({
  actionCreator: setSingleContractCompleted,
  effect: (action, api) => {
    const { status, statusMessage } = action.payload;

    if (status === IBulkContractAPIStatus.FailedToCommit) {
      api.dispatch(addToast({ status: ToastStatus.error, text: statusMessage || DEFAULT_ERROR_MESSAGE }));
    }
  }
});

/**** End Handle Errors *****/

export default singleBulkListener;
