import { Toast } from '@3homes/libs';
import { useAppDispatch } from '../../../state-management/hooks';
import { _closeToast } from '../../../state-management/slices/toast/toast.slice';
import { useToast } from 'shared/hooks/useToast';

const AppToast = () => {
  const dispatch = useAppDispatch();
  const currentToast = useToast();

  const handleToastClose = () => {
    dispatch(_closeToast());
  };

  if (!currentToast) {
    return null;
  }

  return <Toast toastStatus={currentToast.status} textDescription={currentToast.text} onClose={handleToastClose} />;
};

export default AppToast;
