import { ServicingRate } from '../../../../shared/models/Bid';
import { getFirmFromAgent } from '../../../helpers/slice.helper';
import { getPriceFinalPriceForServicingRate, getPriceFromServicerFee } from '../../../../shared/utils/bid.helper';
import { getFirmName } from '../../../../shared/components/Other/FirmName';
import { filterPayupsByBid, getBidKey } from '../../bid/bid.utils';
import { IBestPrice, IBestPriceBid, IBestPricePayup } from './types';
import { _sortByBestPriceDesc, _sortByInvestorId, _sortPayupsByName, _isBid } from './utils';
import { PricingOption } from '../../../../shared/models/PricingOption';
import { PricingOptionState } from '../../pricingOption/pricingOption.slice';
import { TBulkConfig } from '../bulk-grouping/types';

const getBestPrice = (
  bidsByMortgage: IBestPriceBid[],
  payupsByMortgage: IBestPricePayup[],
  pricingOption: PricingOptionState,
  config: TBulkConfig
) => {
  const bestPriceByInvestor = bidsByMortgage
    .map((bid) => {
      const payupsByBid = filterPayupsByBid(payupsByMortgage, bid);
      const feesByInvestor = pricingOption[getFirmFromAgent(bid.agent)]?.servicingFeeOptions || [];

      return _getBestPriceByInvestor(bid, payupsByBid, feesByInvestor, config);
    })
    // Sort alphabetically by investor name, in case the best price is the same
    .sort(_sortByInvestorId)
    .sort(_sortByBestPriceDesc);

  return bestPriceByInvestor[0];
};

// Export for testing
export const _getBestPriceByInvestor = (
  bid: IBestPriceBid,
  payups: IBestPricePayup[],
  fees: PricingOption['servicingFeeOptions'],
  config: TBulkConfig
): IBestPrice => {
  const firm = getFirmFromAgent(bid.agent);

  const feesByInvestor = fees.map((fee) => fee.toString()) as ServicingRate[];
  // In theory 0.25 is always the highest but just in case
  const bestServicerFee = feesByInvestor
    // Sort from highest to lowest
    // For sorting is ok to use Number
    .sort((a, b) => Number(getPriceFromServicerFee(b, bid)) - Number(getPriceFromServicerFee(a, bid)))[0];

  // Select the highest payup
  const payupsSortedAlphabetically = payups.sort(_sortPayupsByName);

  const bestPriceBidOrPayup = [bid, ...payupsSortedAlphabetically]
    .map((bidOrPayup) => {
      const payup = _isBid(bidOrPayup) ? undefined : bidOrPayup;

      return {
        ...bidOrPayup,
        bestPrice: getPriceFinalPriceForServicingRate(bid, bestServicerFee, payup)
      };
    })
    .sort((bidOrPayupA, bidOrPayupB) => Number(bidOrPayupB.bestPrice) - Number(bidOrPayupA.bestPrice))[0];

  const bestSpecPool = _isBid(bestPriceBidOrPayup) ? '' : bestPriceBidOrPayup.name;

  return {
    specPool: bestSpecPool,
    servicerFee: bestServicerFee,
    bestPrice: bestPriceBidOrPayup.bestPrice,
    investorId: getFirmName(firm, config),
    bidKey: getBidKey(bid)
  };
};

export default getBestPrice;
