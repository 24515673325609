export enum BidType {
  WholeLoan = 'Whole Loan',
  InvestorBid = 'Investor Bid',
  ServiceBid = 'Service Bid'
}

export type ServicingRate = '0.25' | '0.375' | '0.5';

export interface IBidFlattened extends Omit<IBid, 'adjustments'> {
  firm: string;
  bidType: BidType | null;
  buyerId: string; // TODO: Remove, is just a rename of agent
  sellerId?: string; // TODO: Remove, is just a rename of owner
  payupAmount?: string;
  readOnlyServicer?: boolean;
}

export interface IBidCreate {
  bidAmount: string;
  internalMortgageId: string;
  bidSubmitter: string;
}

export enum BidHistoryStatus {
  BID_SUBMITTED = 'BID_SUBMITTED',
  BID_CONFIRMED = 'BID_CONFIRMED',
  PENDING_CONFIRMATION = 'PENDING_CONFIRMATION',
  BID_REVISION_REJECTED = 'BID_REVISION_REJECTED',
  BID_REVISION_PROPOSED = 'BID_REVISION_PROPOSED'
}

export enum BidHistoryStatusSorted {
  BID_SUBMITTED = 0,
  PENDING_CONFIRMATION = 1,
  BID_REVISION_PROPOSED = 2,
  BID_REVISION_REJECTED = 0, // Does not add value to the sort
  BID_CONFIRMED = 4
}

// TODO: Update type number to strings
export interface IBidHistory {
  price: string;
  bidPriceDiference: string;
  status: BidHistoryStatus;
  firm: string;
  trader: string;
  date: string; // UTC date
}

interface IBidAdjustment {
  // note: string;
  reason: string;
  isWaiver: boolean;
  // changeBps: string;
}

export enum SecurityInstrument {
  FN_Fix_15_MBS = 'FN Fix-15 MBS',
  FN_Fix_30_MBS = 'FN Fix-30 MBS',
  GN_II_Fix_30 = 'GN II Fix-30',
  UM_Fix_15_MBS = 'UM Fix-15 MBS',
  UM_Fix_30_MBS = 'UM Fix-30 MBS'
}

export interface IBidSecuritySlot {
  name: string; // "FN30 6.250 9/3",
  couponRate: string; // decimal
  settlementDate: string; // ISO-8601 date
  securityInstrument: SecurityInstrument; // "FN Fix-30 MBS"
}

export interface IBid extends IBidCreate {
  contractId: string;
  agent: string;
  owner?: string;
  seller: string;
  isAccepted: boolean;
  payup?: string | null;
  price: string;
  acceptedDate?: string;
  createdDate: string | null;
  receivedDate: string | null;
  approvedDate?: string;
  bidApprover?: string;
  status?: string; // TODO: Move to IBidFlattened, this does not come from the backend
  bidHistory: IBidHistory[];
  hasBeenCommitted?: boolean;
  permissionRoles: string[];
  deliveryDate: string;
  extraFields: string | null;
  servicerId?: string;
  servicerName?: string;
  bulkContractId: string | null;
  pricingOption?: ServicingRate;
  priceForRate025: string;
  priceForRate0375: string;
  priceForRate05: string;
  basePrice: string;
  basePriceForRate025: string;
  basePriceForRate0375: string;
  basePriceForRate05: string;
  adjustments: IBidAdjustment[];
  securitySlots: IBidSecuritySlot[];
}

export interface IBidAPI extends Omit<IBid, 'securitySlots'> {
  securitySlots: IBidSecuritySlot[] | null;
}

export interface IBidAccept {
  internalMortgageId: string;
  buyerId: string;
  bidApprover: string;
  payup?: string | [];
  servicerId: string;
  pricingOption: ServicingRate;
}
export interface IBidAmount {
  bidAmount: string;
}
//make sure we should extend IbidFlattened and not IBid
export interface IBidWithPayup extends IBidFlattened {
  payupName: string;
  finalPrice: string;
  outby: string | null;
  name?: string;
  amount?: string;
}
export interface IBidWithNestedBids extends IBidWithPayup {
  nestedBids: IBidWithPayup[];
}
