import { BidHistoryStatus, IBid } from '../../../shared/models/Bid';
import { IPayup } from '../../../shared/models/Payup';
import { IMortgageFlat } from '../../../shared/models/Mortgage';
import { BidsByMortgageIdMap } from './bid.selectors';

export type TGetBidKey = Pick<IBid, 'internalMortgageId' | 'agent'>;

export function getBidKey(bid: TGetBidKey): string {
  return `${bid.internalMortgageId}|${bid.agent}`;
}

export function filterPayupsByBid<P extends Pick<IPayup, 'agent'>, B extends Pick<IBid, 'agent'>>(
  payups: P[],
  bid: B
): P[] {
  return payups.filter((payup) => payup.agent === bid.agent);
}

export function isBidInDelivery(bid?: Pick<IBid, 'bidHistory'>): boolean {
  return bid?.bidHistory[0].status === BidHistoryStatus.BID_CONFIRMED;
}

export const getBidMap = (mortgages: IMortgageFlat[], bids: IBid[]) => {
  return mortgages.reduce<BidsByMortgageIdMap>((acc, mortgage) => {
    acc[mortgage.internalId] = bids.filter((bid) => bid.internalMortgageId === mortgage.internalId);

    return acc;
  }, {});
};
