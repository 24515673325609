import { IBidSecuritySlot } from './Bid';

export enum CommitContractError {
  'MORTGAGE_IN_BULK' = 'MORTGAGE_IN_BULK',
  'MORTGAGE_ACCEPTED' = 'MORTGAGE_ACCEPTED',
  'LEDGER_ERROR' = 'LEDGER_INTERNAL_SERVER_ERROR',
  'MISSING_BID' = 'MISSING_BID',
  'MISSING_PAYUP' = 'MISSING_PAYUP',
  'FANNIE_COMMITMENT_FEATURE_IS_DISABLED' = 'FANNIE_COMMITMENT_FEATURE_IS_DISABLED'
}

export interface IBulkCommitContractAPIError {
  code: number;
  message: CommitContractError;
}

export enum IBulkContractAPIStatus {
  NotCommitted = 'NotCommitted',
  FailedToCommit = 'FailedToCommit',
  Committed = 'Committed'
}

export interface AOT extends Pick<IBidSecuritySlot, 'couponRate' | 'settlementDate' | 'securityInstrument'> {
  originalSize: string; // integer
  price: string; // must be multiple of 1/256, backend will reject otherwise
  tradeDate: string; // ISO-8601 date
  dealer: string;
  size: string; // integer
  // ids of mortgages that should be assigned to this slot
  mortgages: string[];
  cusip: string;
}

export interface IBulkContract {
  payup?: string | null;
  interestRateMin: string;
  interestRateMax: string;
  servicerId: string;
  servicingRate: string;
  productType: string; // e.g. 1002
  deliveryDate: string; // ISO format
  mortgageIds: string[]; // internalMortgageId
  originator: string;
  investor: string;
  internalId: string;
  status: IBulkContractAPIStatus;
  statusMessage?: string;
  aot?: AOT[];
  // investorCommitmentId: string // Uncomment if used
  // rateToPrice: Array<{ rate: string; basePrice: string }>;
  // mortgageCommitmentPrice: Array<{ internalMortgageId: string; bidPricePlusFees: string }>;
  // commitmentDate: string; // YYYY-MM-DD
}

// V2 API
export interface IResponseBulkContractV2 {
  id: string;
  error: CommitContractError | null;
}

export interface ICommitBulkContractV2
  extends Pick<
    IBulkContract,
    | 'interestRateMin'
    | 'interestRateMax'
    | 'payup'
    | 'servicerId'
    | 'servicingRate'
    | 'productType'
    | 'deliveryDate'
    | 'mortgageIds'
    | 'investor'
    | 'aot'
  > {
  id: string;
}
