import { User } from 'shared/images';
import { handleLogout } from 'shared/utils/logoutHelper';

export function SidebarEntryLogout() {
  return (
    <button onClick={handleLogout} className="action-item danger" data-testid="logout-button">
      <div className="action-icon">
        <User />
      </div>
      Sign Out
    </button>
  );
}
