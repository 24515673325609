import LoadingSpinner from 'shared/NovaPrimeUI/Components/LoadingSpinner/LoadingSpinner';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import Login from 'Sections/Login/Login';
import useAuthManager from 'shared/hooks/useAuthManager';

import { useGlobalConfig } from 'shared/hooks/useGlobalConfig';
import { useParsedAuth0User } from 'shared/hooks/useParsedAuth0User';
import { useProductRole } from 'shared/hooks/useProductRole';
import { doDomainsMatch } from 'shared/utils/doDomainsMatch';
import AppToast from './AppToast';
import AppContentWrapper from './AppContentWrapper';
import { Product } from '../../models/User';
import OriginatorLayout from './OriginatorLayout';
import InvestorLayout from './InvestorLayout';
import { useLocation } from 'react-router-dom';
import { mixpanelTrackPageView } from 'shared/mixpanel';

export function Main() {
  const auth = useAuth0();
  const { product } = useProductRole();
  const user = useParsedAuth0User();
  const config = useGlobalConfig();
  const authManager = useAuthManager();

  const location = useLocation();

  useEffect(() => {
    mixpanelTrackPageView();
  }, [location]);

  useEffect(() => {
    if (auth.error) {
      console.error(auth.error);
      auth.logout();
    }

    if (user.isLoading) {
      return;
    }

    if (!config) {
      return;
    }

    const redirectUrl = config.redirectUrl ?? user.data.frontendUrl;

    if (redirectUrl && !doDomainsMatch(window.location.origin, redirectUrl)) {
      window.location.href = redirectUrl;
      return;
    }

    authManager.load(auth, config, product);
  }, [auth, config]);

  if (auth.isLoading) return <LoadingSpinner />;

  // If we're authenticated but the auth manager hasn't loaded, wait here to
  // prevent requests from going out
  if (auth.isAuthenticated && authManager.isLoading) return <LoadingSpinner />;

  if (!auth.isAuthenticated && !location.search.includes('code=')) {
    return <Login />;
  }

  if (product === Product.Originator) {
    return (
      <AppContentWrapper>
        <OriginatorLayout />

        <AppToast />
      </AppContentWrapper>
    );
  }

  if (product === Product.Investor) {
    return (
      <AppContentWrapper>
        <InvestorLayout />

        <AppToast />
      </AppContentWrapper>
    );
  }

  return null;
}

export default Main;
