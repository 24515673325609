import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../../state-management/hooks';
import { useNavigate } from 'react-router-dom';
import { ERROR_MESSAGE } from '../types';
import { addToastError } from '../../../../../../state-management/slices/toast/toast.slice';
import axios from 'axios';
import useLoadPreviewDocumentsQuery from '../../../../../../state-management/slices/analysis-documents/hooks/useLoadPreviewDocumentsQuery';
import { setPreviewDocuments } from '../../../../../../state-management/slices/analysis-documents/slice';
import useGetAllLoansPath from '../../../Quality/hooks/useGetAllLoansPath';

const useLoadPreviewDocuments = (mortgageId: string) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathWithMortgage } = useGetAllLoansPath();

  const { refetch, data, error, isFetching, isPending } = useLoadPreviewDocumentsQuery(mortgageId);

  useEffect(() => {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 404) {
        dispatch(addToastError(ERROR_MESSAGE.INVALID_LOAN_ERROR));
        navigate(pathWithMortgage);
      } else {
        dispatch(addToastError(ERROR_MESSAGE.DOCUMENT_LOAD_ERROR));
      }
    }
  }, [error, dispatch, navigate, pathWithMortgage]);

  useEffect(() => {
    if (data) {
      dispatch(setPreviewDocuments({ mortgageId, data }));
    }
  }, [data, dispatch, mortgageId]);

  return {
    previewDocuments: data,
    loadPreviewDocuments: refetch,
    isPreviewDocumentsLoading: isFetching,
    isPreviewDocumentsPending: isPending,
    isPreviewDocumentsLoadingError: Boolean(error)
  };
};

export default useLoadPreviewDocuments;
