import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import 'reflect-metadata';
import { client } from 'shared/api/clients/client';
import { IPayupRepository } from 'shared/api/interfaces/IPayupRepository';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';
import { IPayup } from 'shared/models/Payup';

@injectable()
export class PayupRepository implements IPayupRepository {
  private baseRepository = new BaseRepository();

  apiUrl = async (): Promise<string> => {
    return this.baseRepository.getApiUrl((await this.baseRepository.getProductString()) + '/payup'); // TODO: Change url to correct endpoint URL
  };

  add(): Promise<AxiosResponse<any, any>> {
    throw new Error('Method not implemented.');
  }

  update(): Promise<AxiosResponse<any, any>> {
    throw new Error('Method not implemented.');
  }

  getAll = async (): Promise<AxiosResponse<IPayup[]>> => {
    return await client.get(`${await this.apiUrl()}`);
  };

  fetchByMortgageId = async (mortgageId: string): Promise<AxiosResponse<IPayup[]>> => {
    return await client.get(`${await this.apiUrl()}/mortgage/${mortgageId}`);
  };

  delete(): Promise<AxiosResponse<any, any>> {
    throw new Error('Method not implemented.');
  }
}
