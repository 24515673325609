import { AsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { MortgageDocumentMap } from '../slices/mortgage-document/mortgage-document.slice';
import { IBaseThunk, thunkReactQueryMiddleware } from '../thunks/base.thunks';
import { IMortgageDocumentRepository } from 'shared/api/interfaces/IMortgageDocumentRepository';
import { IMortgageDocumentList, s3BucketSuffix } from 'shared/models/MortgageDocument';

export class MortgageDocumentThunks implements IBaseThunk<IMortgageDocumentList> {
  private readonly mortgageDocumentRepository!: IMortgageDocumentRepository;

  private baseIdentifier = 'mortgageDocument';

  constructor(_mortgageRepository: IMortgageDocumentRepository) {
    this.mortgageDocumentRepository = _mortgageRepository;

    if (this.mortgageDocumentRepository === null) {
      throw new Error('mortgageRepository has not been instantiated!');
    }
  }

  getAll(): AsyncThunk<AxiosResponse<IMortgageDocumentList[], any>, void, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  getOne(): AsyncThunk<AxiosResponse<string[], any>, string, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  getLists() {
    const action = `${this.baseIdentifier}/fetchMortgagesDocumentList`;

    return thunkReactQueryMiddleware<MortgageDocumentMap, s3BucketSuffix>(action, (status) => ({
      queryKey: [action, status],
      queryFn: () => this.mortgageDocumentRepository.getLists(status)
    }));
  }

  getList() {
    const action = `${this.baseIdentifier}/fetchMortgageDocumentList`;

    return thunkReactQueryMiddleware<string[], string>(action, (id) => ({
      queryKey: [action, id],
      queryFn: () => this.mortgageDocumentRepository.getList(id)
    }));
  }

  add(): AsyncThunk<AxiosResponse<IMortgageDocumentList, any>, any, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  update(): AsyncThunk<
    AxiosResponse<IMortgageDocumentList, any>,
    { id: string; item: IMortgageDocumentList },
    Record<string, unknown>
  > {
    throw new Error('Method not implemented.');
  }

  delete(): AsyncThunk<AxiosResponse<IMortgageDocumentList, any>, string, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }
}
