import { Auth0ContextInterface } from '@auth0/auth0-react';
import { Config } from '../../state-management/slices/config/config.slice';
import { Product } from 'shared/models/User';

class AuthManager {
  public isLoaded = false;

  public accessToken?: string;

  public idToken?: string;

  public product?: Product;

  private auth?: Auth0ContextInterface;

  async load(auth: Auth0ContextInterface, config: Config, product?: Product): Promise<void> {
    if (this.isLoaded) {
      return;
    }

    const claim = await auth.getIdTokenClaims();

    this.idToken = claim?.__raw;

    this.accessToken = await auth.getAccessTokenSilently({
      authorizationParams: {
        audience: config.oauth.audience,
        scope: 'openid email profile offline_access'
      }
    });

    this.product = product;
    this.auth = auth;

    this.isLoaded = true;
  }

  async refreshAccessToken(): Promise<boolean> {
    this.accessToken = await this.auth?.getAccessTokenSilently();

    if (this.accessToken) {
      return true;
    } else {
      return false;
    }
  }
}

export const authManager = new AuthManager();
