import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../state-management/hooks';
import { selectIsCurrentUserNP } from '../../../state-management/slices/user/user.selectors';

const useIsSupportUIEnabled = () => {
  const [searchParams] = useSearchParams();
  const isSupportUIParamPresent = searchParams.get('support') === 'true';

  const isNPUser = useAppSelector(selectIsCurrentUserNP);

  return isSupportUIParamPresent && isNPUser;
};

export default useIsSupportUIEnabled;
