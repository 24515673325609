import { BrowserOptions, captureConsoleIntegration, reactRouterV6BrowserTracingIntegration } from '@sentry/react';
import React from 'react';
import { createRoutesFromChildren, matchRoutes, useNavigationType } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export const SENTRY_CONFIG: BrowserOptions = {
  enabled: process.env.NODE_ENV === 'production',
  integrations: [
    captureConsoleIntegration({
      levels: ['error']
    }),
    // https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
    reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    })
  ],
  // https://sentry.zendesk.com/hc/en-us/articles/23573234660763-How-do-I-opt-out-of-Performance-Monitoring
  // Disable tracesSampleRate to avoid sampling in performance monitoring
  tracesSampleRate: 0
};
