import {
  APIAllLoansLoanTypes,
  UIAllLoansLoanTypes
} from 'Sections/Originator/Pages/Quality/hooks/useGetPaginatedAllLoans/utils';
import { GovernmentType, MortgageOriginatorStatus, MortgageStatus } from 'shared/models/Mortgage';

export const loanStatusTextMapping: Record<MortgageOriginatorStatus, string> = {
  [MortgageOriginatorStatus.Open]: 'Bids',
  [MortgageOriginatorStatus.Draft]: 'Pipeline',
  [MortgageOriginatorStatus.Sold]: 'Purchased',
  [MortgageOriginatorStatus.Pending]: 'Committed'
};

export const reversedLoanStatusTextMapping: Record<string, MortgageStatus> = {
  Bids: MortgageStatus.Open,
  Pipeline: MortgageStatus.Draft,
  Purchased: MortgageStatus.Sold,
  Committed: MortgageStatus.Pending
};

// a few loan types we want to map to specific display
export const loanTypeTextMapping = (value: string | undefined) => {
  if (!value) {
    return '';
  }

  if (value === APIAllLoansLoanTypes.Other) {
    return UIAllLoansLoanTypes.NonQM;
  }

  if (value === APIAllLoansLoanTypes.USDARuralDevelopment) {
    return GovernmentType.USDARuralDevelopment;
  }

  return value;
};
