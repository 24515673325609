import { createSlice } from '@reduxjs/toolkit';
import { IBidTape } from 'shared/models/BidTape';
import { PayloadAction } from '@reduxjs/toolkit';

export type BidTapeSlice = {
  bidTapes: IBidTape[];
  modalOpen: boolean;
  selectedBidTapeId: IBidTape['id'];
};

const initialState: BidTapeSlice = {
  bidTapes: [],
  modalOpen: false,
  selectedBidTapeId: ''
};

const bidTapeSlice = createSlice({
  name: 'bidTape',
  initialState,
  reducers: {
    setBidTapes: (state, action: PayloadAction<{ data: BidTapeSlice['bidTapes'] }>) => {
      state.bidTapes = action.payload.data;
    },
    setSelectedBidTape: (state, action: PayloadAction<BidTapeSlice['selectedBidTapeId']>) => {
      state.selectedBidTapeId = action.payload;
    },
    addBidTape: (state, action: PayloadAction<{ data: IBidTape }>) => {
      const bidTape = action.payload.data;

      state.bidTapes.push({ ...bidTape });
    },
    updateBidTape: (state, action: PayloadAction<{ data: IBidTape }>) => {
      const bidTape = action.payload.data;
      const indexToReplace = state.bidTapes.findIndex((bt) => bt.id === bidTape.id);

      if (indexToReplace !== -1) {
        state.bidTapes[indexToReplace] = bidTape;
      }
    }
  }
});

export const { setBidTapes, addBidTape, updateBidTape, setSelectedBidTape } = bidTapeSlice.actions;
export default bidTapeSlice.reducer;
