import { RootState } from '../../store';
import { createSelector } from 'reselect';

export const selectToastsQueue = (state: RootState) => state.toast.queue;
export const selectIsToastOpen = (state: RootState) => state.toast.isOpen;
export const selectToastsTimerId = (state: RootState) => state.toast.timerId;

export const selectCurrentToastMessage = createSelector([selectToastsQueue, selectIsToastOpen], (queue, isOpen) =>
  isOpen ? queue[0] : null
);
