import classNames from 'classnames';
import './LoadingSpinner.scss';
import { PropsWithChildren } from 'react';

export interface LoadingSpinnerProps extends PropsWithChildren {
  /**
   * If true, the spinner will use a fixed position and use 100vw viewport width.
   * Otherwise, the spinner will use the width bounds of its parent container.
   */
  viewportWidth?: boolean;
  /**
   * If true, the spinner will use a fixed position and use 100vh viewport height.
   * Otherwise, the spinner will use the height bounds of its parent container.
   */
  viewportHeight?: boolean;
}

const LoadingSpinner = ({ viewportHeight = true, viewportWidth = true, children }: LoadingSpinnerProps) => {
  return (
    <div
      className={classNames('loading-spinner-container', {
        'container-height': !viewportHeight,
        'container-width': !viewportWidth,
        'container-bounds': !viewportWidth && !viewportHeight
      })}
    >
      <div className="loading-spinner" role="status" />
      {children ? <div className="loading-spinner-text">{children}</div> : null}
    </div>
  );
};

export default LoadingSpinner;
