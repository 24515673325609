import { RootState } from '../../store';
import { ContractStatus, IBulkLoansGroup } from 'shared/models/Loan';
import { createSelector } from 'reselect';
import Big from 'big.js';
import { INITIAL_BULK_CONTRACT_STATUS } from './bulk.constants';

export const getIsContractCompleted = (contract: IBulkLoansGroup) =>
  Boolean(contract.status) && contract.apiStatus && contract.apiStatus !== INITIAL_BULK_CONTRACT_STATUS;

export const getIsContractInProgress = (contract: IBulkLoansGroup) =>
  contract.apiStatus === INITIAL_BULK_CONTRACT_STATUS;

export const selectBulk = (state: RootState) => state.bulk;
export const selectBulkLoans = (state: RootState) => state.bulk.importedSourceLoans;
export const selectBulkTrades = (state: RootState) => state.bulk.importedSourceTrades;
export const selectBulkContracts = (state: RootState) => state.bulk.importedContractData;

export const selectBulkLoanErrors = (state: RootState) => state.bulk.importedSourceLoansErrors;

export const selectBulkTradesErrors = (state: RootState) => state.bulk.importedSourceTradesErrors;

export const selectBulkContractsCount = (state: RootState) => state.bulk.importedContractData.length;

export const selectCurrentStep = (state: RootState) => state.bulk.currentStep;

export const selectIsTransactionInProgress = createSelector(selectBulkContracts, (contracts) =>
  contracts.some(getIsContractInProgress)
);

export const selectIsBulkCompleted = createSelector(selectBulkContracts, (contracts) =>
  contracts.every(getIsContractCompleted)
);

export const selectBulkContractsTotalAmount = createSelector(selectBulkContracts, (contracts) =>
  contracts.reduce((totalAmount, contract) => totalAmount.plus(contract.contractAmount), new Big(0)).toFixed(2)
);

export const selectSuccessContracts = createSelector(selectBulkContracts, (contracts) =>
  contracts.filter((contract) => contract.status === ContractStatus.SUCCESS)
);

export const selectNotFailedContracts = createSelector(selectBulkContracts, (contracts) =>
  contracts.filter((contract) => contract.status !== ContractStatus.FAILURE)
);

export const selectFailureContracts = createSelector(selectBulkContracts, (contracts) =>
  contracts.filter((contract) => contract.status === ContractStatus.FAILURE)
);

export const selectSpreadsheetLoansErrorMessage = (state: RootState) => state.bulk.spreadsheetLoansImportError;
export const selectSpreadsheetTradesErrorMessage = (state: RootState) => state.bulk.spreadsheetTradesImportError;

export const selectManageAotLoanNumberFilter = (state: RootState) => state.bulk.manageAotLoanNumberFilter;
