import { useQuery } from '@tanstack/react-query';
import { MortgageRepository } from 'shared/api/repositories/MortgageRepository/MortgageRepository';
import { IMortgageFlat } from 'shared/models/Mortgage';
import { mixpanelPerformanceEvents, mixpanelTime, mixpanelTrack } from '../../../../shared/mixpanel';

const mortgageRepository = new MortgageRepository();

const useGetMortgageByIdQuery = (mortgageId: IMortgageFlat['internalId']) =>
  useQuery({
    enabled: Boolean(mortgageId),
    queryKey: ['mortgages', mortgageId],
    queryFn: async ({ queryKey }) => {
      mixpanelTime(mixpanelPerformanceEvents.P_SINGLE_LOAN);

      const response = await mortgageRepository.getOne(queryKey[1]);

      mixpanelTrack(mixpanelPerformanceEvents.P_SINGLE_LOAN, { mortgageId });

      return response;
    },
    select: (response) => response.data
  });

export default useGetMortgageByIdQuery;
