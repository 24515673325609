import { Dispatch, createListenerMiddleware } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { _closeToast, _openToast, _removePreviousToastFromQueue, _setTimerId } from './toast.slice';

const TIME_BEFORE_SHOWING_NEXT_TOAST = 100;
const TIME_BEFORE_CLOSING_TOAST = 5000;

// open toast; set a timeout to close the notification; store the timeoutId
export function _openToastAndInitializeTimer(dispatch: Dispatch) {
  dispatch(_openToast());

  const timerId = window.setTimeout(() => {
    dispatch(_closeToast());
  }, TIME_BEFORE_CLOSING_TOAST);

  dispatch(_setTimerId(timerId));
}

const toastListener = createListenerMiddleware();

// _closeToast(), listener will run after user manually closes toast, or timeout duration is over
toastListener.startListening({
  actionCreator: _closeToast,
  effect: (action, api) => {
    const { toast } = api.getState() as RootState;

    // cleanup the timer in case the user manually dismissed toast before timeout
    if (toast.timerId) {
      window.clearTimeout(toast.timerId);
      api.dispatch(_setTimerId(null));
    }

    // remove the previous notification from the queue
    api.dispatch(_removePreviousToastFromQueue());

    // if there are more notifications in the queue, show the next one after a delay
    if (toast.queue.length - 1 > 0) {
      window.setTimeout(() => {
        // set timer to close the next notification
        _openToastAndInitializeTimer(api.dispatch);
      }, TIME_BEFORE_SHOWING_NEXT_TOAST);
    }
  }
});

export default toastListener;
