import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';
import { Product, Role } from 'shared/models/User';

export const useProductRole = () => {
  const auth0 = useAuth0();

  const product: string | undefined = auth0.user?.['https://3homes.com/product'];
  const roleArray: string[] = useMemo(() => auth0.user?.['https://3homes.com/role'] ?? [], [auth0.user]);

  const role = useMemo(() => roleArray.map((r) => r.toLocaleLowerCase() as Role), [roleArray]);

  return {
    role,
    product: product?.toLocaleLowerCase() as Product | undefined
  };
};
