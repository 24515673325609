import { ValueFormatterParams } from 'ag-grid-community';
import { IBulkLoan } from '../../models/Loan';
import Big from 'big.js';

export const formatToPercentage = (params: Pick<ValueFormatterParams<IBulkLoan>, 'value'>) =>
  params.value ? `${params.value}%` : '';

export const formatToCurrency = <T>(params: ValueFormatterParams<T, string | number>) =>
  params.value ? formatCurrency(params.value) : '';

export const formatToCurrencyWithDecimals = <T>(params: ValueFormatterParams<T, string | number>) =>
  params.value ? formatCurrency(params.value, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '';

export const formatCurrency = (amount: number | string, options: Intl.NumberFormatOptions = {}): string => {
  try {
    if (!amount) {
      throw new Error('Amount is undefined');
    }

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      ...options
      // notation: 'compact' // To display 10M instead of $10,000,000
    });

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/format#number
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - We have 2 types conflicting here, but format do accept both types
    return formatter.format(amount);
  } catch (error) {
    console.error('Error formatting currency', error);
    return (amount || 0).toString();
  }
};

export const formatInterestRateRange = (min: number, max: number) => {
  const formattedMin = formatToPercentage({ value: new Big(min).toFixed(3) });
  const formattedMax = formatToPercentage({ value: new Big(max).toFixed(3) });

  return `${formattedMin} - ${formattedMax}`;
};

// Creates a contract name from the spec pool and interest rate range
// Used for initial group sorting
export const formatContractName = (specPool: string, interestRateRange: string) => {
  return specPool + ' ' + interestRateRange;
};
