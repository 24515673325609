import { createSlice } from '@reduxjs/toolkit';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { IMortgagesDocumentList, s3BucketSuffix } from 'shared/models/MortgageDocument';
import { MortgageDocumentThunks } from '../../thunks/mortgageDocument.thunks';
import { IMortgageDocumentRepository } from 'shared/api/interfaces/IMortgageDocumentRepository';

export type MortgageDocumentMap<T = IMortgagesDocumentList> = { [key: string]: T };

type DocumentStatus = {
  [key in s3BucketSuffix]: MortgageDocumentMap;
};

export type MortgageDocumentSlice = {
  documentList: DocumentStatus;
};

const initialState: MortgageDocumentSlice = {
  documentList: { [s3BucketSuffix.unsold]: {}, [s3BucketSuffix.sold]: {} }
};

const mortgageDocumentRepository = container.get<IMortgageDocumentRepository>(
  SERVICE_KEYS.MORTGAGE_DOCUMENT_REPOSITORY
);
const mortgageDocumentThunks = new MortgageDocumentThunks(mortgageDocumentRepository);

export const fetchMortgagesDocumentList = mortgageDocumentThunks.getLists();

const mortgageDocumentSlice = createSlice({
  name: 'mortgageDocument',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMortgagesDocumentList.fulfilled, (state, { payload, meta }) => {
      state.documentList[meta.arg] = payload?.data;
    });
  }
});

export const mortgageDocumentReducer = mortgageDocumentSlice.reducer;
