import { authManager } from 'shared/auth/auth-manager';

export class BaseRepository {
  protected baseUrl = '/api/';

  getProductString() {
    if (!authManager.product) {
      throw new Error('No product configured for user');
    }

    return authManager.product;
  }

  getApiUrl(urlFragment: string) {
    return this.baseUrl + urlFragment;
  }
}
