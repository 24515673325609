import useGetCurrentUser from 'state-management/slices/user/hooks/useGetCurrentUser';
import { SidebarEntryLogout } from './SidebarEntryLogout';
import SidebarEntryUserMenu from './SidebarEntryUserMenu';
import { useEffect } from 'react';
import { useAppSelector } from 'state-management/hooks';
import { selectCurrentUser } from 'state-management/slices/user/user.selectors';

export default function SidebarEntryLogoutOrUserMenu() {
  const { getCurrentUser, isFetched } = useGetCurrentUser();
  const currentUser = useAppSelector(selectCurrentUser);

  useEffect(() => {
    if (!isFetched) {
      getCurrentUser();
    }
  }, [getCurrentUser, isFetched]);

  const { name, email } = {
    name: currentUser?.fullName,
    email: currentUser?.email
  };

  if (name && email) {
    return <SidebarEntryUserMenu name={name} email={email} />;
  } else {
    return <SidebarEntryLogout />;
  }
}
