import { useContext, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Main from 'shared/Layouts/Main/Main';
import { Auth0Provider } from '@auth0/auth0-react';
import { Socket } from 'clients/ws-client';
import { LicenseManager } from 'ag-grid-enterprise';
import { stripTrailingSlash } from 'shared/utils/stripTrailingSlash';
import { init } from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import useInitGlobalConfig from './shared/hooks/initGlobalConfig';
import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from './shared/api/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SENTRY_CONFIG } from './shared/sentry/configuration';
import { WsClientContext } from './clients/ws-client-context-provider';
import LoadingSpinner from 'shared/NovaPrimeUI/Components/LoadingSpinner/LoadingSpinner';
import { shouldMixPanelBeEnabled } from 'shared/mixpanel';

function App(): JSX.Element {
  const config = useInitGlobalConfig();
  const wsClient = useContext(WsClientContext);

  useEffect(() => {
    if (!config) return;

    wsClient.start(Socket.getUrlFromBackendUrl(config.backendUrl));
    LicenseManager.setLicenseKey(config.agGridLicenseKey);

    init({ ...SENTRY_CONFIG, dsn: config.sentry?.dsn, environment: location.hostname });

    if (shouldMixPanelBeEnabled()) {
      if (!config.mixpanel?.token) {
        console.error('Mixpanel is not configured');
      } else {
        mixpanel.init(config.mixpanel.token, { persistence: 'localStorage' });
      }
    }
  }, [config, wsClient]);

  if (!config) return <LoadingSpinner />;

  return (
    <Auth0Provider
      domain={stripTrailingSlash(config.oauth.authority)}
      clientId={config.oauth.clientId}
      authorizationParams={{
        redirect_uri: window.location.origin
      }}
      cookieDomain={config.oauth.cookieDomain}
      useRefreshTokens
      useRefreshTokensFallback
    >
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Main />
          <ReactQueryDevtools />
        </QueryClientProvider>
      </BrowserRouter>
    </Auth0Provider>
  );
}

export default App;
