import { createSlice } from '@reduxjs/toolkit';
import { IBid } from '../../../shared/models/Bid';
import { TInitServicersAction, TReconcileServicersAction, TUpdateServicerAction } from './types';
import { getBidKey } from '../bid/bid.utils';

export interface ServicerState {
  [bidKey: string]: Pick<IBid, 'servicerId' | 'servicerName'>;
}

const initialState = {} as ServicerState;

const servicerSlice = createSlice({
  name: 'bid',
  initialState,
  reducers: {
    // TODO TEST CASE: Restored servicers exist in bids fetched for the first time
    initServicers: (state, action: TInitServicersAction) => {
      const bids = action.payload;

      Object.keys(state).forEach((bidKey) => {
        const bid = bids[bidKey];

        if (!bid) {
          delete state[bidKey];
        }
      });
    },
    // TODO TEST CASE: Bids are fetched for the first time
    // TODO TEST CASE: Bids are re-fetch but servicer was locally updated, it should not be overwritten
    // TODO TEST CASE: A bid is added to a mortgage
    reconcileServicers: (state, action: TReconcileServicersAction) => {
      let bidKey;
      const bids = action.payload;

      bids.forEach((bid) => {
        bidKey = getBidKey(bid);
        if (!state[bidKey]) {
          state[bidKey] = {
            servicerId: bid.servicerId,
            servicerName: bid.servicerName
          };
        }
      });
    },
    updateServicer: (state, action: TUpdateServicerAction) => {
      // TODO TEST CASE: Update local servicer
      const { bidKey, servicerName, servicerId } = action.payload;

      state[bidKey] = { servicerId, servicerName };
    }
  }
});

export const { initServicers, reconcileServicers, updateServicer } = servicerSlice.actions;

export const SERVICER_PERSIST_NAME = 'SERVICER';
export const SERVICER_ACTIONS_TO_TRIGGER_PERSIST = [initServicers, reconcileServicers, updateServicer];

export default servicerSlice.reducer;
