export const sortCategoriesAlphabetically = <T extends { category: string }>(a: T, b: T) =>
  a.category.localeCompare(b.category);

// export const getCategoryCountUpdated = (
//   isReviewed: boolean,
//   dataToUpdate: IAnalysisCategory[],
//   category: IAnalysisCategory['category'],
//   documentId: ICategoryDocument['documentId']
// ) => {
//   return dataToUpdate.map((cat) => {
//     if (cat.category === category) {
//       // if reviewed then decrement, if !reviewed then increment
//       const newCount = isReviewed ? -1 : 1;
//
//       return {
//         ...cat,
//         rulesToReview: cat.rulesToReview + newCount,
//         differentiators: cat.differentiators.map((diff) => {
//           return {
//             ...diff,
//             documents: diff.documents.map((doc) => {
//               if (doc.documentId === documentId) {
//                 return {
//                   ...doc,
//                   rulesToReview: doc.rulesToReview + newCount
//                 };
//               }
//
//               return doc;
//             })
//           };
//         })
//       };
//     }
//
//     return cat;
//   });
// };
