import { useAuth0 } from '@auth0/auth0-react';
import { Product, Role } from 'shared/models/User';

type ParsedAuth0User = {
  company: string;
  email: string;
  name: string;
  userId: string;
  product: Product;
  role: Role[];
  frontendUrl?: string;
};

export const useParsedAuth0User = (): { isLoading: false; data: ParsedAuth0User } | { isLoading: true; data: Partial<ParsedAuth0User> } => {
  const auth0 = useAuth0();

  if (!auth0.user) {
    return {
      isLoading: true,
      data: {}
    };
  }

  const namespace = 'https://3homes.com';

  return {
    isLoading: false,
    data: {
      company: auth0.user[`${namespace}/company`],
      email: auth0.user[`${namespace}/email`],
      name: auth0.user[`${namespace}/name`],
      userId: auth0.user[`${namespace}/userId`],
      product: auth0.user[`${namespace}/product`].toLocaleLowerCase() as Product,
      role: (auth0.user[`${namespace}/role`] ?? []).map((role: string) => role.toLocaleLowerCase() as Role),
      frontendUrl: auth0.user[`${namespace}/frontendUrl`]
    }
  };
};
