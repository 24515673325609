import 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community';
import { createRoot } from 'react-dom/client';
import React from 'react';
import 'index.scss';
import { Provider } from 'react-redux';
import { store } from './state-management/store';
import 'reflect-metadata';
import 'Resources/strings';
import 'styles/styles.scss';
import App from './App';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
