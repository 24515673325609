import { useProductRole } from 'shared/hooks/useProductRole';
import { Role } from 'shared/models/User';
import { permissionChecker } from 'shared/utils/permissionChecker';

interface PermissionGuardProps {
  notAllowed?: Role[];
  needsOneOf?: Role[];
  needsAllOf?: Role[];
  children: React.ReactNode;
}

const PermissionGuard = ({ notAllowed, needsOneOf, needsAllOf, children }: PermissionGuardProps) => {
  const { role } = useProductRole();

  const isAbleToView = permissionChecker(role, notAllowed, needsOneOf, needsAllOf);

  if (!isAbleToView) {
    return null;
  }

  return <>{children}</>;
};

export default PermissionGuard;
