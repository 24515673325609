import { createListenerMiddleware } from '@reduxjs/toolkit';
import { createAuth0User } from './user.slice';
import { addToast } from '../toast/toast.slice';
import { ToastStatus } from '@3homes/libs';
import { CreateAuth0UserErrorPayload, getCreateAuth0UserErrorMessage } from './user.helpers';

const userListener = createListenerMiddleware();

userListener.startListening({
  actionCreator: createAuth0User.rejected,
  effect: (action, api) => {
    const { data } = (action as CreateAuth0UserErrorPayload).payload;

    api.dispatch(addToast({ status: ToastStatus.error, text: getCreateAuth0UserErrorMessage(data) }));
  }
});

export default userListener;
