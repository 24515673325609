import { AppDispatch, RootState } from '../../store';
import { selectDocumentStatus, selectPreviewDocuments } from './selectors';
import { createListenerMiddleware, TypedStartListening } from '@reduxjs/toolkit';
import { setAnalysisChecklistCategory, setDocumentCategory, setDocumentStatus } from './slice';
import { IDocumentStatus } from '../../../shared/models/Analysis-Document';
import { sumBy } from 'lodash';

const documentCountListener = createListenerMiddleware();

export type DocCountStartListening = TypedStartListening<RootState, AppDispatch>;
export const startListening = documentCountListener.startListening as DocCountStartListening;

// Updates sidebar "CC" count
startListening({
  actionCreator: setAnalysisChecklistCategory,
  effect: ({ payload }, api) => {
    const documentStatusMap = selectDocumentStatus(api.getState());
    const { mortgageId, data } = payload;
    const status = documentStatusMap[mortgageId];

    if (!status) {
      return;
    }

    const newStatus: IDocumentStatus = {
      ...status,
      rulesFailing: sumBy(data, 'rulesFailing'),
      rulesAutoPass: sumBy(data, 'rulesAutoPass')
    };

    api.dispatch(setDocumentStatus({ data: [newStatus] }));
  }
});

// Updates sidebar "Doc categorization" count
startListening({
  actionCreator: setDocumentCategory,
  effect: ({ payload }, api) => {
    const latestState = api.getState() as RootState;
    const documentStatusMap = selectDocumentStatus(latestState);
    const documents = selectPreviewDocuments(latestState);

    const { mortgageId } = payload;
    const status = documentStatusMap[mortgageId];
    const documentsByMortgage = documents[mortgageId];

    if (!status || !documentsByMortgage.length) {
      return;
    }

    // Because we don't know if this document was categorized before, we need to recalculate the uncategorized count
    const docsUncategorized = documentsByMortgage.reduce((acc, { category }) => acc + (!category ? 1 : 0), 0);

    const newStatus: IDocumentStatus = {
      ...status,
      docsUncategorized
    };

    api.dispatch(setDocumentStatus({ data: [newStatus] }));
  }
});

export default documentCountListener;
