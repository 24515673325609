import { BidHistoryStatusSorted, IBid, ServicingRate } from '../models/Bid';
import { basisPointsToPrice } from '../../state-management/helpers/data-utils.helper';
import { IPayup } from '../models/Payup';

export type TPartialBid = Pick<
  IBid,
  'bidHistory' | 'payup' | 'priceForRate025' | 'priceForRate0375' | 'priceForRate05'
> & {
  servicingRate?: IBid['pricingOption'];
};

export type TPartialPayup = Pick<IPayup, 'amountForRate025' | 'amountForRate0375' | 'amountForRate05' | 'name'>;

export const getPriceFromServicerFee = (
  pricingOption: IBid['pricingOption'],
  bid: Omit<TPartialBid, 'bidHistory' | 'payup'>
): string => {
  switch (pricingOption) {
    case '0.5':
      return bid.priceForRate05;
    case '0.375':
      return bid.priceForRate0375;
    default:
      return bid.priceForRate025;
  }
};

export const getPayupAmountFromServicerFee = (pricingOption: IBid['pricingOption'], payup: TPartialPayup): string => {
  switch (pricingOption) {
    case '0.5':
      return payup.amountForRate05;
    case '0.375':
      return payup.amountForRate0375;
    default:
      return payup.amountForRate025;
  }
};

export const getPriceFinalPriceForServicingRate = (
  bid: Omit<TPartialBid, 'bidHistory' | 'payup'>,
  servicingRate: ServicingRate,
  payup?: TPartialPayup
): string => {
  const priceToUse = getPriceFromServicerFee(servicingRate, bid);
  const basePrice = basisPointsToPrice(priceToUse);

  const finalPrice = payup ? basePrice.plus(getPayupAmountFromServicerFee(servicingRate, payup)) : basePrice;

  return finalPrice.toFixed(3);
};

export const getFinalPrice = (bid: TPartialBid, selectedPayup?: TPartialPayup, payup?: TPartialPayup): string => {
  const latestBid = bid.bidHistory[0];
  const pricingOption = bid.servicingRate || '0.25';

  if (BidHistoryStatusSorted[latestBid.status] < BidHistoryStatusSorted.BID_REVISION_PROPOSED) {
    return getPriceFinalPriceForServicingRate(bid, pricingOption, payup);
  }

  // When the countdown starts
  let basePrice = basisPointsToPrice(latestBid.price),
    finalPrice;

  if (selectedPayup && bid.payup) {
    basePrice = basePrice.minus(getPayupAmountFromServicerFee(pricingOption, selectedPayup));
  }

  if (payup) {
    finalPrice = basePrice.plus(getPayupAmountFromServicerFee(pricingOption, payup));
  } else {
    finalPrice = basePrice;
  }

  return finalPrice.toFixed(3);
};
