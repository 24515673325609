import { authManager } from 'shared/auth/auth-manager';
import { v4 } from 'uuid';
import { ReconnectingWebSocket } from './reconnecting-websocket';
import { SocketMessage } from './socket-message.type';

export interface SocketSubcriber {
  onMessage: (message: string) => void;
  onOpen: () => void;
}

export class Socket {
  instance: ReconnectingWebSocket | null = null;

  invalidJwt = false;

  start(websocketUrl: string): ReconnectingWebSocket {
    console.debug('Starting socket');
    if (this.instance) {
      console.debug('Socket already started');
      this.close();
      //   todo: close or return?
    }

    this.instance = this.init(websocketUrl);

    return this.instance;
  }

  static getUrlFromBackendUrl(backendUrl: string, suffix = ''): string {
    let websocketUrl: string = backendUrl;

    if (backendUrl.includes('https://')) {
      websocketUrl = `${websocketUrl.replace('https://', 'wss://')}`;
    } else if (backendUrl.includes('http://')) {
      websocketUrl = `${websocketUrl.replace('http://', 'ws://')}`;
    }

    if (!websocketUrl.endsWith('/')) {
      websocketUrl += '/wss';
    } else {
      websocketUrl += 'wss';
    }

    return websocketUrl + suffix;
  }

  init(websocketUrl: string): ReconnectingWebSocket {
    console.debug('init socket');
    const socket = new ReconnectingWebSocket(
      async () => {
        if (!authManager.isLoaded) throw new Error('Not authenticated yet');
        return new WebSocket(websocketUrl);
      },
      {
        maxBackoffSeconds: 10,
        minStableSeconds: 5,
        onOpen: async (socket) => {
          socket.send(
            JSON.stringify({
              event: 'auth',
              data: authManager.accessToken
            })
          );
        },
        onMessage: async (message: string) => {
          const payload: SocketMessage = JSON.parse(message);

          if (payload.event === 'auth') {
            if (payload.error) {
              this.invalidJwt = true;
            } else {
              this.invalidJwt = false;
              this.publishOpen();
            }
          } else {
            this.publishMessage(message);
          }
        }
      }
    );

    socket.start();
    return socket;
  }

  static subscribers = new Map<string, SocketSubcriber>();

  publishOpen = (): void => {
    console.debug('open socket');

    Socket.subscribers.forEach(({ onOpen }) => {
      try {
        onOpen();
      } catch (error) {
        console.warn(`Caught error while publishing open event: ${error}`);
      }
    });
  };

  publishMessage = (message: string): void => {
    Socket.subscribers.forEach(({ onMessage }) => {
      try {
        onMessage(message);
      } catch (error) {
        console.warn(`Caught error while publishing message '${message}': ${error}`);
      }
    });
  };

  subscribe = (subscriber: SocketSubcriber) => {
    console.debug('socket subscribe');

    const id = v4();

    Socket.subscribers.set(id, subscriber);

    return {
      unsubscribe: () => {
        Socket.subscribers.delete(id);
      }
    };
  };

  close = (): void => {
    console.debug('Closing socket');
    this.instance?.stop();
  };
}
